<template>
  <div class="main-container">
    <!-- 左侧菜单 -->
    <PersonLeft></PersonLeft>

    <!-- 右侧内容区域 -->
    <div class="main-content">
      <el-row class="header-row">
        <el-col :span="20">
          <el-page-header @back="goIndex" content="用户信息" class="header-content"></el-page-header>
        </el-col>
      </el-row>

      <!-- 用户信息描述 -->
      <el-descriptions style="width: 100%" class="margin-top" :column="3" border>
        <template slot="extra">
          <el-button type="primary" size="small" @click="userVisible = true;" class="action-btn">
            修改
          </el-button>
        </template>

        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i> 用户名
          </template>
          {{ user.uname }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-mobile-phone"></i> 性别
          </template>
          <span v-if="user.gender == 1">男</span>
          <span v-else>女</span>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-location-outline"></i> 居住地
          </template>
          {{ user.city }}
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-date"></i> 出生日期
          </template>
          <el-tag>{{ user.birthday }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-tickets"></i> 手机号码
          </template>
          <el-tag size="small">{{ user.phoneNum }}</el-tag>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-office-building"></i> 联系地址
          </template>
          {{ user.address }}
        </el-descriptions-item>
        <el-descriptions-item v-if="user.role != 0">
          <template slot="label">
            <i class="el-icon-user"></i> 作品主页信息
          </template>
          <el-tag size="mini" type="primary" v-if="user.role === 2">已成为创作者</el-tag>

          <el-button
              size="mini"
              type="primary"
              v-if="user.role === 1"
              @click="openUpdate(user.uid)"
              class="action-btn"
          >
            申请成为小说创作者
          </el-button>
        </el-descriptions-item>
      </el-descriptions>

      <!-- 修改个人信息对话框 -->
      <el-dialog title="修改个人信息" :visible.sync="userVisible" :before-close="cancel" class="dialog-box">
        <el-form :model="updateUser" status-icon :rules="rules" ref="ruleForm" label-width="100px">
          <el-form-item label="用户名" prop="uname">
            <el-input type="text" v-model="updateUser.uname" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-radio v-model="updateUser.gender + ''" label="1">男</el-radio>
            <el-radio v-model="updateUser.gender + ''" label="0">女</el-radio>
          </el-form-item>
          <el-form-item label="出生日期" prop="birthday">
            <el-col :span="11">
              <el-date-picker
                  type="date"
                  value-format="yyyy-MM-dd"
                  placeholder="选择日期"
                  v-model="updateUser.birthday"
                  style="width: 100%;"
              ></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="手机号码" prop="phoneNum">
            <el-input type="text" v-model="updateUser.phoneNum" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="居住地">
            <el-select v-model="updateUser.city" placeholder="请选择">
              <el-option
                  v-for="item in cities"
                  :key="item.value"
                  :label="item.label"
                  :value="item.label"
              >
                <span style="float: left">{{ item.label }}</span>
                <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
              </el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系地址" prop="address">
            <el-input type="textarea" v-model="updateUser.address"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-image style="float: left;width: 20%" :src="codeSrc" @click="changeCode"></el-image>
            <el-input style="float: right;width: 75%" type="text" v-model="updateUser.code" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="cancel" class="cancel-btn">取消</el-button>
            <el-button type="primary" @click="onSubmit('ruleForm')" class="submit-btn">确认</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import PersonLeft from "./PersonLeft";
import publicJS from "../assets/js/public";

export default {
  name: "Personal",
  components: {PersonLeft},
  data() {
    var checkUserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('用户名不能为空'));
      } else {
        // 调用后端api
        this.axios.get(publicJS.methods.getBackPath() + "user/checkUpdateUname", {
          params: {
            uname: value
          }
        }).then(Response => {
          if (Response.data) {
            callback();
          }
          callback(new Error('用户名已存在！'));
        })
      }
    };
    return {
      user: {},
      userVisible: false,
      updateUser: {},
      codeSrc: '',
      cities: [
        {value: 'Beijing', label: '北京市'},
        {value: 'Changchun', label: '长春市'},
        {value: 'Chengdu', label: '成都市'},
        {value: 'Changsha', label: '长沙市'},
        {value: 'Chongqing', label: '重庆市'},
        {value: 'Fujian', label: '福建市'},
        {value: 'Guiyang', label: '贵阳市'},
        {value: 'Guangzhou', label: '广州市'},
        {value: 'Harbin', label: '哈尔滨市'},
        {value: 'HeFei', label: '合肥市'},
        {value: 'Huhhot', label: '呼和浩特市'},
        {value: 'HaiKou', label: '海口市'},
        {value: 'Hangzhou', label: '杭州市'},
        {value: 'Jinan', label: '济南市'},
        {value: 'Kunming', label: '昆明市'},
        {value: 'Lanzhou', label: '兰州市'},
        {value: 'Nanchang', label: '南昌市'},
        {value: 'Nanjing', label: '南京市'},
        {value: 'Shanghai', label: '上海市'},
        {value: 'Shijiazhuang', label: '石家庄市'},
        {value: 'Shenyang', label: '沈阳市'},
        {value: 'Tianjin', label: '天津市'},
        {value: 'Taiyuan', label: '太原市'},
        {value: 'Wuhan', label: '武汉市'},
        {value: 'Xi\'An', label: '西安市'},
        {value: 'Xining', label: '西宁市'},
        {value: 'Zhengzhou', label: '郑州市'},
        {value: 'Urumqi', label: '乌鲁木齐'},
        {value: 'LaSa', label: '拉萨'},
        {value: 'Nanning', label: '南宁市'},
        {value: 'HangKong', label: '香港'},
        {value: 'Macao', label: '澳门'},
        {value: 'Taipei', label: '台湾'},
      ],
      userForm: {
        uname: '',
        passwd: '',
        passwd2: '',
        gender: '1',
        birthday: '',
        phoneNum: '',
        city: '北京市',
        address: '',
        code: ''
      },
      rules: {
        uname: [
          {required: true, message: '用户名不能为空', trigger: 'blur'},
          {pattern: /^[0-9a-zA-Z_]{1,}$/, message: '必须是数字字母组合', trigger: 'blur'},
          {min: 4, max: 10, message: '长度在4-10位', trigger: 'blur'},
          {validator: checkUserName, trigger: 'blur'}
        ],
        birthday: [{required: true, message: '请选择您的生日', trigger: 'blur'}],
        phoneNum: [{
          required: true,
          pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
          message: '请输入正确的手机号码',
          trigger: 'blur'
        }],
        address: [{required: true, message: '请填入您的联系地址', trigger: 'blur'}],
        code: [{required: true, message: '请输入验证码', trigger: 'blur'}]
      },
    }
  },
  methods: {
    getUserInfo() {
      this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo").then(res => {
        this.user = res.data;
        this.updateUser = res.data;
      })
    },
    cancel() {
      this.getUserInfo();
      this.userVisible = false;
    },
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          formData.append("uid", this.updateUser.uid);
          formData.append("uname", this.updateUser.uname);
          formData.append("gender", this.updateUser.gender);
          formData.append("birthday", this.updateUser.birthday);
          formData.append("phoneNum", this.updateUser.phoneNum);
          formData.append("city", this.updateUser.city);
          formData.append("address", this.updateUser.address);
          formData.append("code", this.updateUser.code);
          this.axios.put(publicJS.methods.getBackPath() + "user/update", formData).then(res => {
            if (res.data.status) {
              this.$message.success("修改成功");
              this.$router.replace({path: '/refresh'});
            } else {
              this.$message.error(res.data.msg);
              this.changeCode();
            }
          })
        }
      });
    },
    changeCode() {
      this.codeSrc = publicJS.methods.getBackPath() + "checkCode?timestamp=" + new Date();
    },
    goIndex() {
      this.$router.replace({path: '/'});
    }, openUpdate(index) {
      this.axios.get(publicJS.methods.getBackPath() + "user/toShopMan/" + index).then(res => {
        if (!res.data) {
          this.$message.error("请耐心等待审核");
        } else {
          this.$message.success("审核发送成功");
        }

      })
    }
    // , loadShopInfo() {
    //   this.axios.get(publicJS.methods.getBackPath() + "shop/getOne/" + this.updateUser.uid).then(res => {
    //     this.shopInfo = res;
    //   })
    // }
  },
  mounted() {
    this.getUserInfo();
    this.changeCode();
    // this.loadShopInfo();
  }
}
</script>

<style scoped>
/* Main container and content */
.main-container {
  display: flex;
  padding: 20px;
}

.main-content {
  flex-grow: 1;
  padding-left: 20px;
  background-color: #f9fafb;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Header row */
.header-row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.header-content {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

/* Description section styling */
.el-descriptions {
  width: 100%;
  margin-top: 20px;
}

.el-descriptions-item {
  font-size: 14px;
  color: #333;
}

.el-tag {
  font-size: 12px;
  margin-right: 10px;
}

.el-button {
  font-size: 14px;
  border-radius: 8px;
  transition: all 0.3s ease;
}

.el-button:hover {
  background-color: #409EFF;
}


.cancel-btn, .submit-btn {
  width: 48%;
  padding: 10px;
  font-size: 16px;
  font-weight: bold;
  border-radius: 8px;
}

.cancel-btn {
  background-color: #f56c6c;
  color: white;
}

.cancel-btn:hover {
  background-color: #e04343;
}

.submit-btn {
  background-color: #409EFF;
  color: white;
}

.submit-btn:hover {
  background-color: #36b3e2;
}

/* Responsive design */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    padding: 10px;
  }

  .main-content {
    padding-left: 0;
  }

  .el-button, .cancel-btn, .submit-btn {
    width: 100%;
    font-size: 14px;
  }

  .el-row {
    flex-direction: column;
  }

  .el-col {
    margin-bottom: 10px;
  }
}

</style>
