<template>
  <div class="main-container">
    <PersonLeft></PersonLeft>
    <div class="main-content">

      <!-- Form -->
      <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
               label-width="100px" class="demo-ruleForm" :label-position="labelPostion">

        <!-- Shop Name -->
        <el-form-item label="主页名称" prop="name">
          <el-input v-model="ruleForm.name"></el-input>
        </el-form-item>

        <!-- Shop Description -->
        <el-form-item label="主页描述" prop="desption">
          <el-input v-model="ruleForm.desption"/>
        </el-form-item>

        <!-- Cover Image -->
        <el-form-item label="主页图片(小于3M)" prop="cover">
          <el-upload
              class="avatar-uploader"
              action="#"
              :auto-upload="false"
              :show-file-list="false"
              :file-list="cover.fileList"
              :on-change="coverOnChange"
              :before-upload="coverBeforeUpload">
            <img v-if="cover.imageUrl" :src="cover.imageUrl" class="avatar">
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
        </el-form-item>

        <!-- Submit and Reset Buttons -->
        <el-form-item>
          <el-button type="primary" @click="submitForm('ruleForm')">立即修改</el-button>
        </el-form-item>

      </el-form>
    </div>
  </div>
</template>


<script>
import LeftSide from "./LeftSide";
import publicJS from "../assets/js/public";
import PersonLeft from "@/components/PersonLeft.vue";

export default {
  name: "ShopInfoUpdate",
  components: {PersonLeft, LeftSide},
  data() {
    return {
      shopInfo: [],//种类
      categorys: [],//种类
      labelPostion: 'left',//表单左对齐
      ruleForm: {//form校验数据对象
        id: 0,
        name: '',
        desption: '',
        imgSrc: '',
      },
      rules: {//校验规则
        name: [
          {required: true, message: '请输入名称', trigger: 'blur'},

        ],
        desption: [
          {required: true, message: '请输入名称', trigger: 'blur'},
        ],
        imgSrc: [{required: true}]
      },
      imageSize: 3,//图片上传大小最大3MB
      cover: {
        imageUrl: '',
        fileList: [],
        changed: false
      }, user: [],

    }
  },
  methods: {
    //提交表单
    submitForm(formName) {
      this.ruleForm.cover = 1;
      this.ruleForm.show = 1;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append("name", this.ruleForm.name);
          formData.append("desption", this.ruleForm.desption);
          if (this.shopInfo.id) {
            formData.append("id", this.shopInfo.id);
          }

          if(!this.shopInfo.state){
            formData.append("state", 1);
          }else{
            formData.append("state", this.shopInfo.state);
          }

          formData.append("uid", this.user.uid);

          formData.append("cateoryId", this.ruleForm.cateoryId);
          if (this.cover.changed) {
            this.cover.fileList.forEach((item) => {
              formData.append("cover", item.raw);
            });
          }

          let config = {
            headers: {'Content-Type': 'multipart/form-data'}
          }
          this.axios.put(publicJS.methods.getBackPath() + "shop/update",
              formData, config).then(res => {
            if (res.data) {
              this.$router.replace({path: '/shopEdit'});
            }
          })
        } else {
          return false;
        }
      });
    },
    //封面图片上传前判断文件格式以及大小
    coverBeforeUpload(file) {
      const isJPEG = file.type === 'image/jpeg';
      const isJPG = file.type === 'image/jpg';
      const isPNG = file.type === 'image/png';
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!(isJPEG || isJPG || isPNG)) {
        this.$message.error("上传格式有误");
      }
      if (!isLt3M) {
        this.$message.error('上传头像图片大小不能超过 3MB!');
      }
      return (isJPEG || isJPG || isPNG) && isLt3M;
    },
    //封面图片上传图片改变事件
    coverOnChange(file, fileList) {
      let existFile = fileList
          .slice(0, fileList.length - 1)
          .find((f) => f.name === file.name);
      if (existFile) {
        this.$message.error("当前文件已经存在!");
        fileList.pop();
      }
      this.cover.changed = true;
      this.cover.imageUrl = URL.createObjectURL(file.raw);
      this.cover.fileList = fileList;
    }, //获取所有分类
    getCategory2: function () {
      this.axios.get(publicJS.methods.getBackPath() + "category/findAll").then(res => {
        this.categorys = res.data;
      })
    }, getUserInfo() {
      this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo").then(res => {
        this.user = res.data;
      })
    },
    //获取所有分类
    getCategory: function () {
      this.axios.get(publicJS.methods.getBackPath() + "shop/getCurrent").then(res => {
        if (res.data) {
          this.shopInfo = res.data;
          this.ruleForm = res.data;
          this.cover.imageUrl = res.data.imgSrc;
        }


      })
    }
  },
  mounted() {
    this.getCategory();
    this.getCategory2();
    this.getUserInfo();
  }
}
</script>


<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  display: inline-block;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
  border-radius: 6px;
}

.demo-ruleForm {
  max-width: 800px;
  margin: 30px auto;
}

.el-button {
  margin-top: 10px;
  margin-right: 10px;
}

.el-form-item {
  margin-bottom: 20px;
}

.el-form-item label {
  font-weight: 500;
}

.el-upload__tip {
  margin-top: 10px;
  font-size: 12px;
  color: #606266;
}

.el-input__inner {
  height: 36px;
}

.el-button--primary {
  margin-top: 15px;
}

.el-button--success {
  margin-left: 10px;
}.main-container {
   display: flex;
   padding: 20px;
 }
.main-content {
  flex-grow: 1;
  padding-left: 30px;
  background-color: #f9fafb;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}
</style>


