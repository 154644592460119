<template>
  <el-container class="footer-container">
    <el-row justify="center" align="middle">
      <el-col :span="24" class="footer-content">
        <span class="footer-tag">版权所有：小说网站</span>
      </el-col>
    </el-row>
  </el-container>
</template>

<script>
export default {
  name: "Footer"
}
</script>

<style scoped>
.footer-container {
  background-color: #f5f5f5; /* Light grey background */
  padding: 30px 0; /* Vertical padding */
  box-shadow: 0 -2px 5px rgba(0, 0, 0, 0.1); /* Light shadow at the top */
}

.footer-content {
  text-align: center; /* Center the content */
}

.footer-tag {
  font-size: 16px; /* Improved font size */
  color: #333; /* Dark grey for better contrast */
  font-weight: 400; /* Regular weight */
  line-height: 1.5; /* Line height for better readability */
}

@media (max-width: 768px) {
  .footer-tag {
    font-size: 14px; /* Adjust font size on small screens */
  }
}
</style>
