<template>
  <el-container>
    <!-- Header -->
    <el-header class="header">
      <Header></Header>
    </el-header>

    <!-- Main Content -->
    <el-container>
      <el-main class="main">
        <h1 class="title">小说网站</h1>
        <p class="description">欢迎来到我们的小说网站，提供丰富的小说内容，满足您的阅读需求。</p>
        <el-button type="primary" class="cta-button">立即体验</el-button>
      </el-main>

      <!-- Footer -->
      <el-footer class="footer">
        <Footer></Footer>
      </el-footer>
    </el-container>
  </el-container>
</template>

<script>
import Footer from "./Footer";
import Header from "./Header";

export default {
  name: "About",
  components: { Header, Footer }
}
</script>

<style scoped>
/* 全局设置字体和配色 */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Arial', sans-serif;
  background-color: #f5f5f5; /* 页面背景色 */
  color: #333;
}

/* Header 样式 */
.header {
  background-color: #409EFF; /* 主题颜色 */
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 22px;
}

/* Main 内容部分 */
.main {
  text-align: center;
  padding: 50px;
  background-color: #fff;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  margin-top: 40px;
}

.title {
  font-size: 36px;
  color: #409EFF;
  margin-bottom: 20px;
}

.description {
  font-size: 18px;
  color: #888;
  margin-bottom: 30px;
}

.cta-button {
  font-size: 18px;
  padding: 12px 24px;
  border-radius: 8px;
}

/* Footer 样式 */
.footer {
  background-color: #409EFF;
  color: white;
  text-align: center;
  padding: 15px;
  font-size: 14px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .title {
    font-size: 28px;
  }

  .description {
    font-size: 16px;
  }

  .cta-button {
    font-size: 16px;
    padding: 10px 20px;
  }
}
</style>
