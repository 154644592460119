<template>
  <el-container>
    <el-header style="height: auto;">
      <Header></Header>
    </el-header>
    <el-container>
      <el-aside class="newservice" width="200px">
        <el-row>
          <h2>分类</h2>
        </el-row>
        <el-menu
            background-color="#F7F9FA"
            default-active="1"
            class="el-menu-vertical-demo ervice-bd"
            @select="handleSelect">
          <el-menu-item class="item" v-for="(c,index) in category" :key="index" :index="c.cid">

            <span slot="title" v-text="c.cname"></span>
          </el-menu-item>
        </el-menu>
      </el-aside>
      <el-container>
        <el-main>
          <el-row>
            <el-tag effect="dark" style="font-size: 13px"><i class="el-icon-notebook-2"></i>书籍分类</el-tag>
          </el-row>
          <el-row class="product-list">
            <el-col :span="8" v-for="(furniture, index) in furniturePage.records"  class="content-item product-col"
                   :index="furniture.fid"
                   :key="index">
                <router-link :to="goDetail(furniture.fid,furniture.category.englishName)">
                  <el-image class="img-wrapper"
                            :src="urlArray[index]"></el-image>
                </router-link>

                <div class="info-wrapper">
                  <div class="title">

                    <router-link :to="goDetail(furniture.fid,furniture.category.englishName)"
                                 style="color: #303133">{{ furniture.fname | ellipsis3 }}
                    </router-link>
                  </div>
                  <div class="tag-list">


                    <div class="tag-item">
                      <i class="el-icon-s-unfold"></i>
                      <router-link style="color: black;text-decoration: underline"
                                   :to="goShop(furniture.sid)">{{ furniture.shop.name | ellipsis }}
                      </router-link>

                    </div>
                    <span style="float: right;padding-right: 15px;">{{ furniture.address | ellipsis2 }}</span>
                  </div>
                </div>

                <div class="price-wrapper">
              <span class="price-value">  <em>￥</em>
                {{ furniture.price }}

              </span>

                </div>

              </div>
            </el-col>
          </el-row>

          <!--页码-->
          <div style="width: 100%;float: right">
            <!--少于等于7页-->
            <div v-if="furniturePage.pages<=7" class="block" style="float: right;margin-right: 100px">
              <el-pagination
                  layout="prev, pager, next"
                  background
                  :total="furniturePage.total"
                  :page-size="furniturePage.size"
                  :pager-count="7"
                  @current-change="changePage">
              </el-pagination>
            </div>
            <!--大于7页-->
            <div v-if="furniturePage.pages>7" class="block" style="float: right;margin-right: 100px">
              <el-pagination
                  layout="prev, pager, next"
                  background
                  :total="furniturePage.total"
                  :page-size="furniturePage.size"
                  :pager-count="9"
                  @current-change="changePage">
              </el-pagination>
            </div>
          </div>
        </el-main>
        <el-footer>
          <Footer></Footer>
        </el-footer>
      </el-container>
    </el-container>
  </el-container>


</template>

<script>
import Header from "./Header";
import publicJS from '../assets/js/public';
import Footer from "./Footer";

export default {
  name: "ProductShow",
  components: {Footer, Header},
  data() {
    return {
      category: [],
      currentCid: 0,
      furniturePage: {},
      urlArray: []
    }
  },
  filters: {
    ellipsis(value) {
      if (!value) return '';
      if (value.length > 10) {
        return value.slice(0, 12) + '...'
      }
      return value
    }, ellipsis2(value) {
      if (!value) return '';
      if (value.length > 5) {
        return value.slice(0, 5) + '...'
      }
      return value
    }, ellipsis3(value) {
      if (!value) return '';
      if (value.length > 20) {
        return value.slice(0, 20) + '...'
      }
      return value
    }
  }
  ,
  methods: {
    //获取所有种类
    async getCategories() {
      var res = await this.axios.get(publicJS.methods.getBackPath() + "category/findAll");
      return res;
    },
    //根据种类获取所有商品
    getfurniture(currentPage) {
      this.axios.get(publicJS.methods.getBackPath() + "f/findByCategory/" + currentPage + "/" + this.currentCid).then(res => {
        this.furniturePage = res.data;
        var array = [];
        var basePath;
        for (let furniture of res.data.records) {
          array.push(furniture.image.cover);
        }
        this.urlArray = array;
      })
    },
    //去详情页
    goDetail(fid, fname) {
      return "/detail?fid=" + fid + "&name=" + fname;
    }, goShop(id) {
      return "/showShops?id=" + id;
    },
    //导航栏改变事件
    handleSelect(key, keyPath) {
      this.currentCid = key;
      this.getfurniture(1);
    },
    changePage(page) {
      this.getfurniture(page);
    }
  },
  mounted() {
    this.getCategories().then(res => {
      this.category = res.data;
      this.currentCid = this.category[0].cid;
      this.getfurniture(1);
    })
  }
}
</script>

<style>
.newservice {
  margin: 24px;
  position: relative;
  font-size: 14px;
  background-color: #FFFFFF; /* 更加明亮的白色背景 */
  width: 100%;
  max-width: 270px; /* 设置最大宽度 */
  border-radius: 16px; /* 增加圆角效果 */
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); /* 增加柔和阴影 */
  transition: transform 0.3s ease; /* 增加平滑过渡效果 */
  overflow: hidden; /* 确保子元素不超出边界 */
}

.newservice:hover {
  transform: translateY(-5px); /* 鼠标悬停时轻微抬升 */
}

.newservice h2 {
  padding: 20px;
  font-size: 20px; /* 增加标题字体大小 */
  font-weight: 600; /* 加粗字体 */
  color: #333333; /* 更深的文字颜色 */
  line-height: 28px;
  text-transform: uppercase; /* 大写字体 */
  letter-spacing: 1px; /* 字符间距 */
}

.newservice .service-bd {
  padding: 16px 24px; /* 增加左右内边距 */
  border-top: 2px solid #E5E5E5; /* 增加顶部边框 */
  background-color: #F9F9F9; /* 设置背景色 */
}

.newservice .item {
  margin-bottom: 16px;  /* 增加项之间的间距 */
  font-size: 15px;  /* 增加字体大小 */
  color: #666666;  /* 字体颜色 */
  cursor: pointer;
  display: flex;  /* 使用flex布局来对齐文字 */
  align-items: center;  /* 垂直居中 */
  justify-content: flex-start;  /* 左对齐 */
  height: 40px;  /* 增加高度以保证垂直居中 */
  transition: color 0.3s ease, transform 0.3s ease;
  padding-left: 16px;  /* 给左边留出一些间距 */
  text-align: left;  /* 左对齐 */
}

.newservice .item:hover {
  color: #FF5000;  /* 鼠标悬停时改变文字颜色 */
  transform: scale(1.05);  /* 鼠标悬停时放大 */
}


.newservice .service-arrow {
  margin-right: 10px;
  font-size: 18px;
  width: 18px;
  height: 18px;
}

.content-item {
  width: 100%;  /* 设置为100%宽度，并通过max-width限制最大宽度 */
  max-width: 372px;  /* 设置最大宽度，避免过宽 */
  margin: 0 0 20px 20px;  /* 增加底部间距 */
  position: relative;
  background-color: #fff;  /* 白色背景，增加视觉清晰度 */
  border-radius: 16px;  /* 增加圆角效果 */
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);  /* 增加柔和的阴影 */
  overflow: hidden;  /* 防止内容溢出 */
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;  /* 平滑过渡效果 */
  padding: 16px;  /* 增加内边距 */


  flex-direction: column;
  align-items: stretch; /* 拉伸到父容器 */
  justify-content: space-between;

}

.content-item:hover {
  transform: translateY(-5px);  /* 鼠标悬停时稍微上移 */
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.2);  /* 鼠标悬停时加强阴影 */
}

.img-wrapper {
  position: absolute;
  width: 160px;  /* 使图片更加突出 */
  height: 160px;
  overflow: hidden;
  top: 50%;
  left: 0;
  transform: translateY(-50%);  /* 图片垂直居中 */
  background-color: rgba(27, 23, 67, 0.03);
  border-radius: 12px;  /* 圆角效果 */
}

.info-wrapper {
  margin-left: 180px;  /* 调整右侧信息部分的左边距 */
  padding: 10px 0;  /* 增加上下内边距 */
  overflow: hidden;
}

.title {
  font-size: 18px;  /* 增大字体，突出显示标题 */
  color: #333;  /* 深色字体 */
  font-weight: 600;  /* 加粗字体 */
  line-height: 24px;
  margin-bottom: 8px;  /* 增加下边距 */
  overflow: hidden;
  text-overflow: ellipsis;  /* 超出部分显示省略号 */
  white-space: nowrap;  /* 禁止文本换行 */
  transition: color 0.3s ease;  /* 平滑过渡效果 */
}

.title:hover {
  color: #FF5000;  /* 鼠标悬停时改变颜色 */
}

.tag-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;  /* 增加标签间距 */
  margin-bottom: 8px;  /* 增加标签部分的底部间距 */
}

.tag-item {
  display: inline-block;
  background-color: #FF5000;  /* 标签背景颜色 */
  color: #fff;  /* 白色字体 */
  padding: 5px 10px;
  font-size: 12px;
  border-radius: 20px;  /* 圆角标签 */
  transition: background-color 0.3s ease;  /* 标签的背景颜色变化 */
}

.tag-item:hover {
  background-color: #FF7200;  /* 鼠标悬停时改变标签背景颜色 */
}

.price-wrapper {
  margin-left: 180px;  /* 保证价格部分与标题、标签对齐 */
  font-size: 22px;  /* 较大字体显示价格 */
  color: #FF5000;  /* 价格使用强调色 */
}

.price-value em {
  margin-right: 4px;
  font-size: 16px;  /* 小号的人民币符号 */
  font-family: "Microsoft Yahei";
  vertical-align: middle;
}

</style>
