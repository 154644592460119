<template>
  <div class="main-container">
    <PersonLeft></PersonLeft>
    <div class="main-content">
      <!-- Page Header -->
      <el-row style="width: 100%;float: left;border-bottom: 1px solid #eee">
        <el-page-header @back="goIndex" content="主页信息"></el-page-header>
      </el-row>

      <!-- Action Buttons -->
      <el-row>

          <router-link :to="goUpdate()" class="el-button" style="color: #66b1ff">编辑主页信息</router-link>

        <el-button class="el-button" @click="goShopInfo()">
          去个人主页
        </el-button>
      </el-row>

      <!-- Shop Info Descriptions -->
      <el-descriptions style="float: left;width: 100%" class="margin-top" :column="3" border>
        <el-descriptions-item>
          <template slot="label">
            <i class="el-icon-user"></i>
            用户名
          </template>
          {{ user.uname }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            主页状态
          </template>
          {{ shopInfo.state == 1 ? "已通过审核" : "审核中" }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            主页名称
          </template>
          {{ shopInfo.name }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            主页描述
          </template>
          {{ shopInfo.desption }}
        </el-descriptions-item>

        <el-descriptions-item>
          <template slot="label">
            主页Logo
          </template>
          <el-image
              style="width: 300px; height: auto"
              :src="imgInfo"
              :fit="contain">
          </el-image>
        </el-descriptions-item>
      </el-descriptions>
    </div>
  </div>
</template>


<script>
import LeftSide from "./LeftSide";
import publicJS from "../assets/js/public";
import '../assets/css/public.css'
import PersonLeft from "@/components/PersonLeft.vue";

export default {
  name: "ShopEdit",
  components: {PersonLeft, LeftSide},
  data() {
    return {
      shopInfo: {},
      user: {},
      imgInfo: '',
      categorys: [],
    }
  },
  addDialogVisible: false,
  methods: {
    goShopInfo() {
      this.$router.replace({path: '/showShops?id=' + this.shopInfo.id});
    },

    //获取用户信息
    getUserInfo() {
      this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo").then(res => {
        this.user = res.data;
        this.updateUser = res.data;
      })
    },
    //获取所有分类
    getCategory2: function () {
      this.axios.get(publicJS.methods.getBackPath() + "category/findAll").then(res => {
        this.categorys = res.data;
      })
    },
    goIndex() {
      this.$router.replace({path: '/'});
    },
    //获取商品信息
    loadShopInfo() {
      this.axios.get(publicJS.methods.getBackPath() + "shop/getCurrent").then(res => {
        this.shopInfo = res.data;
        this.imgInfo = res.data.imgSrc;
      })
    }, goUpdate() {
      return "/shopInfoUpdate";
    }
  },
  mounted() {
    this.getUserInfo();
    this.loadShopInfo();
    this.getCategory2();
  }
}
</script>

<style scoped>
/* Container for the page layout */
.main-container {
  display: flex;
  padding: 20px;
}

/* Main content area */
.main-content {
  flex-grow: 1;
  padding-left: 20px;
  background-color: #f9fafb;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Descriptions component styling */
.el-descriptions {
  margin-top: 20px;
}

.el-descriptions-item {
  font-size: 14px;
  margin-bottom: 10px;
}

.el-descriptions-item .el-icon-user {
  color: #409EFF;
}

/* Image display style */
.el-image {
  border-radius: 6px;
  border: 1px solid #dcdfe6;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  margin-top: 10px;
}

.el-button {
  font-size: 14px;
  margin-top: 10px;
}

.el-button:hover {
  background-color: #409EFF;
  color: #fff;
}

/* Custom button styles for action buttons */
.el-button a {
  color: #409EFF;
  text-decoration: none;
}

.el-button a:hover {
  text-decoration: underline;
}

/* Row styling for button group */
.el-row {
  margin-top: 20px;
}

/* Page Header */
.el-page-header {
  margin-bottom: 20px;
}

/* Margin adjustment for top spacing of components */
.margin-top {
  margin-top: 20px;
}

/* Style for the "待编辑" or "已经通过审核" statuses */
.el-tag {
  font-size: 14px;
  margin-top: 10px;
}

/* Page Header style */
.el-page-header {
  padding-bottom: 15px;
}
</style>

