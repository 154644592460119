<template>
  <div style="width: 800px;margin: 0 auto">
    <!--表单-->
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
             label-width="100px" class="demo-ruleForm" :label-position="labelPostion">
      <!--商品名称-->
      <el-form-item label="名称" prop="fname">
        <el-input type="textarea" v-model="ruleForm.fname"></el-input>

      </el-form-item>
      <!--商品分类-->
      <el-form-item label="书籍分类" prop="cid">
        <el-select v-model="ruleForm.cid">
          <el-option v-for="category in categorys" :label="category.cname" :value="category.cid"/>
        </el-select>
      </el-form-item>
      <!--商品价格-->
      <el-form-item label="价格" prop="price">
        <el-input v-model="ruleForm.price"/>
      </el-form-item>
      <!--封面图片-->
      <el-form-item label="封面图片(小于3M)" prop="cover">
        <el-upload
            class="avatar-uploader"
            action="#"
            :auto-upload="false"
            :show-file-list="false"
            :file-list="cover.fileList"
            :on-change="coverOnChange"
            :before-upload="coverBeforeUpload">
          <img v-if="cover.imageUrl" :src="cover.imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>


      <el-form-item label="发布时间" prop="date">
        <el-time-picker placeholder="选择时间" v-model="ruleForm.date" style="width: 100%;"></el-time-picker>

      </el-form-item>
      <!--提交、重置-->
      <el-form-item>
        <el-button type="primary" @click="submitForm('ruleForm')">立即添加</el-button>
        <el-button @click="resetForm('ruleForm')">重置</el-button>
      </el-form-item>
    </el-form>
  </div>
</template>

<script>
import publicJS from '../assets/js/public'
import {Form} from "element-ui";

export default {
  name: "Insert",
  data() {
    return {
      categorys: [],//种类
      labelPostion: 'left',//表单左对齐
      ruleForm: {//form校验数据对象
        fname: '',
        cid: '',
        price: '',
        cover: '',
        show: '',
        sid: 0
      }, action: "", goodsId: "",
      rules: {//校验规则
        fname: [
          {required: true, message: '请输入商品名称', trigger: 'blur'},
          {min: 2, max: 2000, message: '长度在 2 到 2000 个字符', trigger: 'blur'}
        ],
        cid: [
          {required: true, message: '请选择商品分类', trigger: 'change'}
        ],
        price: [
          {
            required: true,
            pattern: /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/,
            message: "请输入合法的金额数字，最多两位小数",
            trigger: 'blur'
          }
        ],
        cover: [{required: true}],
        show: [{required: true}], date: [{required: true}], list: [{required: true}]
      },
      imageSize: 3,//图片上传大小最大3MB
      cover: {
        dialogImageUrl: '',
        fileList: []
      },
      show: {
        imageUrl: '',
        dialogVisible: false,
        fileList: []
      }, imageUrl: ''
    }
  },
  methods: {
    uuid() {
      var s = [];
      var hexDigits = "0123456789abcdef";
      for (var i = 0; i < 36; i++) {
        s[i] = hexDigits.substr(Math.floor(Math.random() * 0x10), 1);
      }
      s[14] = "4"; // bits 12-15 of the time_hi_and_version field to 0010
      s[19] = hexDigits.substr((s[19] & 0x3) | 0x8, 1); // bits 6-7 of the clock_seq_hi_and_reserved to 01
      s[8] = s[13] = s[18] = s[23] = "-";

      var uuid = s.join("");
      return uuid;
    }
    ,
    //提交表单
    submitForm(formName) {
      if (this.cover.fileList.length < 1) {
        this.$message.error("封面图片还未上传");
        return false;
      }

      this.ruleForm.cover = 1;
      this.ruleForm.show = 1;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append("fname", this.ruleForm.fname);
          formData.append("cid", this.ruleForm.cid);
          formData.append("price", this.ruleForm.price);

          formData.append("uid", this.user.uid);
          formData.append("sid", this.shopInfo.id);
          formData.append("type", "1");
          formData.append("date", this.ruleForm.date);
          this.cover.fileList.forEach((item) => {
            formData.append("cover", item.raw);
          });
          this.show.fileList.forEach((item) => {
            formData.append("show", item.raw);
          });
          let config = {
            headers: {'Content-Type': 'multipart/form-data'}
          }
          this.axios.post(publicJS.methods.getBackPath() + "f/insert",
              formData, config).then(res => {
            if (res.data) {
              this.$message.success("添加成功");
              //添加成功刷新页面
              this.$router.replace({path: '/refresh'});
            } else {
              this.$message.error("添加失败，请联系站长");
            }
          })
        } else {
          return false;
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.$refs[formName].resetFields();
      this.imageUrl = "";
      this.$refs.show.clearFiles();
      this.coverFileList = [];
      this.fileList = [];
    },
    //封面图片上传前判断文件格式以及大小
    coverBeforeUpload(file) {
      const isJPEG = file.type === 'image/jpeg';
      const isJPG = file.type === 'image/jpg';
      const isPNG = file.type === 'image/png';
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!(isJPEG || isJPG || isPNG)) {
        this.$message.error("上传格式有误");
      }
      if (!isLt3M) {
        this.$message.error('上传头像图片大小不能超过 3MB!');
      }
      return (isJPEG || isJPG || isPNG) && isLt3M;
    },
    //封面图片上传图片改变事件
    coverOnChange(file, fileList) {
      let existFile = fileList
          .slice(0, fileList.length - 1)
          .find((f) => f.name === file.name);
      if (existFile) {
        this.$message.error("当前文件已经存在!");
        fileList.pop();
      }
      this.cover.imageUrl = URL.createObjectURL(file.raw);
      this.cover.fileList = fileList;
    },
    //展示图片移除前事件
    showBeforeRemove(file, fileList) {
      this.show.fileList = fileList;
    },
    //展示图片移除时事件
    showHandleRemove(file, fileList) {
      var array = [];
      for (let f of fileList) {
        if (file.name === f.name) {
          continue;
        }
        array.push(f);
      }
      this.show.fileList = array;
    },
    //展示图片改变事件
    showUploadChange(file, fileList) {
      console.log(file);
      let existFile = fileList
          .slice(0, fileList.length - 1)
          .find((f) => f.name === file.name);

      const isEnough = fileList.length <= 4;
      const type = file.name.substring(file.name.lastIndexOf("."));
      const isJPEG = type === '.jpeg';
      const isJPG = type === '.jpg';
      const isPNG = type === '.png';
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!isEnough) {
        this.$message.error("上传数量超出限制");
        fileList.pop();
      }
      if (!(isJPEG || isJPG || isPNG)) {
        this.$message.error("上传格式有误");
        fileList.pop();
      }
      if (!isLt3M) {
        this.$message.error('上传头像图片大小不能超过 3MB!');
        fileList.pop();
      }

      if (existFile) {
        this.$message.error("当前文件已经存在!");
        fileList.pop();
      }
      this.show.fileList = fileList;
    },
    //展示图片预览事件
    showHandlePictureCardPreview(file) {
      this.show.dialogImageUrl = file.url;
      this.show.dialogVisible = true;
    },
    //获取所有分类
    getCategory: function () {
      this.axios.get(publicJS.methods.getBackPath() + "category/findAll").then(res => {
        this.categorys = res.data;
      })
    }, loadShopInfo() {
      this.axios.get(publicJS.methods.getBackPath() + "shop/getCurrent").then(res => {
        this.shopInfo = res.data;
      })
    }, getUser() {
      this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo").then(res => {
        this.user = res.data;
      })
    }

  },
  mounted() {
    this.goodsId = this.uuid();
    this.action = publicJS.methods.getBackPath() + "res/upload/" + this.goodsId;
    this.getCategory();
    this.getUser();
    this.loadShopInfo();
  }
}
</script>


<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}

.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}

.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}

.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}

.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
