<template>
  <div class="main-container">
    <LeftSide></LeftSide>
    <div class="main-content">
      <el-row class="header-row">
        <el-page-header @back="goIndex" content="评论管理" class="header-content"></el-page-header>
      </el-row>

      <el-table :data="commentPage.records" stripe class="comment-table">
        <el-table-column type="index" />
        <el-table-column prop="user.uname" label="用户名" />
        <el-table-column label="评论商品名">
          <template slot-scope="scope">
            <el-link :underline="false" type="primary" @click="goFurniture(scope.row.books)">
              {{scope.row.books.fname}}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column prop="ctime" label="评论时间" />
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                type="primary"
                size="mini"
                @click="currentContent = scope.row.content; detailVisible = true;"
                class="action-btn"
            >查看详情</el-button>
            <el-button
                type="danger"
                size="mini"
                @click="del(scope.row.cid)"
                class="action-btn"
            >删除</el-button>
          </template>
        </el-table-column>
      </el-table>

      <div class="pagination-container">
        <el-pagination
            v-if="commentPage.pages <= 7"
            layout="prev, pager, next"
            background
            :total="commentPage.total"
            :page-size="commentPage.size"
            :pager-count="7"
            @current-change="changePage"
        ></el-pagination>

        <el-pagination
            v-if="commentPage.pages > 7"
            layout="prev, pager, next"
            background
            :total="commentPage.total"
            :page-size="commentPage.size"
            :pager-count="9"
            @current-change="changePage"
        ></el-pagination>
      </div>

      <el-dialog :visible.sync="detailVisible" title="评论详情" class="comment-detail-dialog">
        <span v-html="currentContent"></span>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import LeftSide from "./LeftSide";
import publicJS from "../assets/js/public";
export default {
  name: "ManageComment",
  components: {LeftSide},
  data(){
    return{
      commentPage:{},
      detailVisible:false,
      currentContent:''
    }
  },
  methods:{
    getComment(currentPage){
      this.axios.get(publicJS.methods.getBackPath()+"comment/findAll/"+currentPage).
      then(res=>{
        this.commentPage = res.data;
      })
    },
    del(cid){
        this.$confirm('此评论将移入回收站, 是否继续?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          this.axios.delete(publicJS.methods.getBackPath()+"comment/del/"+cid).
          then(res=>{
            if(res.data){
              this.$message.success("删除成功");
              this.$router.replace({path:'/refresh'});
            }
            else{
              this.$message.success("操作失败,请联系管理员");
            }
          })
        }).catch(() => {
        });
    },
    goFurniture(furniture){
      this.$router.replace({path:'/detail',query:{fid:furniture.fid,name:furniture.category.englishName}});
    },
    goIndex(){
      this.$router.replace({path:'/'});
    },
    changePage(page){
      this.getComment(page);
    }
  },
  mounted() {
    this.getComment(1);
  }
}
</script>

<style scoped>
/* Main container and content */
.main-container {
  display: flex;
  padding: 20px;
}

.main-content {
  flex-grow: 1;
  padding-left: 20px;
  background-color: #f9fafb;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Header row */
.header-row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.header-content {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

/* Table styling */
.comment-table {
  margin-top: 20px;
  width: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.el-table-column label {
  font-weight: 500;
  color: #333;
}

.el-table-row:hover {
  background-color: #f4f7fb;
}

/* Action buttons styling */
.action-btn {
  font-size: 14px;
  border-radius: 8px;
  padding: 6px 12px;
  transition: all 0.3s ease;
}

.action-btn[type="primary"] {
  background-color: #409EFF;
  color: white;
}

.action-btn[type="primary"]:hover {
  background-color: #36b3e2;
}

.action-btn[type="danger"] {
  background-color: #f56c6c;
  color: white;
}

.action-btn[type="danger"]:hover {
  background-color: #e04343;
}

/* Pagination container styling */
.pagination-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.el-pagination {
  margin-right: 100px;
}

/* Dialog box styling */



</style>
