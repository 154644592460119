<template>
  <div class="main-container">
    <!-- 左侧菜单 -->
    <LeftSide></LeftSide>

    <!-- 右侧内容区域 -->
    <div class="main-content">
      <el-row class="header-row">
        <el-col :span="20">
          <el-page-header @back="goIndex" content="创作者申请管理" class="header-content"></el-page-header>
        </el-col>
      </el-row>

      <!-- 数据表格 -->
      <el-table :data="users" stripe class="data-table">
        <el-table-column type="index" />
        <el-table-column prop="userName" label="用户名称" />
        <el-table-column prop="state" label="申请状态" />
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <!-- 如果状态不为“通过”，则显示“通过”按钮 -->
            <el-button
                size="mini"
                v-if="scope.row.state !== '正常'"
                type="primary"
                @click="openUpdate(scope.row.id)"
                class="action-btn"
            >
              通过
            </el-button>
            <!-- 如果状态为“通过”，则显示“已通过”并禁用 -->
            <el-button
                size="mini"
                v-if="scope.row.state === '正常'"
                type="primary"
                disabled
                class="action-btn"
            >
              已通过
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>


<script>
import LeftSide from "./LeftSide";
import publicJS from "../assets/js/public";

export default {
  name: "ShopManager",
  components: { LeftSide },
  data() {
    return {
      users: [],
      userForm: {
        id: 0,
        userName: "",
        state: "",
      },
    };
  },
  methods: {
    goIndex() {
      this.$router.replace({path: "/"}); // 返回首页
    },

    // 修改用户按钮点击事件，调用 shop/pass API
    openUpdate(uid) {
      this.axios
          .get(publicJS.methods.getBackPath() + "shop/pass/" + uid)
          .then((response) => {
            this.getCategory(); // 更新用户列表
          });
    },

    // 获取所有用户列表
    getCategory() {
      this.axios
          .get(publicJS.methods.getBackPath() + "shop/list")
          .then((res) => {
            this.users = res.data; // 获取到的用户数据
          });
    },
  },
  mounted() {
    this.getCategory(); // 页面加载完成后获取用户数据
  },
};
</script>

<style scoped>
/* Main container and content */
.main-container {
  display: flex;
  padding: 20px;
}

.main-content {
  flex-grow: 1;
  padding-left: 20px;
  background-color: #f9fafb;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Header row */
.header-row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.header-content {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

/* Action buttons */
.action-btn {
  border-radius: 8px;
  font-size: 14px;
  padding: 6px 12px;
  transition: all 0.3s ease;
}

.action-btn[type="primary"] {
  background-color: #409EFF;
  color: white;
}

.action-btn[type="primary"]:hover {
  background-color: #36b3e2;
}

.el-button--mini {
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 6px;
}

/* Table styling */
.data-table {
  width: 100%;
  margin-top: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.el-table-column label {
  font-weight: 500;
  color: #333;
}

.el-table-row:hover {
  background-color: #f4f7fb;
}

/* Pagination styling */
.el-pagination {
  margin-right: 100px;
}



.el-form-item {
  margin-bottom: 20px;
}

.el-button {
  width: 100%;
  font-size: 16px;
}

/* Responsive design */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    padding: 10px;
  }

  .main-content {
    padding-left: 0;
  }

  .el-button, .submit-btn {
    width: 100%;
    font-size: 14px;
  }

  .el-row {
    flex-direction: column;
  }

  .el-col {
    margin-bottom: 10px;
  }
}

</style>
