import Vue from 'vue'
import Router from 'vue-router'
import Detail from "../components/ShopDetail.vue";

import Refresh from "../components/Refresh";
import Update from "../components/Update";
import ProductShow from "../components/ProductShow";
import ManageShop from "../components/ManageFurniture";
import Login from "../components/Login";
import Register from '../components/Register';

import ShopCar from "../components/ShopCar";
import ManageComment from "../components/ManageComment";
import ManageOrder from "../components/ManageOrder";
import Personal from "../components/PersonalInfo.vue";
import ManageUser from "../components/ManageUser.vue";
import Order from "../components/UserOrder.vue";
import About from "../components/AboutWebsite.vue";
import Chart from "../components/Chart";
import ClassifyManager from "../components/ManagerClassify.vue";
import ShopManager from "../components/ManagerShop.vue";
import ShopEdit from "../components/ShopEdit";
import ShopInfoUpdate from "../components/ShopInfoUpdate";
import Attention from "../components/AttentionUser.vue";
import Material from "../components/ManagerMaterial.vue";
import ShopShow from "../components/ShopShow";
import ManageReward from "../components/ManageReward";

Vue.use(Router)

export default new Router({
    routes: [
        {path: '/', redirect: "/show"},
        {path: '/detail', component: Detail},
        {path: '/update', component: Update},
        {path: '/show', component: ProductShow},
        {path: '/showShops', component: ShopShow},
        {path: '/material', component: Material},

        {path: '/login', component: Login},
        {path: '/register', component: Register},
        {path: '/shopcar', component: ShopCar},
        {path: '/manageOrder', component: ManageOrder},
        {path: '/manageUser', component: ManageUser},
        {path: '/personal', component: Personal},
        {path: '/order', component: Order},
        {path: '/about', component: About},
        {path: '/chart', component: Chart},
        {path: '/refresh', component: Refresh},
        {path: '/classifyManager', component: ClassifyManager},
        {path: '/shopManager', component: ShopManager},
        {path: '/shopEdit', component: ShopEdit},
        {path: '/shopInfoUpdate', component: ShopInfoUpdate},
        {path: '/managefurniture', component: ManageShop},
        {path: '/attention', component: Attention},
        {path: '/manageReward', component: ManageReward},
        {path: '/manageComment', component: ManageComment}


    ]
})
