<template>
  <div class="login-container">
    <div class="form-container">
      <el-form :model="userForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <!-- 用户名 -->
        <el-form-item label="用户名" prop="uname">
          <el-input type="text" v-model="userForm.uname" autocomplete="off" placeholder="请输入用户名"></el-input>
        </el-form-item>

        <!-- 密码 -->
        <el-form-item label="密码" prop="passwd">
          <el-input type="password" v-model="userForm.passwd" autocomplete="off" placeholder="请输入密码"></el-input>
        </el-form-item>

        <!-- 验证码（可选）-->
        <el-form-item label="验证码" prop="code">
          <div class="code-container">
            <el-image style="cursor: pointer" :src="codeSrc" @click="changeCode"></el-image>
            <el-input style="width: 70%" type="text" v-model="userForm.code" autocomplete="off" placeholder="请输入验证码"></el-input>
          </div>
        </el-form-item>

        <!-- 提交按钮 -->
        <el-form-item>
          <el-button type="primary" @click="onSubmit('ruleForm')" class="submit-button">登录</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import publicJS from '../assets/js/public';
export default {
  name: "Login",
  data() {
    return {
      codeSrc: '',
      userForm: {
        uname: '',
        passwd: '',
        code: ''
      },
      rules: {
        uname: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        passwd: [{ required: true, message: '请输入密码', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    }
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          formData.append("uname", this.userForm.uname);
          formData.append("passwd", this.userForm.passwd);
          formData.append("code", this.userForm.code);
          this.axios.post(publicJS.methods.getBackPath() + "user/login", formData)
              .then(res => {
                if (res.data.status) {
                  this.$message.success("登录成功");
                  this.$router.replace({ path: '/' });
                } else {
                  this.$message.error(res.data.msg);
                  this.changeCode();
                }
              });
        } else {
          return false;
        }
      });
    },
    changeCode() {
      this.codeSrc = publicJS.methods.getBackPath() + "checkCode?timestamp=" + new Date();
    }
  },
  mounted() {
    this.changeCode();
  }
}
</script>

<style scoped>
/* 登录页容器 */
.login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f5f5f5;
}

.form-container {
  width: 100%;
  max-width: 400px;
  padding: 40px;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

/* 表单标题 */
.demo-ruleForm h1 {
  font-size: 28px;
  text-align: center;
  color: #409EFF;
  margin-bottom: 20px;
}

/* 表单项 */
.el-form-item {
  margin-bottom: 20px;
}

/* 提交按钮 */
.submit-button {
  width: 100%;
  font-size: 16px;
  padding: 12px 0;
  border-radius: 8px;
  background-color: #409EFF;
  color: white;
}

/* 验证码输入框 */
.code-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.code-container el-image {
  cursor: pointer;
  width: 30%;
  height: 40px;
  object-fit: contain;
}

.el-input {
  width: 65%;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .form-container {
    padding: 20px;
    width: 90%;
  }

  .demo-ruleForm h1 {
    font-size: 24px;
  }

  .submit-button {
    font-size: 14px;
  }

  .code-container {
    flex-direction: column;
    align-items: center;
  }

  .code-container el-image {
    margin-bottom: 10px;
  }
}
</style>
