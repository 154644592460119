<template>
  <div class="main-container">
    <PersonLeft></PersonLeft>
    <div class="main-content">
      <el-row style="float: left; border-bottom: 1px solid #eee;">
        <el-page-header @back="goIndex" content="购物车"></el-page-header>
      </el-row>
      <el-table
          ref="videoList"
          :data="shopCar"
          border
          @selection-change="tableChange"
          stripe
          style="margin-top: 20px;">
        <el-table-column type="selection" :selectable="selected" disabled="true"/>
        <el-table-column label="序号" type="index" width="50px"/>
        <el-table-column prop="goods.fname" label="商品名称">
          <template slot-scope="scope">
            <el-link :underline="false" type="primary" @click="gogoods(scope.row.goods)">
              {{ scope.row.goods.fname }}
            </el-link>
          </template>
        </el-table-column>
        <el-table-column label="封面图片" width="200px">
          <template slot-scope="scope">
            <el-image style="width: 180px;height: 180px" :src="scope.row.goods.image.cover"/>
          </template>
        </el-table-column>
        <el-table-column property="goods.category.cname" label="种类"/>
        <el-table-column prop="goods.price" label="价格"/>
        <el-table-column prop="count" label="数量"/>
        <el-table-column label="状态">
          <template slot-scope="scope">
            <el-tag effect="dark" v-if="scope.row.goods.del===0">等待支付</el-tag>
            <el-tag type="danger" effect="dark" v-else>已下架</el-tag>
          </template>
        </el-table-column>
      </el-table>
      <div style="display: flex; justify-content: flex-end; margin-top: 20px;">
        <el-button type="primary" style="margin-right: 10px;" @click="orderVisible = true" :disabled="selectShopCar.length == 0">
          加入购物车
        </el-button>
        <el-button type="danger" @click="del" :disabled="selectShopCar.length === 0">
          删除
        </el-button>
      </div>
      <el-tag v-show="total != 0" style="margin-top: 10px;">
        预计报酬: {{ total }}元
      </el-tag>
    </div>

    <!--下单-->
    <el-dialog title="支付" :visible.sync="orderVisible">
      <el-table
          :data="selectShopCar"
          border
          width="100%"
          show-summary="true">
        <el-table-column prop="goods.fname" label="商品名称"/>
        <el-table-column prop="count" label="小说数量"/>
        <el-table-column prop="total" label="单项总价"/>
      </el-table>
      <el-form :model="orderForm" :rules="orderRule" ref="orderForm">
        <el-form-item label="姓名" prop="name">
          <el-input v-model="orderForm.name" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="手机号" prop="phoneNum">
          <el-input v-model="orderForm.phoneNum" autocomplete="off"></el-input>
        </el-form-item>
        <el-form-item label="收货地址" prop="recAddr">
          <el-input v-model="orderForm.recAddr" autocomplete="off"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="resetForm('orderForm')">取消</el-button>
        <el-button type="primary" @click="submitForm('orderForm')">确认下单</el-button>
      </div>
    </el-dialog>
    <Footer></Footer>
  </div>
</template>


<script>
import publicJS from "../assets/js/public";
import PersonLeft from "./PersonLeft";

export default {
  name: "ShopCar",
  components: {PersonLeft},
  data() {
    return {
      shopCar: [],
      selectShopCar: [],
      total: 0,
      orderForm: {
        name: '',
        phoneNum: '',
        recAddr: ''
      },
      orderRule: {
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
          {min: 2, max: 255, message: '长度在 2 到 255 个字符', trigger: 'blur'}
        ],
        phoneNum: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {
            pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }],
        recAddr: [
          {required: true, message: '请输入发货地址', trigger: 'blur'},
          {min: 2, max: 255, message: '长度限定在2-255位', trigger: 'blur'}
        ]
      },
      orderVisible: false
    }
  },
  methods: {
    goIndex() {
      this.$router.replace({path: '/'});
    },
    async getUserInfo() {
      var res = await this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo");
      return res.data;
    },
    //获取购物车
    getShopCar() {
      this.axios.get(publicJS.methods.getBackPath() + "shopcar/findShopCars").then(res => {

        this.shopCar = res.data;
      })
    },
    //购物车选择改变事件
    tableChange(val) {
      this.selectRows = []
      if (val.length > 1) {
        this.$refs.videoList.clearSelection()
        this.$refs.videoList.toggleRowSelection(
            val[val.length - 1]
        )
      }
      this.selectRows = [val[val.length - 1]]

      this.selectShopCar = this.selectRows;
      let total = 0;
      val.forEach(shopCar => {
        shopCar.total = shopCar.count * shopCar.goods.price;
        total += shopCar.count * shopCar.goods.price;
      })
      this.total = total.toFixed(2);
    },
    //删除选中
    del() {
      const sids = [];
      this.selectShopCar.forEach(item => {
        sids.push(item.sid);
      })
      this.axios.delete(publicJS.methods.getBackPath() + "shopcar/del", {
        params: {
          sids: sids,
          type: 1
        },
        paramsSerializer: params => {
          return this.$qs.stringify(params, {indices: false})

        }
      })
      this.$router.replace({path: '/refresh'});
    },
    //提交下单表单
    submitForm(formName) {

      this.$refs[formName].validate(async (valid) => {
        if (valid) {
          const order = {
            oName: this.orderForm.name,
            phoneNum: this.orderForm.phoneNum,
            recAddr: this.orderForm.recAddr,
            oTime: publicJS.methods.getTime(),
            total: this.total,

          };
          const records = [
            {id: 0, fid: 1, oid: 0, count: 3, total: 2000, del: 0},
            {id: 0, fid: 2, oid: 0, count: 2, total: 3000, del: 0}
          ];
          let formData = new FormData();
          formData.append("oName", order.oName);
          formData.append("phoneNum", order.phoneNum);
          formData.append("recAddr", order.recAddr);
          formData.append("oTime", order.oTime);
          formData.append("total", order.total);
          formData.append("salemanId", this.selectShopCar[0].salemanId);
          formData.append("shopId", this.selectShopCar[0].shopId);

          const res = await this.axios.post(publicJS.methods.getBackPath() + "order/add", formData);
          if (res.data == 0) {
            this.$message.error("下单失败,请联系站长");
            return false;
          }
          const oid = res.data;
          for (const item of this.selectShopCar) {
            formData = new FormData();
            formData.append("fid", item.fid);
            formData.append("oid", oid);
            formData.append("count", item.count);
            formData.append("total", item.total);

            formData.append("shopId", item.shopId);
            formData.append("salemanId", item.salemanId);

            const res = await this.axios.post(publicJS.methods.getBackPath() + "record/add", formData);
            if (!res.data) {
              this.$message.error("下单失败,请联系站长");
              return false;
            }
          }
          //删除选中内容
          this.del();
          this.$message.success("下单成功");
          await this.$router.replace({path: '/refresh'});
        } else {
          this.orderVisible = false;
          return false;
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.orderVisible = false;
      this.$refs[formName].resetFields();
    },
    selected(row, index) {
      return row.goods.del === 0;
    },
    gogoods(goods) {
      this.$router.replace({path: '/detail', query: {fid: goods.fid, name: goods.category.englishName}});
    }
  },
  mounted() {
    this.getUserInfo().then(user => {
      if (user) {
        this.getShopCar();
      }
    })
  }
}
</script>

<style scoped>
.main-container {
  display: flex;
  padding: 20px;
}

/* Main content area with background color, border radius, and shadow */
.main-content {
  flex-grow: 1;
  padding-left: 20px;
  background-color: #f9fafb; /* Light grayish background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Avatar uploader container style */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9; /* Dashed border */
  border-radius: 6px; /* Rounded corners for upload box */
  cursor: pointer; /* Pointer cursor on hover */
  position: relative;
  overflow: hidden;
}

/* Hover effect for avatar uploader */
.avatar-uploader .el-upload:hover {
  border-color: #409EFF; /* Change border color to blue on hover */
}

/* Avatar icon size and positioning */
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d; /* Gray color for icon */
  width: 178px;
  height: 178px;
  line-height: 178px; /* Center text inside the box */
  text-align: center; /* Centering icon */
}

/* Avatar image style */
.avatar {
  width: 178px;
  height: 178px;
  display: block; /* Display as a block-level element */
}

/* Additional form or button styles */
.el-button {
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 6px;
  transition: all 0.3s ease;
}

/* Button hover effect */
.el-button:hover {
  background-color: #409EFF; /* Blue background on hover */
  color: white; /* White text on hover */
}

/* Form styling */
.el-form-item {
  margin-bottom: 20px; /* Spacing between form items */
}

/* Input fields inside form */
.el-input, .el-select, .el-textarea {
  border-radius: 6px;
  padding: 10px;
}

/* Styling for input or select field focus */
.el-input:focus, .el-select:focus, .el-textarea:focus {
  border-color: #409EFF; /* Blue border on focus */
  box-shadow: 0 0 5px rgba(64, 158, 255, 0.5); /* Subtle glow effect */
}

/* Pagination styling */
.el-pagination {
  margin-top: 20px;
  text-align: center;
}

.el-pagination .el-button {
  border-radius: 6px;
}





/* Media queries for responsiveness */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    padding: 10px;
  }

  .main-content {
    padding-left: 0;
    margin-top: 20px;
  }

  .el-button {
    width: 100%; /* Full-width buttons on small screens */
  }

  .el-form-item {
    width: 100%; /* Full-width form items */
  }
}

</style>

