<template>
  <div class="main-container">
    <PersonLeft></PersonLeft>

    <div class="main-content">
      <el-row class="header-row">
        <el-col :span="20">
          <el-page-header @back="goIndex" content="收藏列表" class="header-content"></el-page-header>
        </el-col>
      </el-row>

      <!-- 评论展示 -->
      <el-table :data="tableData" stripe width="100%" class="data-table">
        <el-table-column fixed prop="id" label="序号" width="150" />
        <el-table-column fixed prop="time" label="收藏时间" width="300">
          <template scope="scope">
            {{ new Date(scope.row.time).toLocaleString() }}
          </template>
        </el-table-column>
        <el-table-column fixed prop="time" label="收藏小说名称" width="300">
          <template scope="scope">
            {{ scope.row.name }}
          </template>
        </el-table-column>

        <el-table-column prop="shopId" label="作者主页" width="400">
          <template scope="scope">
            <router-link :to="'showShops?id=' + scope.row.shopId" style="color: #303133">访问主页</router-link>
          </template>
        </el-table-column>

        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button
                @click.native.prevent="deleteRow(scope.$index, tableData, scope.row.id)"
                type="text"
                size="small"
                class="action-btn"
            >
              取消关注
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>

<script>
import PersonLeft from "./PersonLeft";
import publicJS from "../assets/js/public";

export default {
  // eslint-disable-next-line vue/multi-word-component-names
  name: "Attention",
  components: {PersonLeft}
  , methods: { goIndex() {
      this.$router.replace({path: '/'});
    },
    deleteRow(index, rows, id) {
      console.log(id);
      this.axios.get(publicJS.methods.getBackPath() + "attontion/delete/" + id).then(res => {
        rows.splice(index, 1);
      })
    }, //根据种类获取所有商品
    getData() {
      this.axios.get(publicJS.methods.getBackPath() + "attontion/findAll/").then(res => {
        this.tableData = res.data;
      })
    }
  },
  data() {
    return {
      tableData: []
    }
  },
  mounted() {
    this.getData();
  },
}
</script>

<style scoped>
.main-container {
  display: flex;
  padding: 20px;
}

/* Main container and content */
.left-side-container {
  padding: 20px;
  background-color: #f4f7fc; /* Light gray background for the whole container */
  min-height: 100vh;
  box-sizing: border-box;
}

.main-content {
  flex-grow: 1;
  padding-left: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.header-row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.header-content {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 20px;
}

/* Table styling */
.data-table {
  width: 100%;
  margin-top: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.el-table-column label {
  font-weight: 500;
  color: #333;
}

.el-table-row:hover {
  background-color: #f4f7fb;
}

/* Action buttons */
.action-btn {
  border-radius: 8px;
  font-size: 14px;
  padding: 6px 12px;
  transition: all 0.3s ease;
}

.action-btn:hover {
  background-color: #409EFF;
  color: #fff;
}

/* Router Link Styling */
.router-link {
  color: #409EFF;
  text-decoration: none;
  font-size: 14px;
}

.router-link:hover {
  text-decoration: underline;
  color: #36b3e2;
}

/* Responsive Design */
@media screen and (max-width: 768px) {
  .left-side-container {
    padding: 10px;
  }

  .header-content {
    font-size: 20px;
    padding: 10px;
  }

  .el-button {
    font-size: 14px;
    width: 100%;
  }
}

</style>
