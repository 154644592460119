<template>
  <div class="main-container">
    <LeftSide></LeftSide>
    <div class="main-content">
      <el-row class="header-row">
        <el-page-header @back="goIndex" content="角色管理" class="header-content"></el-page-header>

        <!-- Button: Add User -->
        <el-col :span="5">
          <el-button type="primary" @click="openAdd" class="action-btn">添加用户</el-button>
        </el-col>

        <!-- Input: Search -->
        <el-col :span="8">
          <el-input v-model="search" placeholder="输入用户名..." class="search-input"/>
        </el-col>

        <!-- Button: Search -->
        <el-col :span="5">
          <el-button type="primary" @click="getUser" class="search-btn">搜索</el-button>
        </el-col>
      </el-row>

      <el-table :data="users" stripe width="100%" class="user-table">
        <el-table-column type="index"/>
        <el-table-column prop="uname" label="用户名"/>
        <el-table-column label="身份">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.role===0">管理员</el-tag>
            <el-tag v-else-if="scope.row.role===1">普通用户</el-tag>
            <el-tag v-else>作者</el-tag>
          </template>
        </el-table-column>
        <el-table-column label="操作">
          <template slot-scope="scope">
            <div class="action-buttons">
              <el-button
                  size="mini"
                  type="primary"
                  @click="user = users[scope.$index]; userVisible = true;"
                  class="action-btn">查看详情
              </el-button>
              <el-button
                  size="mini"
                  type="primary"
                  @click="openUpdate(scope.row.uid)"
                  class="action-btn">修改
              </el-button>
              <el-button
                  size="mini"
                  type="danger"
                  v-if="scope.row.role!=0"
                  @click="del(scope.row.uid)"
                  class="action-btn">删除

              </el-button>
            </div>
          </template>
        </el-table-column>
      </el-table>

      <!-- User Detail Dialog -->
      <el-dialog title="详细信息" :visible.sync="userVisible" class="dialog-box">
        <el-descriptions :column="3" border class="dialog-content">
          <el-descriptions-item label="用户名">{{ user.uname }}</el-descriptions-item>
          <el-descriptions-item label="性别">{{ user.gender === 1 ? '男' : '女' }}</el-descriptions-item>
          <el-descriptions-item label="居住地">{{ user.city }}</el-descriptions-item>
          <el-descriptions-item label="出生日期">{{ user.birthday }}</el-descriptions-item>
          <el-descriptions-item label="手机号码">{{ user.phoneNum }}</el-descriptions-item>
          <el-descriptions-item label="联系地址">{{ user.address }}</el-descriptions-item>
        </el-descriptions>
      </el-dialog>

      <!-- Add/Update User Dialog -->
      <el-dialog :title="title" :visible.sync="visible" :destroy-on-close="true" :before-close="handleClose" class="dialog-box">
        <el-form :model="userForm" :rules="rules" ref="ruleForm" label-width="100px" class="form-box">
          <el-form-item label="用户名" prop="uname">
            <el-input type="text" v-model="userForm.uname" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="密码" prop="passwd">
            <el-input v-show="mode === 0" type="password" v-model="userForm.passwd" autocomplete="off"></el-input>
            <el-input v-show="mode === 1" v-model="userForm.passwd" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="重复密码" prop="passwd2">
            <el-input v-show="mode === 0" type="password" v-model="userForm.passwd2" autocomplete="off"></el-input>
            <el-input v-show="mode === 1" v-model="userForm.passwd2" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="性别">
            <el-radio v-model="userForm.gender" label="1">男</el-radio>
            <el-radio v-model="userForm.gender" label="0">女</el-radio>
          </el-form-item>
          <el-form-item label="出生日期" prop="birthday">
            <el-col :span="11">
              <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择日期" v-model="userForm.birthday" style="width: 100%;"></el-date-picker>
            </el-col>
          </el-form-item>
          <el-form-item label="手机号码" prop="phoneNum">
            <el-input type="text" v-model="userForm.phoneNum" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="居住地">
            <el-select v-model="userForm.city" placeholder="请选择">
              <el-option v-for="item in cities" :key="item.value" :label="item.label" :value="item.label"></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="联系地址" prop="address">
            <el-input type="textarea" v-model="userForm.address"></el-input>
          </el-form-item>
          <el-form-item label="验证码" prop="code">
            <el-image style="width: 30%" :src="codeSrc" @click="changeCode"></el-image>
            <el-input style="width: 65%" v-model="userForm.code" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button v-show="mode === 0" type="primary" @click="onSubmit('ruleForm')">注册</el-button>
            <el-button v-show="mode === 1" type="primary" @click="onSubmit('ruleForm')">修改</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import LeftSide from "./LeftSide";
import publicJS from "../assets/js/public";

export default {
  name: "ManageUser",
  components: {LeftSide},
  data() {
    var checkUserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('用户名不能为空'));
      } else {
        // 调用后端api
        this.axios.get(publicJS.methods.getBackPath() + "user/checkUname", {
          params: {
            uname: value
          }
        }).then(Response => {
          if (Response.data) {
            callback();
          }
          callback(new Error('用户名已存在！'));
        })
      }
    };
    var checkPasswd2 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请再次输入密码"));
      } else if (this.userForm.passwd.indexOf(value) == -1) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    };
    return {
      shopInfo: [],
      users: [],
      user: {},
      index: 0,
      search: '',//搜索内容
      userVisible: false,
      title: '',//dialog标题
      oldName: '',//旧用户名
      visible: false,
      updateVisible: false,
      mode: 0,//添加模式还是修改模式 0为添加
      codeSrc: '',
      cities: [
        { value: 'Beijing', label: '北京市' },
        { value: 'Changchun', label: '长春市' },
        { value: 'Chengdu', label: '成都市' },
        { value: 'Changsha', label: '长沙市' },
        { value: 'Chongqing', label: '重庆市' },
        { value: 'Fujian', label: '福建市' },
        { value: 'Guiyang', label: '贵阳市' },
        { value: 'Guangzhou', label: '广州市' },
        { value: 'Harbin', label: '哈尔滨市' },
        { value: 'HeFei', label: '合肥市' },
        { value: 'Huhhot', label: '呼和浩特市' },
        { value: 'HaiKou', label: '海口市' },
        { value: 'Hangzhou', label: '杭州市' },
        { value: 'Jinan', label: '济南市' },
        { value: 'Kunming', label: '昆明市' },
        { value: 'Lanzhou', label: '兰州市' },
        { value: 'Nanchang', label: '南昌市' },
        { value: 'Nanjing', label: '南京市' },
        { value: 'Shanghai', label: '上海市' },
        { value: 'Shijiazhuang', label: '石家庄市' },
        { value: 'Shenyang', label: '沈阳市' },
        { value: 'Tianjin', label: '天津市' },
        { value: 'Taiyuan', label: '太原市' },
        { value: 'Wuhan', label: '武汉市' },
        { value: 'XiAn', label: '西安市' },
        { value: 'Xining', label: '西宁市' },
        { value: 'Zhengzhou', label: '郑州市' },
        { value: 'Urumqi', label: '乌鲁木齐市' },
        { value: 'LaSa', label: '拉萨市' },
        { value: 'Nanning', label: '南宁市' },
        { value: 'HangKong', label: '香港' },
        { value: 'Macao', label: '澳门' },
        { value: 'Taipei', label: '台北市' },
        { value: 'Zhuhai', label: '珠海市' },
        { value: 'Hainan', label: '海南省' },
        { value: 'Guizhou', label: '贵州省' },
        { value: 'Shandong', label: '山东省' },
        { value: 'Henan', label: '河南省' },
        { value: 'Sichuan', label: '四川省' },
        { value: 'Liaoning', label: '辽宁省' },
        { value: 'Anhui', label: '安徽省' },
        { value: 'Jiangsu', label: '江苏省' },
        { value: 'Zhejiang', label: '浙江省' },
        { value: 'Guangxi', label: '广西省' },
        { value: 'Xinjiang', label: '新疆省' },
        { value: 'Gansu', label: '甘肃省' },
        { value: 'Qinghai', label: '青海省' },
        { value: 'Tibet', label: '西藏' },
        { value: 'Ningxia', label: '宁夏省' },
        { value: 'Jilin', label: '吉林省' },
        { value: 'Hunan', label: '湖南省' },
        { value: 'Shanxi', label: '山西省' },
        { value: 'InnerMongolia', label: '内蒙古自治区' },
        { value: 'Macau', label: '澳门特别行政区' },
        { value: 'HongKong', label: '香港特别行政区' }
      ],
      userForm: {
        uid: 0,
        uname: '',
        passwd: '',
        passwd2: '',
        gender: '1',
        birthday: '',
        phoneNum: '',
        city: '北京市',
        address: '',
        code: ''
      },
      rules: {
        uname: [
          {required: true, message: '用户名不能为空', trigger: 'blur'},
          {pattern: /^[0-9a-zA-Z_]{1,}$/, message: '必须是数字字母组合', trigger: 'blur'},
          {min: 6, max: 10, message: '长度在6-10位', trigger: 'blur'},
          // {validator:checkUserName,trigger:'blur'}
        ],
        passwd: [
          {required: true, message: '请输入密码', trigger: 'blur'},
          {pattern: /^[0-9a-zA-Z_]{1,}$/, message: '必须是数字字母组合', trigger: 'blur'},
          {min: 8, max: 16, message: '长度在8-16位', trigger: 'blur'}
        ],
        passwd2: [{required: true, validator: checkPasswd2, trigger: 'blur'}],
        birthday: [{required: true, message: '请选择您的生日', trigger: 'blur'}],
        phoneNum: [{
          required: true,
          pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
          message: '请输入正确的手机号码',
          trigger: 'blur'
        }],
        address: [{required: true, message: '请填入您的联系地址', trigger: 'blur'}],
        code: [{required: true, message: '请输入验证码', trigger: 'blur'}]
      }
    }
  },
  methods: {
    setDialog() {
      const dialogs = document.getElementsByClassName("el-dialog");
      dialogs[0].style.height = "250px";
    },
    getUser() {
      this.axios.get(publicJS.methods.getBackPath() + "user/findBySearch", {
        params: {
          uname: this.search
        }
      }).then(res => {
        this.users = res.data;
      })
    },
    goIndex() {
      this.$router.replace({path: '/'});
    },
    //用户 添加/更改 提交事件
    async onSubmit(formName) {
      if (this.mode === 0 || (this.mode === 1 && this.oldName !== this.userForm.uname)) {
        let res = await this.axios.get(publicJS.methods.getBackPath() + "user/checkUname", {
          params: {
            uname: this.userForm.uname
          }
        });
        if (!res.data) {
          this.$message.error("用户名已存在");
          return;
        }
      }
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          if (this.mode === 1) {
            formData.append("uid", this.userForm.uid);
          }
          formData.append("uname", this.userForm.uname);
          formData.append("passwd", this.userForm.passwd);
          formData.append("gender", this.userForm.gender);
          formData.append("birthday", this.userForm.birthday);
          formData.append("phoneNum", this.userForm.phoneNum);
          formData.append("city", this.userForm.city);
          formData.append("address", this.userForm.address);
          formData.append("code", this.userForm.code);
          let url = publicJS.methods.getBackPath() + "user/register";
          let method = 'post';
          if (this.mode === 1) {
            url = publicJS.methods.getBackPath() + "user/update";
            method = 'put';
          }
          this.axios({
            method: method,
            url: url,
            data: formData
          }).then(res => {
            if (res.data.status) {
              if (this.mode === 0)
                this.$message.success("注册成功");
              else
                this.$message.success("修改成功");
              this.search = '';
              this.getUser();
              this.visible = false;
            } else {
              this.$message.error(res.data.msg);
              this.changeCode();
            }
          })
        }
      });
    },
    changeCode() {
      this.codeSrc = publicJS.methods.getBackPath() + "checkCode?timestamp=" + new Date();
    },
    //添加用户按钮点击事件
    openAdd() {
      this.mode = 0;
      this.title = '添加用户';
      this.changeCode();
      this.visible = true;
    },
    //修改用户按钮点击事件
    async openUpdate(uid) {
      this.mode = 1;
      this.title = '修改用户';
      let res = await this.axios.get(publicJS.methods.getBackPath() + "user/getByUid/" + uid);
      res.data.gender += '';
      this.userForm = res.data;
      this.userForm.passwd2 = this.userForm.passwd;
      this.oldName = this.userForm.uname;
      this.changeCode();
      this.visible = true;
    },
    handleClose(done) {
      this.userForm = {};
      this.oldName = '';
      done();
    },
    //删除按钮点击事件
    del(uid) {
      this.$confirm('此操作将删除该用户, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete(publicJS.methods.getBackPath() + "user/del/" + uid).then(res => {
          if (res.data) {
            this.search = '';
            this.getUser();
            this.$message({
              type: 'success',
              message: '删除成功!'
            });
          } else {
            this.$message({
              type: 'error',
              message: '删除失败!'
            });
          }
        })
      }).catch(() => {

      });
    }
  },
  mounted() {
    this.getUser();
    this.setDialog();
    this.changeCode();
  }
}
</script>

<style scoped>
/* Main container and content */
.main-container {
  display: flex;
  padding: 20px;
}

.main-content {
  flex-grow: 1;
  padding-left: 30px;
  background-color: #f9fafb;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Header row */
.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.header-content {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

/* Action buttons */
.action-buttons {
  display: flex;
  gap: 10px;
}

.action-btn {
  border-radius: 8px;
  padding: 6px 12px;
  transition: all 0.3s ease;
}

.action-btn[type="primary"] {
  background-color: #409EFF;
  color: white;
}

.action-btn[type="primary"]:hover {
  background-color: #36b3e2;
}

.action-btn[type="danger"] {
  background-color: #f56c6c;
  color: white;
}

.action-btn[type="danger"]:hover {
  background-color: #e04343;
}

/* Table Styling */
.user-table {
  margin-top: 20px;
  width: 100%;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.el-table-column label {
  font-weight: 500;
  color: #333;
}

.el-table-row:hover {
  background-color: #f4f7fb;
}


.dialog-content {
  padding: 20px;
}

.form-box {
  padding: 20px;
}

.el-button {
  border-radius: 8px;
  font-size: 14px;
  padding: 10px 20px;
  transition: all 0.3s ease;
}

.el-button[type="primary"] {
  background-color: #409EFF;
  color: white;
}

.el-button[type="primary"]:hover {
  background-color: #36b3e2;
}

/* Input Styling */
.el-input, .el-select, .el-date-picker {
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.el-input:focus, .el-select:focus, .el-date-picker:focus {
  border-color: #409EFF;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.2);
}

/* Responsive Design */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    padding: 15px;
  }

  .main-content {
    padding-left: 0;
  }

  .el-row {
    flex-direction: column;
    align-items: stretch;
  }

  .el-col {
    margin-bottom: 15px;
  }

  .action-btn {
    font-size: 14px;
    padding: 8px 14px;
  }
}

</style>
