<template>
  <el-container>
    <el-header style="height: auto;">
      <Header></Header>
    </el-header>

    <el-container>
      <el-main>
        <!-- 商品展示区域 -->
        <el-row class="product-show-row">
          <el-col :span="12">
            <div class="product-image-container">



            <el-image
                :src="shopData.shops.imgSrc"
                class="product-image"
            ></el-image>
            </div>
          </el-col>
          <el-col :span="5">
            <el-card class="product-card">
              <div class="text item">
                描述: {{ shopData.shops.desption }}
              </div>
              <div class="text item">
                {{ user2.city }} {{ user2.address }}
              </div>
              <div class="text item">
                <i class="el-icon-user-solid"></i>{{ user2.uname }}
              </div>
            </el-card>
          </el-col>
        </el-row>

        <!-- 发布的作品标签 -->
        <el-tabs type="border-card" class="product-tabs">
          <el-tab-pane label="发布的作品">
            <el-row v-for="c in category" :key="c.cid" class="category-row">
              <el-row class="category-name">
                <el-tag>{{ c.cname }}</el-tag>
              </el-row>
              <el-row
                  v-for="(item, index) in shopData.shopList"
                  :key="index"
                  v-if="item.cid == c.cid"
                  class="product-item"
              >
                <router-link :to="goDetail(item.fid, 'shopData.category.englishName')">
                  <el-image
                      :src="item.image.cover"
                      class="product-item-image"
                  ></el-image>
                </router-link>
                <div class="product-price">￥{{ item.price }}</div>
                <div class="product-name">
                  <router-link
                      v-text="item.fname"
                      :to="goDetail(item.fid, 'shopData.category.englishName')"
                      class="product-link"
                  ></router-link>
                </div>
              </el-row>
            </el-row>
          </el-tab-pane>
        </el-tabs>

        <!-- 推荐商品区域 -->
        <el-row class="recommended-products">
          <el-row v-if="recommendData.length > 0" class="recommend-header">
            推荐商品
          </el-row>
          <el-row
              v-for="(item, index) in recommendData"
              :key="index"
              class="recommend-item"
          >
            <router-link :to="goDetail(item.fid, 'shopData.category.englishName')">
              <el-image :src="item.image.cover" class="recommend-image"></el-image>
            </router-link>
            <div class="recommend-price">￥{{ item.price }}</div>
            <div class="recommend-name">
              <router-link
                  v-text="item.fname"
                  :to="goDetail(item.fid, 'shopData.category.englishName')"
                  class="recommend-link"
              ></router-link>
            </div>
            <div class="recommend-footer">
              <i class="el-icon-s-unfold"></i>
              <router-link
                  style="color: black; text-decoration: underline"
                  :to="goShop(item.sid)"
                  class="shop-name"
              >{{ item.shop.name | ellipsis }}</router-link
              >
              <span class="recommend-address">{{ item.address }}</span>
            </div>
          </el-row>
        </el-row>
        <Footer></Footer>
      </el-main>

    </el-container>
  </el-container>
</template>

<script>
import Header from "./Header";
import publicJS from "../assets/js/public";
import Footer from "./Footer";

export default {
  name: "ProductShow",
  components: { Footer, Header },
  filters: {
    ellipsis(value) {
      if (!value) return "";
      if (value.length > 10) {
        return value.slice(0, 12) + "...";
      }
      return value;
    },
  },
  data() {
    return {
      shopData: [],
      user: [],
      dialogVisible: false,
      form: {
        name: "",
        desption: "",
      },
      noticeData: {},
      category: [],
      user2: [],
      recommendData: [],
    };
  },
  methods: {
    goShop(id) {
      return "/showShops?id=" + id;
    },
    onSubmit() {
      this.axios
          .post(publicJS.methods.getBackPath() + "complaint/add", {
            name: this.form.name,
            desption: this.form.desption,
            userId: this.user.uid,
            type: 0,
            userName: this.user.uname,
            shopId: this.shopData.shops.id,
            shopName: this.shopData.shops.name,
            complaintType: 0,
            shoppingName: "",
            shoppingId: 0,
          })
          .then((res) => {
            this.$message.success("投诉成功");
            this.dialogVisible = false;
          });
    },
    getShopInfo(id) {
      this.axios
          .get(publicJS.methods.getBackPath() + "shop/getShopInfoById/" + id)
          .then((res) => {
            this.shopData = res.data;
            this.getUser2();
          });
    },
    getUser() {
      this.axios
          .get(publicJS.methods.getBackPath() + "user/getUserInfo")
          .then((res) => {
            this.user = res.data;
          });
    },
    getUser2() {
      this.axios
          .get(publicJS.methods.getBackPath() + "user/getByUid/" + this.shopData.shops.uid)
          .then((res) => {
            this.user2 = res.data;
          });
    },
    goDetail(fid, fname) {
      return "/detail?fid=" + fid;
    },
    getCategory() {
      this.axios
          .get(publicJS.methods.getBackPath() + "category/findAll", {})
          .then((res) => {
            this.category = res.data;
          });
    },
    getRecommend() {
      this.axios
          .get(publicJS.methods.getBackPath() + "f/currentRecommend", {})
          .then((res) => {
            this.recommendData = res.data;
          });
    },
  },
  mounted() {
    this.getShopInfo(this.$route.query.id);
    this.getUser();
    this.getCategory();
    this.getRecommend();
  },
};
</script>

<style scoped>
/* 页面全局样式 */
body {
  font-family: 'Arial', sans-serif;
  background-color: #f9f9f9;
  margin: 0;
  padding: 0;
  color: #333;
}

/* 头部样式 */
.el-header {

  color: white;
  padding: 10px 0;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

/* 底部样式 */
.el-footer {
  background-color: #f1f1f1;
  padding: 20px 0;
  text-align: center;
  color: #777;
  font-size: 14px;
  border-top: 1px solid #ddd;
}




/* 商品展示区 */
.product-show-row {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
}


.product-image-container {
  display: flex;
  justify-content: center;  /* 水平居中 */
  align-items: center;      /* 垂直居中 */
  height: 100%;             /* 使容器的高度充满父容器 */
}

.product-image {
  height: auto;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}


.product-card {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.text.item {
  font-size: 14px;
  margin-bottom: 18px;
  line-height: 1.6;
}

.item i {
  color: #409eff;
  margin-right: 8px;
}

/* 标签页样式 */
.product-tabs {
  margin-top: 40px;
}

.el-tabs__header {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.el-tab-pane {
  padding: 20px;
}

.el-tag {


  font-size: 12px;
  border-radius: 4px;
  margin-top: 10px;
}

/* 商品卡片样式 */
.product-item {
  width: 250px;
  border: 1px solid #409eff;
  display: inline-block;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);


}

.product-item-image {
  width: 100%;
  height: 218px;
  object-fit: cover; /* 保证图片不会拉伸，保持比例 */
  border-radius: 8px;
  margin-bottom: 10px;
  transition: transform 0.3s ease;
}

.product-item-image:hover {
  transform: scale(1.05);
}

.product-price {
  font-size: 20px;
  color: red;
}

.product-name {
  font-size: 12px;
  margin-top: 10px;
}

.product-link {
  color: #303133;
  text-decoration: none;
}

.product-link:hover {
  text-decoration: underline;
  color: #409eff;
}

.recommend-item {
  width: 250px;
  border: 1px solid #409eff;
  display: inline-block;
  margin-top: 20px;
  margin-right: 20px;
  border-radius: 8px;
  padding: 16px;
  transition: box-shadow 0.3s ease;
}

.recommend-item:hover {
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.recommend-item img {
  max-width: 100%;
  border-radius: 8px;
}

.recommend-item .recommend-price {
  color: red;
  font-size: 18px;
  font-weight: bold;
  margin-top: 10px;
}

.recommend-item .recommend-name {
  font-size: 14px;
  color: #303133;
  margin-top: 10px;
}

.recommend-item .recommend-footer {
  font-size: 12px;
  color: #82848a;
  margin-top: 10px;

}

.recommend-item .shop-name {
  text-decoration: underline;
  color: black;
}

.recommend-item .recommend-address {
  color: #82848a;
  font-size: 10px;
}

.recommend-header {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 15px;
  color: #409eff;
}

.recommend-item .el-icon-s-unfold {
  color: #82848a;
  margin-right: 8px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .product-item, .recommend-item {
    width: 100%;
    margin: 10px 0;
  }

  .product-item-image, .recommend-image {
    width: 100%;
  }

  .el-header, .el-footer {
    padding: 15px 0;
  }

  .product-card {
    padding: 15px;
  }
}

@media (max-width: 480px) {
  .product-item, .recommend-item {
    width: 100%;
  }

  .el-header, .el-footer {
    padding: 10px 0;
  }

  .product-card {
    padding: 10px;
  }
}

</style>