<template>
  <div class="main-container">
    <!-- 左侧菜单栏 -->
    <LeftSide></LeftSide>

    <!-- 右侧内容区域 -->
    <div class="main-content">
      <el-row class="header-row">
        <el-col :span="20">
          <el-page-header @back="goIndex" content="分类管理" class="header-content"></el-page-header>
        </el-col>
        <el-col :span="4" class="text-right">
          <el-button type="primary" @click="openAdd" class="action-btn">添加分类</el-button>
        </el-col>
      </el-row>

      <!-- 分类数据表格 -->
      <el-table :data="users" stripe class="data-table">
        <el-table-column type="index" label="序号" width="80"/>
        <el-table-column prop="cname" label="名称"/>
        <el-table-column prop="englishName" label="英文名称"/>
        <el-table-column label="操作" width="200">
          <template slot-scope="scope">
            <el-button size="mini" type="primary" @click="openUpdate(scope.row.cid)" class="action-btn">修改</el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- 添加/编辑分类对话框 -->
      <el-dialog :title="title" :visible.sync="visible" :destroy-on-close="true" :before-close="handleClose" class="dialog-box">
        <el-form :model="userForm" :rules="rules" ref="ruleForm" label-width="100px" class="form-box">
          <el-form-item label="分类名称" prop="cname">
            <el-input v-model="userForm.cname" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="英文名称" prop="englishName">
            <el-input v-model="userForm.englishName" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit('ruleForm')" class="submit-btn">
              {{ mode === 0 ? '添加' : '修改' }}
            </el-button>
          </el-form-item>
        </el-form>
      </el-dialog>
    </div>
  </div>
</template>


<script>
import LeftSide from "./LeftSide";
import publicJS from "../assets/js/public";

export default {
  name: "ClassifyManager",
  components: { LeftSide },
  data() {
    return {
      users: [],
      userForm: {
        cid: 0,
        cname: '',
        englishName: ''
      },
      rules: {
        cname: [{ required: true, message: '分类名称不能为空', trigger: 'blur' }],
        englishName: [{ required: true, message: '英文名称不能为空', trigger: 'blur' }]
      },
      title: '',
      visible: false,
      mode: 0, // 0 为添加模式，1 为编辑模式
      oldName: ''
    };
  },
  methods: {
    getUser() {
      this.axios.get(publicJS.methods.getBackPath() + "category/findAll")
          .then(res => {
            this.users = res.data;
          });
    },
    goIndex() {
      this.$router.replace({ path: '/' });
    },
    // 提交添加或修改分类
    async onSubmit(formName) {
      if (this.mode === 0 || (this.mode === 1 && this.oldName !== this.userForm.cname)) {
        let res = await this.axios.get(publicJS.methods.getBackPath() + "category/checkCname", {
          params: { cname: this.userForm.cname }
        });
        if (!res.data) {
          this.$message.error("分类名已存在");
          return;
        }
      }

      this.$refs[formName].validate((valid) => {
        if (valid) {
          const url = this.mode === 1 ? "category/update" : "category/add";
          const method = this.mode === 1 ? 'put' : 'post';
          const formData = new FormData();
          formData.append("cname", this.userForm.cname);
          formData.append("englishName", this.userForm.englishName);

          if (this.mode === 1) {
            formData.append("cid", this.userForm.cid);
          }

          this.axios({ method, url: publicJS.methods.getBackPath() + url, data: formData })
              .then(res => {
                if (res.data.status) {
                  this.$message.success(this.mode === 0 ? "添加成功" : "修改成功");
                  this.getUser();
                  this.visible = false;
                } else {
                  this.$message.error(res.data.msg);
                }
              });
        }
      });
    },
    openAdd() {
      this.mode = 0;
      this.title = '添加分类';
      this.visible = true;
    },
    async openUpdate(cid) {
      this.mode = 1;
      this.title = '修改分类';
      const res = await this.axios.get(publicJS.methods.getBackPath() + "category/getByUid/" + cid);
      this.userForm = res.data;
      this.oldName = res.data.cname;
      this.visible = true;
    },
    handleClose(done) {
      this.userForm = {};
      this.oldName = '';
      done();
    }
  },
  mounted() {
    this.getUser();
  }
};
</script>

<style scoped>
/* Main container and content */
.main-container {
  display: flex;
  padding: 20px;
}

.main-content {
  flex-grow: 1;
  padding-left: 20px;
  background-color: #f9fafb;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Header row */
.header-row {
  display: flex;
  justify-content: flex-start;
  margin-bottom: 20px;
}

.header-content {
  font-size: 24px;
  font-weight: 600;
  color: #333;
}

/* Action button */
.action-btn {
  border-radius: 8px;
  padding: 8px 16px;
  font-size: 14px;
  transition: all 0.3s ease;
}

.action-btn[type="primary"] {
  background-color: #409EFF;
  color: white;
}

.action-btn[type="primary"]:hover {
  background-color: #36b3e2;
}

.el-button--mini {
  font-size: 14px;
  padding: 6px 12px;
  border-radius: 6px;
}

/* Table styling */
.data-table {
  width: 100%;
  margin-top: 20px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.el-table-column label {
  font-weight: 500;
  color: #333;
}

.el-table-row:hover {
  background-color: #f4f7fb;
}



.form-box {
  padding: 20px;
}

.el-form-item {
  margin-bottom: 20px;
}

.submit-btn {
  width: 100%;
  padding: 10px 20px;
  font-size: 16px;
  font-weight: bold;
  background-color: #409EFF;
  color: white;
  border-radius: 8px;
  transition: background-color 0.3s ease;
}

.submit-btn:hover {
  background-color: #36b3e2;
}

/* Responsive design */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    padding: 10px;
  }

  .main-content {
    padding-left: 0;
  }

  .el-button, .submit-btn {
    width: 100%;
    font-size: 14px;
  }

  .el-row {
    flex-direction: column;
  }

  .el-col {
    margin-bottom: 10px;
  }
}

</style>
