<template>
  <div class="main-container">
    <PersonLeft></PersonLeft>

    <!-- Main Content Section -->
    <div class="main-content">
      <!-- Page Header -->
      <el-row style="width: 100%;float: left;border-bottom: 1px solid #eee">
        <el-page-header @back="goIndex" content="收货管理"></el-page-header>
      </el-row>

      <!-- Order Management Section -->
      <div style="width: 100%;float: left;margin-top: 20px">

        <!-- Data Table to display order details -->
        <el-table :data="orderPage.records" border width="100%">
          <el-table-column type="index"/>
          <el-table-column prop="onum" label="订单编号" sortable/>
          <el-table-column prop="oname" label="姓名" width="70px"/>
          <el-table-column prop="user.uname" label="用户名"/>
          <el-table-column prop="phoneNum" label="手机号码"/>
          <el-table-column prop="recAddr" label="收货地址"/>
          <el-table-column prop="total" label="订单总金额" sortable/>
          <el-table-column prop="otime" label="下单时间" sortable/>

          <!-- Delivery Status -->
          <el-table-column label="发货状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.deliverStatus==-1" effect="dark" type="warning">等待您支付</el-tag>
              <el-tag v-if="scope.row.deliverStatus==0" effect="dark" type="warning">未发货</el-tag>
              <el-tag v-if="scope.row.deliverStatus==1" effect="dark" type="success">已发货</el-tag>
              <el-tag v-if="scope.row.deliverStatus==3" effect="dark" type="success">已退货</el-tag>
            </template>
          </el-table-column>

          <!-- Receipt Status -->
          <el-table-column label="确认收货状态">
            <template slot-scope="scope">
              <el-tag v-if="scope.row.deliverStatus==-1" type="warning">等待对方付款</el-tag>
              <el-tag v-if="scope.row.recStatus==0" type="warning">未收货</el-tag>
              <el-tag v-if="scope.row.recStatus==1" type="success">已确认收货</el-tag>
              <el-tag v-if="scope.row.recStatus==3" type="success">已申请退款</el-tag>
              <el-tag v-if="scope.row.recStatus==4" type="success">等待投诉结果</el-tag>
              <el-tag v-if="scope.row.recStatus==6" type="success">管理员驳回投诉</el-tag>
              <el-tag v-if="scope.row.recStatus==7" type="success">被管理员强制退货，货物已退回</el-tag>
              <el-tag v-if="scope.row.recStatus==8" type="success">作者已同意退货申请</el-tag>
              <el-tag v-if="scope.row.recStatus==9" type="success">作者已拒绝退货申请</el-tag>
            </template>
          </el-table-column>

          <!-- Actions Column -->
          <el-table-column label="操作">
            <br>
            <template slot-scope="scope">
              <el-button type="primary" size="mini" @click="openDetail(scope.$index)">订单详情</el-button>

              <el-button type="primary" size="mini" @click="deliver(scope.row.oid)"
                         :disabled="scope.row.deliverStatus==1" v-if="scope.row.deliverStatus!=3 && scope.row.deliverStatus!=4">
                发货
              </el-button>

              <el-button type="primary" size="mini" @click="cancel(scope.row.oid)"
                         :disabled="scope.row.deliverStatus==4 || scope.row.deliverStatus==1" v-if="scope.row.deliverStatus!=4">
                取消订单
              </el-button>

              <el-button type="primary" size="mini" @click="agree(scope.row.oid)"
                         v-if="scope.row.deliverStatus==1 && scope.row.recStatus==3">
                同意退货
              </el-button>

              <el-button type="primary" size="mini" @click="refuse(scope.row.oid)"
                         v-if="scope.row.deliverStatus==1 && scope.row.recStatus==3">
                拒绝退货
              </el-button>

              <el-button type="primary" size="mini" @click="qiangzhi(scope.row.oid)"
                         v-if="scope.row.deliverStatus==1 && scope.row.recStatus==3 && scope.row.user.role==0">
                强制退货
              </el-button>

              <el-button type="primary" size="mini" @click="bohui(scope.row.oid)"
                         v-if="scope.row.deliverStatus==1 && scope.row.recStatus==4 && scope.row.user.role==0">
                驳回投诉
              </el-button>
            </template>
          </el-table-column>
        </el-table>

        <!-- Pagination Section -->
        <div style="float: left">
          <div v-if="orderPage.pages <= 7" class="block">
            <el-pagination layout="prev, pager, next" background :total="orderPage.total" :page-size="orderPage.size"
                           :pager-count="7" @current-change="changePage">
            </el-pagination>
          </div>

          <div v-if="orderPage.pages > 7" class="block">
            <el-pagination layout="prev, pager, next" background :total="orderPage.total" :page-size="orderPage.size"
                           :pager-count="9" @current-change="changePage">
            </el-pagination>
          </div>
        </div>
      </div>

      <!-- Order Details Dialog -->
      <el-dialog title="订单详情" :visible.sync="detailVisible">
        <el-table :data="goods">
          <el-table-column property="fname" label="商品名称" width="150"></el-table-column>
          <el-table-column property="price" label="单价" width="120"></el-table-column>
          <el-table-column prop="category.cname" label="种类"/>
          <el-table-column label="封面图片">
            <template slot-scope="scope">
              <el-image :src="scope.row.image.cover"/>
            </template>
          </el-table-column>
          <el-table-column prop="count" label="购买数量"/>
          <el-table-column prop="total" label="单项总价"/>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import publicJS from "../assets/js/public";
import LeftSide from "./LeftSide";
import PersonLeft from "@/components/PersonLeft.vue";

export default {
  name: "ManageOrder",
  components: {PersonLeft, LeftSide},
  data() {
    return {
      form: {
        name: '',
        desption: '',

      }, orderId: 0,
      orderPage: {records: []},//订单分页对象
      dialogVisible: false,
      currentPage: 1,//当前页
      detailVisible: false,//详情dialog显示的标记
      dialogVisible2: false,
      goods: [],//详情页商品，
      shopInfo: [], nowItem: [],
      selectOrder: [], user: [],

    }
  },
  methods: {
    onSubmit2() {
      console.log(this.nowItem)
      this.axios.post(publicJS.methods.getBackPath() + "complaint/add", {
        "name": this.form.name,
        "desption": this.form.desption,
        "userId": this.nowItem.user.uid,
        "type": 0,
        "userName": this.nowItem.user.uname,
        "shopId": this.shopData.id,
        "shopName": this.shopData.name,
        "complaintType": 2,
        "shoppingName": this.nowItem.goods[0].fname,
        "shoppingId": this.nowItem.goods[0].fid,
      }).then(res => {
        this.$message.success("投诉成功");
        this.dialogVisible2 = false;
      })

    },
    //根据种类获取所有商品
    getShopInfo() {
      if (this.user.role == 2) {
        this.axios.get(publicJS.methods.getBackPath() + "shop/getCurrent").then(res => {
          this.shopData = res.data;
          this.getOrder(1);
        })
      } else if (this.user.role == 0) {
        this.getOrder(1);
      }
    },
    getUserInfo() {
      this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo").then(res => {
        this.user = res.data;
        this.updateUser = res.data;
        this.getShopInfo();

      })
    },
    getOrder(currentPage) {
      if (this.user.role == 0) {
        this.axios.get(publicJS.methods.getBackPath() + "order/findAll/" + currentPage + "/" + this.user.role + "/0").then(res => {
          this.orderPage = res.data;
        })
      } else {
        this.axios.get(publicJS.methods.getBackPath() + "order/findAll/" + currentPage + "/" +
            this.user.role + "/" + this.shopData.id).then(res => {
          this.orderPage = res.data;
        })
      }

    }, deliver(oid) {
      this.axios.put(publicJS.methods.getBackPath() + "order/deliver/" + oid).then(res => {
        if (res.data) {
          this.$message.success("发货成功");
          this.$router.replace({path: '/refresh'});
        } else {
          this.$message.error("操作失败,请联系站长");
        }
      })
    },
    //切换页
    changePage: function (page) {
      this.getgoods(page);
    },
    openDetail(index) {
      this.goods = this.orderPage.records[index].goods;
      this.detailVisible = true;
    },

    del() {
      const oids = [];
      this.selectOrder.forEach(item => {
        oids.push(item.oid);
      })
      this.axios.delete(publicJS.methods.getBackPath() + "order/del", {
        params: {
          oids: oids,
          type: 1
        },
        paramsSerializer: params => {
          return this.$qs.stringify(params, {indices: false})
        }
      })
    },
    refuse(oid) {
      this.axios.put(publicJS.methods.getBackPath() + "order/refuseT/" + oid).then(res => {
        if (res.data) {
          this.$message.success("拒绝退款成功");
          this.$router.replace({path: '/refresh'});
        } else {
          this.$message.error("操作失败,请联系站长");
        }
      })
    },

    tousu(oid) {
      this.axios.put(publicJS.methods.getBackPath() + "order/tousu/" + oid).then(res => {
        if (res.data) {
          this.$message.success("投诉申请发送成功！");
          this.$router.replace({path: '/refresh'});
        } else {
          this.$message.error("操作失败,请联系站长");
        }
      })
    }
    ,

    goIndex() {
      this.$router.replace({path: '/'});
    }, cancel(oid) {
      this.axios.put(publicJS.methods.getBackPath() + "order/cancel/" + oid).then(res => {
        if (res.data) {
          this.$message.success("取消订单成功");
          this.$router.replace({path: '/refresh'});
        } else {
          this.$message.error("操作失败,请联系站长");
        }
      })
    }, agree(oid) {
      this.axios.put(publicJS.methods.getBackPath() + "order/agree/" + oid).then(res => {
        if (res.data) {
          this.$message.success("同意退款成功");
          this.$router.replace({path: '/refresh'});
        } else {
          this.$message.error("操作失败,请联系站长");
        }
      })
    }, qiangzhi(oid) {
      this.axios.put(publicJS.methods.getBackPath() + "order/qiangzhi/" + oid).then(res => {
        if (res.data) {
          this.$message.success("强制退货成功！");
          this.$router.replace({path: '/refresh'});
        } else {
          this.$message.error("操作失败,请联系站长");
        }
      })
    },
    bohui(oid) {
      this.axios.put(publicJS.methods.getBackPath() + "order/bohui/" + oid).then(res => {
        if (res.data) {
          this.$message.success("驳回投诉成功！");
          this.$router.replace({path: '/refresh'});
        } else {
          this.$message.error("操作失败,请联系站长");
        }
      })
    },
  }
  ,
  mounted() {
    this.getUserInfo();
  }
}
</script>

<style scoped>
.main-container {
  display: flex;
  padding: 20px;
}

.main-content {
  flex-grow: 1;
  padding-left: 20px;
  background-color: #f9fafb;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.el-table {
  width: 100%;
  margin-top: 20px;
}

.el-table-column {
  padding: 10px;
}

.el-button {
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 6px;
}

.el-button:hover {
  background-color: #409EFF;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.el-dialog__body {
  padding: 20px;
}

/* Pagination Styling */
.el-pagination {
  margin-top: 20px;
  text-align: right;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    padding: 10px;
  }

  .main-content {
    padding-left: 0;
  }

  .el-button {
    width: 100%;
  }
}

</style>
