<template>
  <div class="left-side-container">
    <el-header class="header">
      <i class="el-icon-setting"></i> 后台管理
    </el-header>

    <!-- Left side menu -->
    <el-col class="menu-column">
      <el-menu :default-active="$route.path" router class="el-menu-vertical">
        <el-menu-item index="/chart" v-if="user.role === 0">
          <i class="el-icon-info"></i>
          <span slot="title">统计信息</span>
        </el-menu-item>
        <el-menu-item index="/manageReward" v-if="user.role === 2">
          <i class="el-icon-shopping-bag-1"></i>
          <span slot="title">作品管理</span>
        </el-menu-item>
        <el-menu-item index="/manageUser" v-if="user.role === 0">
          <i class="el-icon-user-solid"></i>
          <span slot="title">角色管理</span>
        </el-menu-item>
        <el-menu-item index="/classifyManager" v-if="user.role === 0">
          <i class="el-icon-platform-eleme"></i>
          <span slot="title">分类管理</span>
        </el-menu-item>
        <el-menu-item index="/shopManager" v-if="user.role === 0">
          <i class="el-icon-success"></i>
          <span slot="title">创作者申请</span>
        </el-menu-item>
        <el-menu-item index="/manageComment" v-if="user.role === 0">
          <i class="el-icon-discover"></i>
          <span slot="title">评论管理</span>
        </el-menu-item>
      </el-menu>
    </el-col>
  </div>
</template>

<script>
import publicJS from "../assets/js/public";
import Header from "./Header";

export default {
  name: "LeftSide",
  components: { Header },
  data() {
    return {
      user: {}
    };
  },
  methods: {
    getUser() {
      this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo").then(res => {
        this.user = res.data;
      });
    }
  },
  mounted() {
    this.getUser();
  }
};
</script>

<style scoped>
.left-side-container {
  padding: 20px;
  background-color: #f4f7fc; /* Light gray background for the whole container */
  min-height: 100vh;
  box-sizing: border-box;
}

.header {
  background-color: #409EFF;
  color: #fff;
  padding: 20px;
  text-align: center;
  font-size: 20px;
  font-weight: 600;
  border-radius: 6px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.menu-column {
  margin-top: 30px;
}

.el-menu-vertical {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.el-menu-item {
  font-size: 16px;

  color: #333; /* 默认字体颜色 */
  border-radius: 6px; /* 圆角 */
  margin-bottom: 10px; /* 间隔 */
  transition: background-color 0.3s ease, color 0.3s ease; /* 更清晰的过渡效果 */
}

.el-menu-item:hover {
  background-color: #409EFF; /* 鼠标悬停时背景色 */
  color: #fff; /* 鼠标悬停时文字颜色 */
}

.el-menu-item.active {
  background-color: #409EFF; /* 激活状态背景色 */
  color: #fff; /* 激活状态文字颜色 */
}

.el-menu-item.disabled {
  color: #dcdfe6; /* 禁用状态文字颜色 */
  background-color: #f5f7fa; /* 禁用状态背景颜色 */
  cursor: not-allowed; /* 禁用时光标变为不可点击状态 */
}


@media screen and (max-width: 768px) {
  .left-side-container {
    padding: 10px;
  }

  .header {
    font-size: 18px;
    padding: 15px;
  }

  .el-menu-item {

    font-size: 14px;
  }
}
</style>
