<template>
  <div class="main-container">
    <PersonLeft></PersonLeft>

    <!-- Main Content Section -->
    <div class="main-content">
      <!-- Page Header -->
      <el-row style="float: left;border-bottom: 1px solid #eee">
        <el-page-header @back="goIndex" content="收货订单"></el-page-header>
      </el-row>

      <!-- Tabs for Order Status -->
      <el-tabs style="float: left;width: 100%;margin-top: 20px" v-model="currentStatus" type="card" @tab-click="handleClick">
        <el-tab-pane label="全部" name="All"></el-tab-pane>
        <el-tab-pane label="进行中" name="Ing"></el-tab-pane>
        <el-tab-pane label="已完成" name="Already"></el-tab-pane>
      </el-tabs>

      <!-- Order Data Table -->
      <el-table :data="orderPage.records" border width="100%" @selection-change="changeSelect">
        <el-table-column type="selection"></el-table-column>
        <el-table-column type="index"/>
        <el-table-column prop="onum" label="订单编号" sortable/>
        <el-table-column prop="oname" label="姓名" width="70px"/>
        <el-table-column label="用户名">
          <template slot-scope="scope">{{ scope.row.user.uname }}</template>
        </el-table-column>
        <el-table-column prop="phoneNum" label="手机号码"/>
        <el-table-column prop="recAddr" label="收货地址"/>
        <el-table-column prop="total" label="订单总金额" sortable/>
        <el-table-column prop="otime" label="下单时间" sortable/>

        <!-- Order Status Column -->
        <el-table-column label="商品状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.deliverStatus==-1" effect="dark" type="warning">等待您付款</el-tag>
            <el-tag v-if="scope.row.deliverStatus==0" effect="dark" type="warning">未发货</el-tag>
            <el-tag v-if="scope.row.deliverStatus==1" effect="dark" type="success">已发货</el-tag>
            <el-tag v-if="scope.row.deliverStatus==3" effect="dark" type="success">已退货</el-tag>
            <el-tag v-if="scope.row.deliverStatus==4" effect="dark" type="success">已取消订单</el-tag>
          </template>
        </el-table-column>

        <!-- Receipt Confirmation Status Column -->
        <el-table-column label="确认收货状态">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.recStatus==0" type="warning">未确认收货</el-tag>
            <el-tag v-if="scope.row.recStatus==1" type="success">已确认收货</el-tag>
            <el-tag v-if="scope.row.recStatus==3" type="success">申请退款</el-tag>
            <el-tag v-if="scope.row.recStatus==4" type="success">等待投诉结果</el-tag>
            <el-tag v-if="scope.row.recStatus==6" type="success">已驳回投诉</el-tag>
            <el-tag v-if="scope.row.recStatus==7" type="success">被管理员强制退货</el-tag>
            <el-tag v-if="scope.row.recStatus==8" type="success">您已同意退货申请</el-tag>
            <el-tag v-if="scope.row.recStatus==9" type="success">您已拒绝退货申请</el-tag>
            <el-tag v-if="scope.row.recStatus==10" type="success">作者取消订单</el-tag>
          </template>
        </el-table-column>

        <!-- Action Buttons -->
        <el-table-column label="操作">
          <template slot-scope="scope">
            <el-button type="primary" size="mini" @click="openDetail(scope.$index)">订单详情</el-button>
            <el-button type="primary" size="mini" @click="dialogVisible3=true;orderId=scope.row.oid" v-if="scope.row.deliverStatus==-1">
              支付订单
            </el-button>
            <el-button type="primary" size="mini" @click="confirm(scope.row.oid)" :disabled="scope.row.deliverStatus==0||scope.row.recStatus==1" v-if="scope.row.deliverStatus==1">
              确认收货
            </el-button>
            <el-button type="primary" size="mini" @click="refuse(scope.row.oid)" :disabled="scope.row.deliverStatus==0||scope.row.recStatus==1 || scope.row.recStatus==4 || scope.row.recStatus==3" v-if="scope.row.deliverStatus==1">
              申请退货
            </el-button>
          </template>
        </el-table-column>
      </el-table>

      <!-- Pagination -->
      <el-pagination v-if="orderPage.pages > 0" layout="prev, pager, next" background :total="orderPage.total" :page-size="orderPage.size" :pager-count="7" @current-change="changePage"></el-pagination>

      <!-- Payment Dialog -->
      <el-dialog title="支付订单" :visible.sync="dialogVisible3">
        <el-row>
          <el-row>支付信息</el-row>
          <el-image src="https://img.atobo.com/ProductImg/EWM/UWeb/1/9/4/0/0447/19400447/1.gif"></el-image>
        </el-row>
        <el-form :model="orderForm" :rules="orderRule" ref="orderForm">
          <el-form-item label="姓名" prop="name">
            <el-input v-model="orderForm.name" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="手机号" prop="phoneNum">
            <el-input v-model="orderForm.phoneNum" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item label="收货地址" prop="recAddr">
            <el-input v-model="orderForm.recAddr" autocomplete="off"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="zhifu('orderForm')">支付订单</el-button>
          </el-form-item>
        </el-form>
      </el-dialog>

      <!-- Order Details Dialog -->
      <el-dialog title="订单详情" :visible.sync="detailVisible">
        <el-table :data="goods">
          <el-table-column property="fname" label="商品名称" width="150"></el-table-column>
          <el-table-column property="price" label="单价" width="120"></el-table-column>
          <el-table-column prop="category.cname" label="种类"/>
          <el-table-column label="封面图片">
            <template slot-scope="scope">
              <el-image :src="scope.row.image.cover"/>
            </template>
          </el-table-column>
          <el-table-column prop="count" label="购买数量"/>
          <el-table-column prop="total" label="单项总价"/>
        </el-table>
      </el-dialog>
    </div>
  </div>
</template>

<script>
import PersonLeft from "./PersonLeft";
import publicJS from "../assets/js/public";

export default {
  name: "Order",
  components: {PersonLeft},
  data() {
    return {
      orderForm: {
        name: '',
        phoneNum: '',
        recAddr: ''
      },
      currentStatus: 'All',
      orderPage: {},
      detailVisible: false,
      goods: [], dialogVisible3: false, orderRule: {
        name: [
          {required: true, message: '请输入姓名', trigger: 'blur'},
          {min: 2, max: 255, message: '长度在 2 到 255 个字符', trigger: 'blur'}
        ],
        phoneNum: [
          {required: true, message: '请输入手机号', trigger: 'blur'},
          {
            pattern: /^1(3[0-9]|4[01456879]|5[0-35-9]|6[2567]|7[0-8]|8[0-9]|9[0-35-9])\d{8}$/,
            message: '请输入正确的手机号码',
            trigger: 'blur'
          }],
        recAddr: [
          {required: true, message: '请输入收货地址', trigger: 'blur'},
          {min: 2, max: 255, message: '长度限定在2-255位', trigger: 'blur'}
        ]
      },
    }
  },
  methods: {
    goIndex() {
      this.$router.replace({path: '/'});
    },
    //标签页切换事件
    handleClick(tab, event) {
      const index = tab.index;
      switch (index) {
        case 0 + '':
          this.getOrder(1, 'All');
          break;
        case 1 + '':
          this.getOrder(1, 'Ing');
          break;
        case 2 + '':
          this.getOrder(1, 'Del');
          break;
      }
    }, zhifu(formName) {
      this.$refs[formName].validate(async (valid) => {
            if (valid) {
              let obj = {
                "takeName": this.orderForm.name,
                "takePhone": this.orderForm.phoneNum,
                "takeAddress": this.orderForm.recAddr
              }


              const res = await this.axios.post(publicJS.methods.getBackPath() + "order/updateorder/" + this.orderId, obj
                  , {
                    headers: {
                      "Content-Type": "application/json",
                    }
                  });
              if (res.data == 0) {
                this.$message.error("填写地址失败,请联系站长");
                return false;
              }

              this.axios.put(publicJS.methods.getBackPath() + "order/zhifu/" + this.orderId).then(res => {
                if (res.data) {
                  this.$message.success("支付成功");

                } else {
                  this.$message.error("操作失败,请联系站长");
                }
              })
              this.dialogVisible3 = false;
              this.getShopInfo();
            }
          }
      )

    },
    getOrder(currentPage, mode) {
      const url = publicJS.methods.getBackPath() + "order/find" + mode + "/user/" + currentPage;
      this.axios.get(url).then(res => {
        const orderPage = res.data;
        const orders = orderPage.records;
        orders.forEach(item => {
          const goods = item.goods;
          goods.forEach(f => {
            f.image.cover = f.image.cover;
          })
        })
        this.orderPage = orderPage;
      })
    }, changeSelect(val) {
      this.selectOrder = val;
    }, gogoods(goods) {
      this.$router.replace({path: '/detail', query: {fid: goods.fid, name: goods.category.englishName}});
    },
    //查看详情按钮
    openDetail(index) {
      this.goods = this.orderPage.records[index].goods;
      this.detailVisible = true;
    },
    changePage() {
      this.getOrder(1, this.currentStatus);
    }, refuse(oid) {
      this.axios.put(publicJS.methods.getBackPath() + "order/refuse/" + oid).then(res => {
        if (res.data) {
          this.$message.success("拒收消息已发送！");
          this.$router.replace({path: '/refresh'});
        } else {
          this.$message.error("操作失败,请联系站长");
        }
      })
    }
    ,
    confirm(oid) {
      this.axios.put(publicJS.methods.getBackPath() + "order/confirm/" + oid).then(res => {
        if (res.data) {
          this.$message.success("确认收货成功");
          this.$router.replace({path: '/refresh'});
        } else {
          this.$message.error("操作失败,请联系站长");
        }
      })
    },

  }
  ,
  mounted() {
    this.getOrder(1, 'All');
  }
}
</script>

<style scoped>
.main-container {
  display: flex;
  flex-direction: row;
  padding: 20px;
}

.main-content {
  flex-grow: 1;
  padding-left: 20px;
  background-color: #f9fafb;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.el-table {
  width: 100%;
  margin-top: 20px;
}

.el-table-column {
  padding: 10px;
}

.el-button {
  font-size: 16px;
  padding: 10px 20px;
  border-radius: 6px;
}

.el-button:hover {
  background-color: #409EFF;
}

.dialog-footer {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.el-dialog__body {
  padding: 20px;
}

/* Pagination Styling */
.el-pagination {
  margin-top: 20px;
  text-align: right;
}

/* Adjustments for smaller screens */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    padding: 10px;
  }

  .main-content {
    padding-left: 0;
  }

  .el-button {
    width: 100%;
  }
}.el-page-header__content {
   font-size: 18px;
   color: #303133;
 }

</style>
