<template>
  <div class="charts-container main-content">
    <LeftSide></LeftSide>
    <div v-if="user.role==0" class="main-content">
      <div id="gender" class="chart-box"></div>
      <div id="city" class="chart-box"></div>
      <div id="birthday" class="chart-box"></div>
      <div id="order" class="chart-box"></div>
    </div>
    <div v-else-if="user.role==2" class="main-content">
      <Charts2></Charts2>
    </div>
    <div v-else >欢迎</div>
  </div>
</template>

<script>
import publicJS from "../assets/js/public";
import LeftSide from "./LeftSide";
import Charts2 from "@/components/UserChart.vue";

export default {
  name: "Charts",
  components: {Charts2, LeftSide},
  data() {
    return {
      user: {},
      genderOption: {
        title: {text: '男女比例'},
        series: [
          {
            type: 'pie',
            label: {formatter: '{b}: {@2012} ({d}%)'},
            data: [],
            radius: '50%'
          }
        ]
      },
      cityOption: {
        title: {text: '居住地分布'},
        series: [
          {
            type: 'pie',
            label: {formatter: '{b}: {@2012} ({d}%)'},
            data: [],
            radius: '50%'
          }
        ]
      },
      birthdayOption: {
        title: {text: '年龄分布'},
        series: [
          {
            type: 'pie',
            label: {formatter: '{b}: {@2012} ({d}%)'},
            data: [],
            radius: '50%'
          }
        ]
      },
      orderOption: {
        title: {text: '销量统计'},
        tooltip: {},
        xAxis: {
          data: []
        },
        yAxis: {},
        series: [{
          name: '销量',
          type: 'bar',
          data: []
        }]
      }
    };
  },
  methods: {
    getUserInfo() {
      this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo").then(res => {
        this.user = res.data;
        if (this.user.role == 0) {
          this.getUserChart();
          this.getOrderChart();
          this.drawGender();
          this.drawCity();
          this.drawBirthday();
          this.drawOrder();
        }
      });
    },
    drawGender() {
      let myChart = this.$echarts.init(document.getElementById('gender'));
      myChart.setOption(this.genderOption);
    },
    drawCity() {
      let myChart = this.$echarts.init(document.getElementById('city'));
      myChart.setOption(this.cityOption);
    },
    drawBirthday() {
      let myChart = this.$echarts.init(document.getElementById('birthday'));
      myChart.setOption(this.birthdayOption);
    },
    drawOrder() {
      let myChart = this.$echarts.init(document.getElementById('order'));
      myChart.setOption(this.orderOption);
    },
    getUserChart() {
      this.axios.get(publicJS.methods.getBackPath() + "user/findChart").then(res => {
        this.genderOption.series[0].data = res.data.gender;
        this.cityOption.series[0].data = res.data.city;
        this.birthdayOption.series[0].data = res.data.birthday;
        this.drawGender();
        this.drawCity();
        this.drawBirthday();
      });
    },
    getOrderChart() {
      this.axios.get(publicJS.methods.getBackPath() + "order/findChart").then(res => {
        const name = [];
        const value = [];
        res.data.order.forEach(item => {
          name.push(item.name);
          value.push(item.value);
        });
        this.orderOption.xAxis.data = name;
        this.orderOption.series[0].data = value;
        this.drawOrder();
      });
    }
  },
  mounted() {
    this.getUserInfo();
  }
};
</script>

<style scoped>
.charts-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: flex-start;
}

.main-content {
  width: 83%;
  padding: 20px;
}

.chart-box {
  width: 500px;
  height: 300px;
  margin: 20px;
  float: left;
}

@media (max-width: 768px) {
  .charts-container {
    flex-direction: column;
  }
  .main-content {
    width: 100%;
  }
  .chart-box {
    width: 100%;
    margin: 10px 0;
  }
}
</style>
