<template>
  <div class="main-container">
    <!--左侧导航-->
    <PersonLeft></PersonLeft>
    <!--右侧-->
    <div class="main-content">
      <el-row style="width: 100%;float: left;border-bottom: 1px solid #eee">
        <el-page-header @back="goIndex" content="小说管理"></el-page-header>
      </el-row>
      <el-tag v-if="!shopInfo">请先编辑个人主页信息，用于展示</el-tag>
      <div style="width: 100%;float: left;margin-top: 20px" v-else>
        <!--添加操作-->
        <el-row>
          <el-button type="primary" @click="addDialogVisible = true;">添加</el-button>
        </el-row>
        <!--搜索表单-->
        <el-form style="width: fit-content;margin: 0 auto" :inline="true" :model="furniture" class="demo-form-inline">
          <el-form-item>
            <el-input v-model="furniture.search" placeholder="小说名称"></el-input>
          </el-form-item>
          <el-form-item label="书籍分类" prop="cid">
            <el-select v-model="furniture.cid">
              <el-option v-for="category in categorys" :label="category.cname" :value="category.cid"/>
            </el-select>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">查询</el-button>
            <el-button type="primary" @click="furniture.cid='';furniture.search='';getfurniture(furniture.currentPage)">
              重置
            </el-button>
          </el-form-item>
        </el-form>
        <!--小说展示-->
        <el-table
            :data="furnitures.records"
            stripe

            @selection-change="handleSelectionChange">
          <el-table-column type="index"/>
          <el-table-column label="名称" sortable>
            <template slot-scope="scope">
              <router-link :to="goDetail(scope.row.fid,scope.row.category.englishName)" style="color: #66b1ff">
                {{ scope.row.fname }}
              </router-link>
            </template>
          </el-table-column>
          <el-table-column prop="price" label="价格" sortable/>
          <el-table-column prop="category" label="分类" sortable>
            <template slot-scope="scope">
              {{ scope.row.category.cname }}
            </template>
          </el-table-column>
          <el-table-column prop="date" label="日期" sortable>
            <template slot-scope="scope">
              {{ new Date(scope.row.date).toLocaleString() }}
            </template>
          </el-table-column>

          <!-- ... 其他列省略 ... -->
          <el-table-column label="操作">
            <template slot-scope="scope">
              <el-button type="success" @click="dialogVisible5=true;nowData=scope.row;queryData();nowFid=scope.row.fid">
                修改章节
              </el-button>
              <el-button type="success" @click="openAddChapterDialog(scope.row)">
                增加章节
              </el-button>
              <el-button type="warning"
                         @click="updateDialogVisible=true;getfurnitureByUpdate(scope.row.fid,scope.row.category.englishName);">
                修改小说
              </el-button>
              <el-button type="warning" @click="resDialogVisible=true;queryRes(scope.row);">
                选择介绍素材
              </el-button>
              <el-button @click="dialogVisible = true;fid=scope.row.fid" type="danger">删除</el-button>
            </template>
          </el-table-column>

        </el-table>

        <!--页码-->
        <div>
          <!--少于等于7页-->
          <div v-if="furnitures.pages<=7" class="block">
            <el-pagination
                layout="prev, pager, next"
                background
                :total="furnitures.total"
                :page-size="furnitures.size"
                :pager-count="7"
                @current-change="changePage">
            </el-pagination>
          </div>
          <!--大于7页-->
          <div v-if="furnitures.pages>7" class="block">
            <el-pagination
                layout="prev, pager, next"
                background
                :total="furnitures.total"
                :page-size="furnitures.size"
                :pager-count="9"
                @current-change="changePage">
            </el-pagination>
          </div>
        </div>
        <el-dialog
            title="增加或修改小说章节"
            :visible.sync="dialogVisible4"
            @close="destroySummernote">
          <el-form :model="form" label-width="80px">
            <el-form-item label="章节名称">
              <el-input v-model="form.name"></el-input>
            </el-form-item>
            <el-form-item label="章节分类">
              <el-select v-model="form.type" placeholder="请选择">
                <el-option label="免费" value="0"></el-option>
                <el-option label="付费" value="1"></el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="章节内容">
              <div ref="summernote" class="summernote"></div>
            </el-form-item>
            <el-form-item label="排序">
              <el-input type="textarea" v-model="form.sort"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button type="primary" @click="onSubmit2">提交</el-button>
              <el-button @click="dialogVisible4 = false">取消</el-button>
            </el-form-item>
          </el-form>
        </el-dialog>
        <!--删除的dialog对话-->
        <el-dialog
            title="删除小说"
            :visible.sync="dialogVisible"
            width="30%"
        >
          <span>确认删除选中的小说?</span>
          <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
        <el-button type="primary" @click="del">确 定</el-button>
      </span>
        </el-dialog>
        <!--选择素材dialog-->
        <el-dialog
            title="选择素材"
            :visible.sync="resDialogVisible"
            width="70%">
          <el-button @click="updateGoods()">确认</el-button>
          <el-checkbox-group v-model="checkList">
            <el-checkbox style="display: inline-block;border: 1px black solid;margin-right: 20px;margin-bottom: 20px"
                         v-for="item in resData" :label="item.id" :key="item.id">
              <el-image fit="contain" :src="item.url" style="width: 300px;height: 300px;">

              </el-image>
              <el-row>
                <el-tag>{{ item.name }}</el-tag>
              </el-row>
            </el-checkbox>
          </el-checkbox-group>

        </el-dialog>
        <!--添加的dialog-->
        <el-dialog
            title="添加小说"
            :visible.sync="addDialogVisible"
            width="70%">
          <Insert></Insert>
        </el-dialog>

        <!-- 修改章节列表对话框 -->
        <el-dialog title="修改小说" :visible.sync="dialogVisible5" >
          <el-table :data="tableData"  max-height="250">
            <el-table-column fixed label="小说名称" width="150">
              {{ nowData.fname }}
            </el-table-column>
            <el-table-column prop="name" label="章节名称" width="120"></el-table-column>
            <el-table-column label="章节分类" width="120">
              <template slot-scope="scope">
                {{ scope.row.type == 0 ? "免费" : "收费" }}
              </template>
            </el-table-column>
            <el-table-column prop="sort" label="排序" width="120"></el-table-column>
            <el-table-column label="操作" width="120">
              <template slot-scope="scope">
                <el-button @click="openUpdateChapterDialog(scope.row)" type="text" size="small">
                  修改章节
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </el-dialog>
        <!--修改的dialog-->
        <el-dialog
            title="修改小说"
            :visible.sync="updateDialogVisible"
           >
          <!--修改-->
          <div style="width: 900px;margin: 0 auto">
            <!--表单-->
            <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
                     label-width="100px" class="demo-ruleForm" :label-position="labelPostion">

              <el-form-item label="小说名称" prop="fname">
                <el-input type="textarea" v-model="ruleForm.fname"></el-input>
              </el-form-item>

              <el-form-item label="小说价格" prop="price">
                <el-input v-model="ruleForm.price"/>
              </el-form-item>
              <!--封面图片-->
              <el-form-item label="封面图片(小于3M)" prop="cover">
                <el-upload
                    class="avatar-uploader"
                    action="#"
                    :auto-upload="false"
                    :show-file-list="false"
                    :file-list="cover.fileList"
                    :on-change="coverOnChange"
                    :before-upload="coverBeforeUpload">
                  <img v-if="cover.imageUrl" :src="cover.imageUrl" class="avatar">
                  <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                </el-upload>
              </el-form-item>
              <el-form-item label="发布时间" prop="date">
                <el-time-picker placeholder="选择时间" v-model="ruleForm.date" style="width: 100%;"></el-time-picker>

              </el-form-item>
              <!--商品分类-->
              <el-form-item label="书籍分类" prop="cid">
                <el-select v-model="ruleForm.cid">
                  <el-option v-for="category in categorys" :label="category.cname" :value="category.cid"/>
                </el-select>
              </el-form-item>
              <!--提交、重置-->
              <!--            <el-form-item>-->
              <!--              <el-button type="primary" @click="submitForm('ruleForm')">立即修改</el-button>-->
              <!--              <el-button @click="resetForm('ruleForm')">重置</el-button>-->
              <!--            </el-form-item>-->
            </el-form>
          </div>
          <el-button @click="dialogVisible = false;submitForm('ruleForm')">修改</el-button>
          <el-button type="primary" @click="resetForm('ruleForm')">重置</el-button>
        </el-dialog>
      </div>
    </div>
  </div>
</template>

<script>
import publicJS from '../assets/js/public'
import '../assets/css/public.css'
import LeftSide from "./LeftSide";
import Insert from "./Insert";
import PersonLeft from "@/components/PersonLeft.vue";


export default {
  name: "ManageReward",
  props: {
    modelValue: {
      type: String,
      default: '',
    },
  },
  emits: ['update:modelValue'],

  components: {PersonLeft, Insert, LeftSide},
  data() {
    return {
      furnitures: {},//展示小说对象
      fid: 0,//当前操作的fid
      englishName: '',//分类英文名
      furniture: {
        currentPage: 1,//当前页
        search: '',//搜索内容
        cid: ''
      }, form: {
        content: '',
        typeId: '',
        name: '',
        type: '',
        sort: ''
      }, tableData: [], type: [], nowFid: [],

      user: {}, nowData: {},
      shopInfo: {},
      dialogVisible: false,
      addDialogVisible: false,
      updateDialogVisible: false, dialogVisible4: false, dialogVisible5: false,
      cateoryId: '',
      //修改的data
      categorys: [],//种类
      labelPostion: 'left',//表单左对齐
      ruleForm: {//form校验数据对象
        fid: 0,
        fname: '',
        date: '',
        price: '',
        cover: '',
        show: '',
        sid: 0,
        uid: 0,
        cid: 0
      },
      rules: {//校验规则
        fname: [
          {required: true, message: '请输入小说名称', trigger: 'blur'},
          {min: 2, max: 2000, message: '长度在 2 到 2000 个字符', trigger: 'blur'}
        ],
        cid: [
          {required: true, message: '请选择书籍分类', trigger: 'change'}
        ],
        price: [
          {
            required: true,
            pattern: /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/,
            message: "请输入合法的金额数字，最多两位小数",
            trigger: 'blur'
          }
        ],
        cover: [{required: true}],
        show: [{required: true}], date: [{required: true}]
      },
      imageSize: 3,//图片上传大小最大3MB
      cover: {
        imageUrl: '',
        fileList: [],
        changed: false
      }, resDialogVisible: false, resData: [], checkList: [], updateGoodsId: 0,
    }
  },
  methods: {
    destroySummernote() {
      const $editor = $(this.$refs.summernote);
      if ($editor.summernote('isActivated')) {
        $editor.summernote('destroy');
      }
    },
    openUpdateChapterDialog(chapter) {
      this.type = 2;
      this.form = { ...chapter };
      this.dialogVisible4 = true;
      this.$nextTick(() => {
        this.initSummernote(); // 重新初始化 Summernote
        this.setEditorContent(this.form.content);
      });
    },
    openAddChapterDialog(row) {
      this.type = 1;
      this.nowData = row;
      this.form = { name: '', type: '', content: '', sort: '' };
      this.dialogVisible4 = true;
      this.$nextTick(() => {
        this.initSummernote(); // 重新初始化 Summernote
        this.setEditorContent('');
      });
    },
    onSubmit2() {
      const $editor = $(this.$refs.summernote);
      this.form.content = $editor.summernote('code'); // 手动获取 Summernote 的最新内容

      var formData = new FormData();

      if (this.type == 1) {
        this.form.typeId = this.nowData.fid; // 添加章节时使用当前小说的 fid
      } else {
        this.form.typeId = this.nowFid; // 修改章节时使用当前章节的 fid
      }

      // 将 form 中的所有字段添加到 formData
      for (let formKey in this.form) {
        formData.append(formKey, this.form[formKey]);
      }

      // 发送请求
      if (this.type == 1) {
        this.axios.post(publicJS.methods.getBackPath() + "content/add", formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(res => {
          this.dialogVisible4 = false;
          this.$message.info("添加成功");
          this.queryData(); // 提交成功后刷新章节列表
        }).catch(err => {
          this.$message.error("添加失败");
          console.error(err);
        });
      } else if (this.type == 2) {
        this.axios.post(publicJS.methods.getBackPath() + "content/update/" + this.form.id, formData, {
          headers: { 'Content-Type': 'multipart/form-data' }
        }).then(res => {
          this.dialogVisible4 = false;
          this.$message.info("修改成功");
          this.queryData(); // 提交成功后刷新章节列表
        }).catch(err => {
          this.$message.error("修改失败");
          console.error(err);
        });
      }
    },
    goIndex() {
      this.$router.replace({path: '/'});
    },  //查询小说
    queryData: function () {
      this.axios.get(publicJS.methods.getBackPath() + "content/queryByTypeId/" + this.nowData.fid,).then(resp => {
        this.tableData = resp.data;
      })
    },
    //查询小说
    getfurniture: function (currentPage) {
      this.axios.get(publicJS.methods.getBackPath() + "f/findBySearch/" + currentPage + "/1", {
        params: {
          search: this.furniture.search,
          cid: this.furniture.cid
        }
      }).then(res => {
        this.furnitures = res.data;
      })
    },
    //去详情页面
    goDetail(fid, name) {
      return "/detail?fid=" + fid + "&name=" + name;
    },
    //去修改页面
    goUpdate(fid, name) {
      return "/update?fid=" + fid + "&name=" + name;
    },
    //表格选中项改变事件
    handleSelectionChange: function (val) {
      this.multipleSelection = val;
      console.log(this.multipleSelection);
    },
    //切换页
    changePage: function (page) {
      this.getfurniture(page);
    },
    //搜索提交
    onSubmit: function () {
      this.getfurniture(1);
    },
    //删除事件
    del() {
      this.axios.delete(publicJS.methods.getBackPath() + "f/del/" + this.fid).then(res => {
        this.dialogVisible = false;
        if (res.data) {
          this.$message.success("删除成功");
          this.$router.replace({path: '/refresh'});
        } else {
          this.$message.error("删除失败");
        }
      })
    }, updateGoods() {
      var string = this.checkList;
      this.axios.post(publicJS.methods.getBackPath() + "f/updateList/" +
          this.updateGoodsId, string).then(res => {
        this.$message.success("更新成功");
        this.updateDialogVisible = false;
        this.loadShopInfo();

      })
    },

    //修改的方法
    //提交表单
    submitForm(formName) {
      this.ruleForm.cover = 1;
      this.ruleForm.show = 1;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append("fid", this.ruleForm.fid);
          formData.append("fname", this.ruleForm.fname);
          formData.append("cid", this.ruleForm.cid);
          formData.append("price", this.ruleForm.price);
          formData.append("date", new Date(this.ruleForm.date));
          formData.append("uid", this.ruleForm.uid);
          formData.append("sid", this.ruleForm.sid);
          formData.append("type", "1");
          if (this.cover.changed) {
            this.cover.fileList.forEach((item) => {
              formData.append("cover", item.raw);
            });
          }

          let config = {
            headers: {'Content-Type': 'multipart/form-data'}
          }
          this.axios.put(publicJS.methods.getBackPath() + "f/update",
              formData, config).then(res => {
            if (res.data) {
              this.updateDialogVisible = false;
              this.$message.success("修改成功");
              this.$router.replace({path: '/refresh'});
            }
          })
        } else {
          return false;
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.getfurniture(this.$route.query.fid);
    },
    //封面图片上传前判断文件格式以及大小
    coverBeforeUpload(file) {
      const isJPEG = file.type === 'image/jpeg';
      const isJPG = file.type === 'image/jpg';
      const isPNG = file.type === 'image/png';
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!(isJPEG || isJPG || isPNG)) {
        this.$message.error("上传格式有误");
      }
      if (!isLt3M) {
        this.$message.error('上传头像图片大小不能超过 3MB!');
      }
      return (isJPEG || isJPG || isPNG) && isLt3M;
    },
    //封面图片上传图片改变事件
    coverOnChange(file, fileList) {
      let existFile = fileList
          .slice(0, fileList.length - 1)
          .find((f) => f.name === file.name);
      if (existFile) {
        this.$message.error("当前文件已经存在!");
        fileList.pop();
      }
      this.cover.changed = true;
      this.cover.imageUrl = URL.createObjectURL(file.raw);
      this.cover.fileList = fileList;
    },

    //展示图片1上传前判断文件格式以及大小
    showOneBeforeUpload(file) {
      const isJPEG = file.type === 'image/jpeg';
      const isJPG = file.type === 'image/jpg';
      const isPNG = file.type === 'image/png';
      const isLt3M = file.size / 1024 / 1024 < 3;
      if (!(isJPEG || isJPG || isPNG)) {
        this.$message.error("上传格式有误");
      }
      if (!isLt3M) {
        this.$message.error('上传头像图片大小不能超过 3MB!');
      }
      return (isJPEG || isJPG || isPNG) && isLt3M;
    },
    getCategory: function () {
      this.axios.get(publicJS.methods.getBackPath() + "category/findAll").then(res => {
        this.categorys = res.data;
      })
      //修改某个索引图片
    }, updateOne: function (url, index) {
      const formData = new FormData();

      url.forEach((item) => {
        formData.append("multipartFile", item.raw);
      });
      let config = {
        headers: {'Content-Type': 'multipart/form-data'}
      }
      this.axios.put(publicJS.methods.getBackPath() + "f/updateOne/" + this.ruleForm.fid + "/" + index,
          formData, config).then(res => {
        if (res.data) {
          this.$message.success("修改成功");

        } else {
          this.$message.error("修改失败");
        }
      })
    }, loadShopInfo() {
      this.axios.get(publicJS.methods.getBackPath() + "shop/getCurrent").then(res => {
        this.shopInfo = res.data;

      })
    }, queryRes(row) {
      this.updateGoodsId = row.fid;
      if (row.listIds != null && row.listIds.length > 2) {
        this.checkList = JSON.parse(row.listIds);
      } else {
        this.checkList = [];

      }

      this.axios.get(publicJS.methods.getBackPath() + "currentUserRes").then(res => {

        this.resData = res.data;
      })


    },
    getfurnitureByUpdate(fid, fname) {
      this.axios.get(publicJS.methods.getBackPath() + "f/findByFid/" + fid).then(res => {
        var furniture = res.data;
        this.ruleForm.fname = furniture.fname;

        this.ruleForm.price = furniture.price;
        this.ruleForm.fid = furniture.fid;

        this.ruleForm.date = furniture.date;
        this.ruleForm.cid = furniture.cid;

        this.ruleForm.uid = furniture.uid;
        this.ruleForm.sid = furniture.sid;

        const image = furniture.image;

        this.cover.fileList = [{name: 'cover.jpg', url: image.cover}];
        this.cover.imageUrl = image.cover;

      })
    },
    setEditorContent(content) {
      const $editor = $(this.$refs.summernote);
      $editor.summernote('code', content);
    },
    initSummernote() {
      const $editor = $(this.$refs.summernote);
      $editor.summernote({
        height: 300,
        placeholder: '请输入内容...',
        toolbar: [
          ['style', ['bold', 'italic', 'underline']],
          ['font', ['strikethrough', 'superscript', 'subscript']],
          ['para', ['ul', 'ol', 'paragraph']],
          ['insert', ['link', 'picture']],
        ],
        callbacks: {
          onChange: (contents) => {
            this.form.content = contents;
          },
        },
      });
    },
  },
  mounted() {
    this.getfurniture(1);
    this.getCategory();
    this.loadShopInfo();
    // this.initSummernote();
  },
  watch: {
    modelValue(newValue) {

      // 当外部传入的 modelValue 改变时，更新编辑器内容
      const $editor = $(this.$refs.summernote);
      if (newValue !== $editor.summernote('code')) {
        $editor.summernote('code', newValue);
      }
    },
  }
}
</script>

<style scoped>
.summernote {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
}

.main-container {
  display: flex;
  padding: 20px;
}

/* Main content area with background color, border radius, and shadow */
.main-content {
  flex-grow: 1;
  padding-left: 20px;
  background-color: #f9fafb; /* Light grayish background */
  border-radius: 10px; /* Rounded corners */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1); /* Subtle shadow */
}

/* Avatar uploader container style */
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9; /* Dashed border */
  border-radius: 6px; /* Rounded corners for upload box */
  cursor: pointer; /* Pointer cursor on hover */
  position: relative;
  overflow: hidden;
}

/* Hover effect for avatar uploader */
.avatar-uploader .el-upload:hover {
  border-color: #409EFF; /* Change border color to blue on hover */
}

/* Avatar icon size and positioning */
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d; /* Gray color for icon */
  width: 178px;
  height: 178px;
  line-height: 178px; /* Center text inside the box */
  text-align: center; /* Centering icon */
}

/* Avatar image style */
.avatar {
  width: 178px;
  height: 178px;
  display: block; /* Display as a block-level element */
}

/* Additional form or button styles */
.el-button {
  font-size: 16px;
  padding: 8px 16px;
  border-radius: 6px;
  transition: all 0.3s ease;
}

/* Button hover effect */
.el-button:hover {
  background-color: #409EFF; /* Blue background on hover */
  color: white; /* White text on hover */
}

/* Form styling */
.el-form-item {
  margin-bottom: 20px; /* Spacing between form items */
}

/* Input fields inside form */
.el-input, .el-select, .el-textarea {
  border-radius: 6px;
  padding: 10px;
}

/* Styling for input or select field focus */
.el-input:focus, .el-select:focus, .el-textarea:focus {
  border-color: #409EFF; /* Blue border on focus */
  box-shadow: 0 0 5px rgba(64, 158, 255, 0.5); /* Subtle glow effect */
}

/* Pagination styling */
.el-pagination {
  margin-top: 20px;
  text-align: center;
}

.el-pagination .el-button {
  border-radius: 6px;
}


/* Media queries for responsiveness */
@media (max-width: 768px) {
  .main-container {
    flex-direction: column;
    padding: 10px;
  }

  .main-content {
    padding-left: 0;
    margin-top: 20px;
  }

  .el-button {
    width: 100%; /* Full-width buttons on small screens */
  }

  .el-form-item {
    width: 100%; /* Full-width form items */
  }
}

</style>
