<template>
  <div class="left-side-container">
    <!-- Header -->
    <el-header class="header">
      <i class="el-icon-setting"></i>个人中心
    </el-header>

    <!-- Left Side Menu -->
    <el-col class="menu-column">
      <el-menu :default-active="$route.path" router class="el-menu-vertical">

        <!-- User Info (Always available) -->
        <el-menu-item index="/personal">
          <i class="el-icon-user-solid"></i>
          <span slot="title">我的信息</span>
        </el-menu-item>

        <!-- Favorites List (Always available) -->
        <el-menu-item index="/attention">
          <i class="el-icon-star-on"></i>
          <span slot="title">收藏列表</span>
        </el-menu-item>

        <!-- Shopping Cart (Visible only for users with role != 0) -->
        <el-menu-item index="/shopcar" v-if="user.role != 0">
          <i class="el-icon-shopping-cart-2"></i>
          <span slot="title">购物车</span>
        </el-menu-item>

        <!-- Manage Rewards (Visible only for users with role == 2) -->
        <el-menu-item index="/ManageReward" v-if="user.role == 2">
          <i class="el-icon-goods"></i>
          <span slot="title">发布小说</span>
        </el-menu-item>

        <!-- Order Management (Visible only for users with role != 0) -->
        <el-menu-item index="/order" v-if="user.role != 0">
          <i class="el-icon-tickets"></i>
          <span slot="title">收货订单</span>
        </el-menu-item>

        <!-- Manage Orders (Visible only for users with role == 2) -->
        <el-menu-item index="/manageOrder" v-if="user.role == 2">
          <i class="el-icon-document"></i>
          <span slot="title">发货订单</span>
        </el-menu-item>

        <!-- Material Management (Visible only for users with role == 2) -->
        <el-menu-item index="/material" v-if="user.role == 2">
          <i class="el-icon-document"></i>
          <span slot="title">素材管理</span>
        </el-menu-item>

        <!-- Homepage Management (Visible only for users with role != 0) -->
        <el-menu-item index="/shopEdit" v-if="user.role != 0">
          <i class="el-icon-info"></i>
          <span slot="title">主页管理</span>
        </el-menu-item>

      </el-menu>
    </el-col>
  </div>
</template>


<script>
import publicJS from "@/assets/js/public";

export default {
  name: "PersonLeft", mounted() {
    this.getUserInfo();
  }, data() {
    return {
      user: ''
    }
  }, methods: {
    getUserInfo() {
      this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo").then(res => {
        this.user = res.data;
      });
    }

  }
}
</script>

<style scoped>
/* Main container and content */
.left-side-container {
  padding: 20px;
  background-color: #f4f7fc; /* Light gray background for the whole container */
  min-height: 100vh;
  box-sizing: border-box;
}

.header {
  background-color: #409EFF;
  color: #fff;
  padding: 20px;
  text-align: center;

  font-weight: 600;
  border-radius: 6px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.menu-column {
  margin-top: 30px;
}

.el-menu-vertical {
  background-color: #fff;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.el-menu-item {
  font-size: 16px;
  color: #333; /* Default text color */
  border-radius: 6px; /* Rounded corners */
  margin-bottom: 10px; /* Space between items */
  transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transitions for hover */
}

.el-menu-item:hover {
  background-color: #409EFF; /* Hover background */
  color: #fff; /* Hover text color */
}

.el-menu-item.active {
  background-color: #409EFF; /* Active state background */
  color: #fff; /* Active state text color */
}

.el-menu-item.disabled {
  color: #dcdfe6; /* Disabled state text color */
  background-color: #f5f7fa; /* Disabled state background */
  cursor: not-allowed; /* Disabled cursor */
}

@media screen and (max-width: 768px) {
  .left-side-container {
    padding: 10px;
  }

  .header {
    font-size: 18px;
    padding: 15px;
  }

  .el-menu-item {
    font-size: 14px;
  }
}

</style>
