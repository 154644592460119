<template>
  <div class="header-container">
    <el-row class="header-row">
      <!-- 左侧用户按钮及欢迎信息 -->
      <el-col :span="20">
        <el-button type="warning" class="register-button" @click="goToRegister">
          <i class="el-icon-s-custom"></i> 注册
        </el-button>


        <el-dropdown v-if="user" class="user-dropdown">
          <el-button type="primary">
            欢迎您, {{ user.uname }} <i class="el-icon-arrow-down el-icon--right"></i>
          </el-button>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item v-if="user.role === 0 || user.role === 2">
              <router-link to="/chart" class="dropdown-item">后台管理</router-link>
            </el-dropdown-item>
            <el-dropdown-item>
              <router-link to="/personal" class="dropdown-item">个人中心</router-link>
            </el-dropdown-item>
            <!-- 退出按钮 -->
            <el-dropdown-item>
              <el-button type="primary" class="login-button" @click="logout" style="width: 100%;">
                退出
              </el-button>
            </el-dropdown-item>
          </el-dropdown-menu>
        </el-dropdown>


        <el-button v-else type="primary" class="login-button" @click="goToLogin">
          登录
        </el-button>

      </el-col>

      <!-- 右侧主题选择器 -->
      <el-col :span="4">
        <ThemePicker></ThemePicker>
      </el-col>
    </el-row>

    <!-- 导航菜单 -->
    <el-row class="menu-row" style="margin-top: 10px">
      <el-menu router :default-active="$route.path" class="el-menu-demo" mode="horizontal">
        <el-menu-item index="/show">主页</el-menu-item>
        <!-- <el-menu-item index="/new">其他消息</el-menu-item> -->
      </el-menu>
    </el-row>
  </div>
</template>

<script>
import publicJS from '../assets/js/public';
import ThemePicker from "@/components/ThemePicker.vue";

export default {
  name: "Header",
  components: { ThemePicker },
  data() {
    return {
      user: null
    };
  },
  methods: {
    getUser() {
      this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo").then(res => {
        this.user = res.data;
      });
    },  goToRegister() {
      this.$router.push('/register');
    },   goToLogin() {
      this.$router.push('/login');
    },
    logout() {
      this.axios.get(publicJS.methods.getBackPath() + 'user/logout').then(() => {
        // 否则进行跳转
        this.$router.push('/')
        window.location.reload();
      });

    }
  },
  mounted() {
    this.getUser();
  }
};
</script>

<style scoped>
.header-container {
  padding: 20px;

  color: white;
}

.header-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.register-button,
.login-button {
  margin-right: 10px;
}

.user-dropdown .el-button {
  padding: 10px;
}

.dropdown-item {
  color: #606266;
  text-decoration: none;
}

.dropdown-item:hover {
  color: #409EFF;
}

.menu-row {
  padding-top: 10px;
}

.el-menu-demo {
  background-color: #fff;
  border-bottom: 1px solid #ddd;
}

.el-menu-item {
  font-size: 16px;
  color: #333;
}

.el-menu-item:hover {
  color: #409EFF;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .header-container {
    padding: 15px;
  }

  .header-row {
    flex-direction: column;
    text-align: center;
  }

  .el-button {
    width: 100%;
    margin-bottom: 10px;
  }

  .menu-row {
    margin-top: 20px;
  }
}
</style>
