<template>
  <div style="width: 900px;margin: 0 auto">
    <!--表单-->
    <el-form :model="ruleForm" :rules="rules" ref="ruleForm"
             label-width="100px" class="demo-ruleForm" :label-position="labelPostion">

      <el-form-item label="商品名称" prop="fname">
        <el-input v-model="ruleForm.fname"></el-input>
      </el-form-item>

<!--      <el-form-item label="商品分类" prop="cid">-->
<!--        <el-select v-model="ruleForm.cid" disabled>-->
<!--          <el-option v-for="category in categorys" :label="category.cname" :value="category.cid"/>-->
<!--        </el-select>-->
<!--      </el-form-item>-->

      <el-form-item label="商品价格" prop="price">
        <el-input v-model="ruleForm.price"/>
      </el-form-item>

      <el-form-item label="封面图片(小于3M)" prop="cover">
        <el-upload
          class="avatar-uploader"
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          :file-list="cover.fileList"
          :on-change="coverOnChange"
          :before-upload="coverBeforeUpload">
          <img v-if="cover.imageUrl" :src="cover.imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>

      <el-form-item label="商品展示图片(小于3M, jpeg/jpg/png, 4张)" prop="cover">
        <el-upload
          style="float: left"
          class="avatar-uploader"
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          :file-list="showOne.fileList"
          :on-change="showOneOnChange"
          :before-upload="showOneBeforeUpload">
          <img v-if="showOne.imageUrl" :src="showOne.imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-upload
          style="float: left;margin-left: 15px"
          class="avatar-uploader"
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          :file-list="showTwo.fileList"
          :on-change="showTwoOnChange"
          :before-upload="showTwoBeforeUpload">
          <img v-if="showTwo.imageUrl" :src="showTwo.imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-upload
          style="float: left;margin-left: 15px"
          class="avatar-uploader"
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          :file-list="showThree.fileList"
          :on-change="showThreeOnChange"
          :before-upload="showThreeBeforeUpload">
          <img v-if="showThree.imageUrl" :src="showThree.imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
        <el-upload
          style="float: left;margin-left: 15px"
          class="avatar-uploader"
          action="#"
          :auto-upload="false"
          :show-file-list="false"
          :file-list="showFour.fileList"
          :on-change="showFourOnChange"
          :before-upload="showFourBeforeUpload">
          <img v-if="showFour.imageUrl" :src="showFour.imageUrl" class="avatar">
          <i v-else class="el-icon-plus avatar-uploader-icon"></i>
        </el-upload>
      </el-form-item>
<!--      &lt;!&ndash;提交、重置&ndash;&gt;-->
<!--      <el-form-item>-->
<!--        <el-button type="primary" @click="submitForm('ruleForm')">立即修改</el-button>-->
<!--        <el-button @click="resetForm('ruleForm')">重置</el-button>-->
<!--      </el-form-item>-->
    </el-form>
  </div>
</template>

<script>
import publicJS from '../assets/js/public'

export default {
  name: "Update",
  data(){
    return{
      categorys:[],//种类
      labelPostion:'left',//表单左对齐
      ruleForm:{//form校验数据对象
        fid:0,
        fname:'',
        cid:'',
        price:'',
        cover:'',
        show:''
      },
      rules: {//校验规则
        fname: [
          { required: true, message: '请输入商品名称', trigger: 'blur' },
          { min: 5, max: 30, message: '长度在 5 到 30 个字符', trigger: 'blur' }
        ],
        cid: [
          { required: true, message: '请选择商品分类', trigger: 'change' }
        ],
        price: [
          {required: true, pattern: /((^[1-9]\d*)|^0)(\.\d{0,2}){0,1}$/,message: "请输入合法的金额数字，最多两位小数", trigger: 'blur' }
        ],
        cover:[{required:true}],
        show:[{required:true}]
      },
      imageSize:3,//图片上传大小最大3MB
      cover:{
        imageUrl:'',
        fileList:[],
        changed:false
      },
      showOne:{
        imageUrl:'',
        fileList:[],
        changed:false
      },
      showTwo:{
        imageUrl:'',
        fileList:[],
        changed:false
      },
      showThree:{
        imageUrl:'',
        fileList:[],
        changed:false
      },
      showFour:{
        imageUrl:'',
        fileList:[],
        changed:false
      },
      mark:['one','two','three','four']
    }
  },
  methods:{
    //提交表单
    submitForm(formName) {
      this.ruleForm.cover = 1;
      this.ruleForm.show = 1;
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const formData = new FormData();
          formData.append("fname",this.ruleForm.fname);
          formData.append("cid",this.ruleForm.cid);
          formData.append("price",this.ruleForm.price);
          formData.append("fid",this.ruleForm.fid);

          if(this.cover.changed){
            this.cover.fileList.forEach((item) => {
              formData.append("cover", item.raw);
            });
          }
          if(this.showOne.changed){
            this.showOne.fileList.forEach((item) => {
              formData.append("one", item.raw);
            });
          }
          if(this.showTwo.changed){
            this.showTwo.fileList.forEach((item) => {
              formData.append("two", item.raw);
            });
          }
          if(this.showThree.changed){
            this.showThree.fileList.forEach((item) => {
              formData.append("three", item.raw);
            });
          }
          if(this.showFour.changed){
            this.showFour.fileList.forEach((item) => {
              formData.append("four", item.raw);
            });
          }

          let config = {
            headers:{'Content-Type':'multipart/form-data'}
          }
          this.axios.put(publicJS.methods.getBackPath()+"f/update",
            formData,config).then(res=>{
              if(res.data){
                this.$router.replace({path: '/managefurniture'});
              }
          })
        } else {
          return false;
        }
      });
    },
    //重置表单
    resetForm(formName) {
      this.getfurniture(this.$route.query.fid);
    },
    //封面图片上传前判断文件格式以及大小
    coverBeforeUpload(file){
      const isJPEG = file.type === 'image/jpeg';
      const isJPG = file.type === 'image/jpg';
      const isPNG = file.type === 'image/png';
      const isLt3M = file.size / 1024 / 1024 < 3;
      if(!(isJPEG||isJPG||isPNG)){
        this.$message.error("上传格式有误");
      }
      if(!isLt3M){
        this.$message.error('上传头像图片大小不能超过 3MB!');
      }
      return (isJPEG||isJPG||isPNG)&&isLt3M;
    },
    //封面图片上传图片改变事件
    coverOnChange(file,fileList){
      let existFile = fileList
        .slice(0, fileList.length - 1)
        .find((f) => f.name === file.name);
      if (existFile) {
        this.$message.error("当前文件已经存在!");
        fileList.pop();
      }
      this.cover.changed = true;
      this.cover.imageUrl = URL.createObjectURL(file.raw);
      this.cover.fileList = fileList;
    },

    //展示图片1上传前判断文件格式以及大小
    showOneBeforeUpload(file){
      const isJPEG = file.type === 'image/jpeg';
      const isJPG = file.type === 'image/jpg';
      const isPNG = file.type === 'image/png';
      const isLt3M = file.size / 1024 / 1024 < 3;
      if(!(isJPEG||isJPG||isPNG)){
        this.$message.error("上传格式有误");
      }
      if(!isLt3M){
        this.$message.error('上传头像图片大小不能超过 3MB!');
      }
      return (isJPEG||isJPG||isPNG)&&isLt3M;
    },
    //展示图片1上传图片改变事件
    showOneOnChange(file,fileList){
      let existFile = fileList
        .slice(0, fileList.length - 1)
        .find((f) => f.name === file.name);
      if (existFile) {
        this.$message.error("当前文件已经存在!");
        fileList.pop();
      }
      this.showOne.changed = true;
      this.showOne.imageUrl = URL.createObjectURL(file.raw);
      this.showOne.fileList = fileList;
    },

    //展示图片2上传前判断文件格式以及大小
    showTwoBeforeUpload(file){
      const isJPEG = file.type === 'image/jpeg';
      const isJPG = file.type === 'image/jpg';
      const isPNG = file.type === 'image/png';
      const isLt3M = file.size / 1024 / 1024 < 3;
      if(!(isJPEG||isJPG||isPNG)){
        this.$message.error("上传格式有误");
      }
      if(!isLt3M){
        this.$message.error('上传头像图片大小不能超过 3MB!');
      }
      return (isJPEG||isJPG||isPNG)&&isLt3M;
    },
    //展示图片2上传图片改变事件
    showTwoOnChange(file,fileList){
      let existFile = fileList
        .slice(0, fileList.length - 1)
        .find((f) => f.name === file.name);
      if (existFile) {
        this.$message.error("当前文件已经存在!");
        fileList.pop();
      }
      this.showTwo.changed = true;
      this.showTwo.imageUrl = URL.createObjectURL(file.raw);
      this.showTwo.fileList = fileList;
    },

    //展示图片3上传前判断文件格式以及大小
    showThreeBeforeUpload(file){
      const isJPEG = file.type === 'image/jpeg';
      const isJPG = file.type === 'image/jpg';
      const isPNG = file.type === 'image/png';
      const isLt3M = file.size / 1024 / 1024 < 3;
      if(!(isJPEG||isJPG||isPNG)){
        this.$message.error("上传格式有误");
      }
      if(!isLt3M){
        this.$message.error('上传头像图片大小不能超过 3MB!');
      }
      return (isJPEG||isJPG||isPNG)&&isLt3M;
    },
    //展示图片3上传图片改变事件
    showThreeOnChange(file,fileList){
      let existFile = fileList
        .slice(0, fileList.length - 1)
        .find((f) => f.name === file.name);
      if (existFile) {
        this.$message.error("当前文件已经存在!");
        fileList.pop();
      }
      this.showThree.changed = true;
      this.showThree.imageUrl = URL.createObjectURL(file.raw);
      this.showThree.fileList = fileList;
    },

    //展示图片4上传前判断文件格式以及大小
    showFourBeforeUpload(file){
      const isJPEG = file.type === 'image/jpeg';
      const isJPG = file.type === 'image/jpg';
      const isPNG = file.type === 'image/png';
      const isLt3M = file.size / 1024 / 1024 < 3;
      if(!(isJPEG||isJPG||isPNG)){
        this.$message.error("上传格式有误");
      }
      if(!isLt3M){
        this.$message.error('上传头像图片大小不能超过 3MB!');
      }
      return (isJPEG||isJPG||isPNG)&&isLt3M;
    },
    //展示图片4上传图片改变事件
    showFourOnChange(file,fileList){
      let existFile = fileList
        .slice(0, fileList.length - 1)
        .find((f) => f.name === file.name);
      if (existFile) {
        this.$message.error("当前文件已经存在!");
        fileList.pop();
      }
      this.showFour.changed = true;
      this.showFour.imageUrl = URL.createObjectURL(file.raw);
      this.showFour.fileList = fileList;
    },
    //获取所有分类
    getCategory:function (){
      this.axios.get(publicJS.methods.getBackPath()+"category/findAll").
      then(res=>{
        this.categorys = res.data;
      })
    },
    getfurnitureByUpdate(fid,fname){
      this.axios.get(publicJS.methods.getBackPath()+"f/findByFid/"+fid).
      then(res=>{
        var furniture = res.data;
        this.ruleForm.fname = furniture.fname;
        this.ruleForm.cid = furniture.cid;
        this.ruleForm.price = furniture.price;
        this.ruleForm.fid = furniture.fid;

        const image = furniture.image;
        const baseUrl = publicJS.methods.getBackPath()+"img/"+fname+"/";

        this.cover.fileList = [{name:'cover.jpg',url:baseUrl+image.cover}];
        this.cover.imageUrl = baseUrl+image.cover;

        this.showOne.fileList = [{name:'one.jpg',url:baseUrl+image.one}];
        this.showOne.imageUrl = baseUrl+image.one;

        this.showTwo.fileList = [{name:'two.jpg',url:baseUrl+image.two}];
        this.showTwo.imageUrl = baseUrl+image.two;

        this.showThree.fileList = [{name:'three.jpg',url:baseUrl+image.three}];
        this.showThree.imageUrl = baseUrl+image.three;

        this.showFour.fileList = [{name:'four.jpg',url:baseUrl+image.four}];
        this.showFour.imageUrl = baseUrl+image.four;
      })
    }
  },
  mounted() {
    this.getCategory();
    // this.getfurniture(this.$route.query.fid,this.$route.query.name);
  }
}
</script>

<style scoped>
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409EFF;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
</style>
