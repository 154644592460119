<template>
  <div class="main-container">
    <!-- Left Sidebar -->
    <PersonLeft></PersonLeft>

    <!-- Main Content Area -->
    <div class="main-content">
      <!-- File Upload Component -->
      <el-upload
          class="upload-demo"
          ref="upload"
          multiple
          :action="resUrl"
          :on-preview="handlePreview"
          :on-remove="handleRemove"
          :file-list="fileList"
          :auto-upload="false"
      >
        <!-- Trigger for selecting files -->
        <el-button slot="trigger" size="small" type="primary">选取文件</el-button>

        <!-- Button to upload the files to the server -->
        <el-button style="margin-left: 10px;" size="small" type="success" @click="submitUpload">上传到服务器</el-button>

        <!-- Upload Tip -->
        <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过100MB</div>
      </el-upload>

      <el-divider></el-divider>

      <!-- Displaying image resources -->
      <!-- Displaying image or video resources -->
      <el-row>
        <div style="display: inline-block;border: 1px black solid;margin-right: 20px;margin-bottom: 20px" v-for="item in resData" :key="item.id">
          <!-- Display image if it's an image -->
          <el-image
              v-if="isImage(item.url)"
              fit="contain"
              :src="item.url"
              style="width: 300px;height: 300px;"
          ></el-image>
          <!-- Display video if it's a video -->
          <video
              v-else-if="isVideo(item.url)"
              controls
              :src="item.url"
              style="width: 300px;height: 300px;"
          ></video>
          <!-- Display a tag for unsupported types -->
          <el-tag v-else>不支持的文件类型: {{ item.name }}</el-tag>
          <!-- Display resource name -->
          <el-row><el-tag>{{ item.name }}</el-tag></el-row>
        </div>
      </el-row>
    </div>
  </div>
</template>


<script>
import PersonLeft from "./PersonLeft";
import publicJS from "../assets/js/public";

export default {
  name: "Material",
  components: {PersonLeft},
  data() {

    return {
      user: {}, fileList: [], resUrl: '', resData: [],
    }
  },
  methods: {
    isImage(url) {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
      const ext = url.toLowerCase().slice(url.lastIndexOf('.'));
      return imageExtensions.includes(ext);
    },

    // 判断是否为视频
    isVideo(url) {
      const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov', '.avi'];
      const ext = url.toLowerCase().slice(url.lastIndexOf('.'));
      return videoExtensions.includes(ext);
    },
    getUserInfo() {
      this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo").then(res => {
        this.user = res.data;
        this.updateUser = res.data;
        this.resUrl = publicJS.methods.getBackPath() + "upload/" + this.user.uid + "/0";
      });
    },
    submitUpload() {
      this.$refs.upload.submit();
      this.$message({
        type: 'info',
        message: '上传成功'
      });
      this.queryRes();
    },
    handleRemove(file, fileList) {
      console.log(file, fileList);
    },
    handlePreview(file) {
      console.log(file);
    },
    queryRes() {
      this.axios.get(publicJS.methods.getBackPath() + "currentUserRes").then(res => {
        this.resData = res.data;
      });
    },
  },
  mounted() {
    this.getUserInfo();
    this.queryRes();
  }
}
</script>

<style scoped>
/* Container for the left sidebar and the main content */
.main-container {
  display: flex;
  padding: 20px;
}

/* Main content area */
.main-content {
  flex-grow: 1;
  padding-left: 20px;
  background-color: #f9fafb;
  border-radius: 10px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

/* Style for the upload component */
.upload-demo {
  margin-bottom: 20px;
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.el-upload__tip {
  font-size: 14px;
  color: #b5b5b5;
  margin-top: 10px;
}

/* Style for the image resource display */
.el-row {
  margin-top: 20px;
}

.el-image {
  border: 1px solid #dcdfe6;
  border-radius: 6px;
  padding: 5px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
}

.el-tag {
  font-size: 14px;
  margin-top: 10px;
}

.el-divider {
  margin: 20px 0;
}

/* Style for the images display section */
.el-row > div {
  display: inline-block;
  border: 1px solid #dcdfe6;
  border-radius: 8px;
  margin-right: 20px;
  margin-bottom: 20px;
  text-align: center;
  overflow: hidden;
  width: 300px;
}

.el-image img {
  width: 100%;
  height: auto;
}

/* Style for the buttons in the upload section */
.el-button {
  font-size: 14px;
  margin-right: 10px;
}

.el-button:hover {
  background-color: #409EFF;
  color: #fff;
}

/* Style for the tag in the images section */
.el-tag {
  margin-top: 10px;
  display: block;
  font-size: 12px;
  color: #409EFF;
}

/* General page structure and layout */
.el-row {
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
}

</style>
