import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import Element from 'element-ui';
import '@/assets/scss/reset.scss';

import jQuery from 'jquery';
import 'summernote/dist/summernote-lite.min.css'; // 引入 Summernote 的 Lite 版样式
import 'summernote/dist/summernote-lite.min.js'; // 引入 Summernote 的 Lite 版 JS
// 将 jQuery 添加到全局 window 对象
window.$ = window.jQuery = jQuery;

Vue.use(Element);

// axios
import axios from 'axios';
Vue.prototype.axios = axios;
axios.defaults.withCredentials = true; // 允许跨域携带 cookie

// 发 post 请求用到的 qs
import qs from 'qs';
Vue.prototype.$qs = qs;

// 全局挂载 VueQuillEditor
import VueQuillEditor from 'vue-quill-editor';
import 'quill/dist/quill.core.css';
import 'quill/dist/quill.snow.css';
import 'quill/dist/quill.bubble.css';
import * as Emoji from 'quill-emoji';
import Quill from 'quill';
import 'quill-emoji/dist/quill-emoji.css';
Vue.use(VueQuillEditor);
Quill.register('modules/emoji', Emoji); // 将 emoji 插件注册进富文本

// 引入 echarts
import * as echarts from 'echarts';
Vue.prototype.$echarts = echarts;

// 引入 Summernote 全局配置工具
import { initSummernote, destroySummernote, setSummernoteContent, getSummernoteContent, initReadOnlySummernote } from '@/utils/summernote';

// 注册 Summernote 全局方法到 Vue 原型
Vue.prototype.$initSummernote = initSummernote;
Vue.prototype.$destroySummernote = destroySummernote;
Vue.prototype.$setSummernoteContent = setSummernoteContent;
Vue.prototype.$getSummernoteContent = getSummernoteContent;
Vue.prototype.$initReadOnlySummernote = initReadOnlySummernote;

Vue.config.productionTip = false;

import { getIndexStyle } from '@/tools/theme/index';

/* 初始化主题样式 */
getIndexStyle().then(() => {
  store.dispatch('changeThemeStyle');
});

new Vue({
  router,
  store,
  render: (h) => h(App),
}).$mount('#app');