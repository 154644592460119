<template>
  <div class="theme-picker-container">
    <el-color-picker
        v-model="themeColor"
        :predefine="themeList"
        @change="handleChangeColor"
        class="u-theme-picker"
    />
  </div>
</template>

<script>
import { mapMutations, mapActions } from 'vuex';
import store from '@/store';

export default {
  name: 'ThemePicker',
  data() {
    return {
      themeColor: store.state.themeColor,
      themeList: [
        '#409EFF', '#3C6CFE', '#00AE8A', '#30BDF4', '#6772E5',
        '#FB6B01', '#353D64', '#7A40F2'
      ]
    };
  },
  methods: {
    ...mapMutations(['setTheme']),
    ...mapActions(['changeThemeStyle']),
    handleChangeColor(val) {
      this.setTheme(val);
      this.changeThemeStyle();
    }
  }
};
</script>

<style scoped lang="scss">


.theme-picker-container .el-color-picker {
  transition: all 0.3s ease-in-out;
}

.theme-picker-container .el-color-picker:hover {
  transform: scale(1.05);
}

.theme-picker-container .el-color-picker .el-input__inner {
  padding: 0;
  border-radius: 6px;
  background-color: #f4f7fc;
}

.theme-picker-container .el-color-picker .el-input__inner:focus {
  border-color: #409EFF;
}

.theme-picker-container .el-color-picker .el-input__inner:disabled {
  background-color: #e4e7ed;
}
</style>
