<template>
  <div style="display: flex; flex-wrap: wrap; justify-content: space-between; gap: 20px;">
    <div id="gender" :style="{ width: '100%', maxWidth: '500px', height: '300px' }"></div>
    <div id="res" :style="{ width: '100%', maxWidth: '500px', height: '300px' }"></div>
    <div id="dis" :style="{ width: '100%', maxWidth: '500px', height: '300px' }"></div>
  </div>
</template>

<script>
import publicJS from "../assets/js/public";
import LeftSide from "./LeftSide";

export default {
  name: "Charts2",
  components: { LeftSide },
  data() {
    return {
      shopInfo: {},
      genderOption: {
        title: {
          text: "性别分布"
        },
        tooltip: {},
        legend: {
          data: ['男', '女']
        },
        xAxis: {
          data: ['男', '女']
        },
        yAxis: {},
        series: [
          {
            name: '性别',
            type: 'bar',
            data: []
          }
        ]
      },
      resOption: {
        title: {
          text: "预约统计"
        },
        tooltip: {},
        legend: {
          data: ['预约']
        },
        xAxis: {
          data: []
        },
        yAxis: {},
        series: [
          {
            name: '预约',
            type: 'bar',
            data: []
          }
        ]
      },
      disOption: {
        title: {
          text: "折扣分布"
        },
        tooltip: {},
        legend: {
          data: ['折扣']
        },
        xAxis: {
          data: []
        },
        yAxis: {},
        series: [
          {
            name: '折扣',
            type: 'bar',
            data: []
          }
        ]
      }
    }

  },
  methods: {
    // 获取商品信息
    loadShopInfo() {
      this.axios.get(publicJS.methods.getBackPath() + "shop/getCurrent").then(res => {
        this.shopInfo = res.data;
        this.getUserChart();
      })
    },
    drawGender() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('gender'));
      // 绘制图表
      myChart.setOption(this.genderOption);
    },
    drawRes() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('res'));
      // 绘制图表
      myChart.setOption(this.resOption);
    },
    drawDis() {
      // 基于准备好的dom，初始化echarts实例
      let myChart = this.$echarts.init(document.getElementById('dis'));
      // 绘制图表
      myChart.setOption(this.disOption);
    },
    setOptions(option, data) {
      option.title.text = data.name;
      option.legend.data = [data.name];
      option.series[0].name = data.name;
      option.xAxis.data = data.nameList;
      option.series[0].data = data.dateLst;
    },
    getUserChart() {
      this.axios.get(publicJS.methods.getBackPath() + "order/echart/" + this.shopInfo.id).then(res => {
        var data1 = res.data;

        this.setOptions(this.genderOption, data1[0]);
        this.setOptions(this.resOption, data1[1]);
        this.setOptions(this.disOption, data1[2]);

        this.drawGender();
        this.drawRes();
        this.drawDis();
      })
    }
  },
  mounted() {
    this.loadShopInfo();
    // 绘制初始的图表
    this.drawGender();
    this.drawRes();
    this.drawDis();
  }
}
</script>

<style scoped>
#gender, #res, #dis {
  margin-bottom: 20px;
}

@media (max-width: 1024px) {
  #gender, #res, #dis {
    width: 100%; /* 屏幕小于1024px时，每个图表占满一行 */
  }
}

@media (max-width: 768px) {
  #gender, #res, #dis {
    width: 100%; /* 屏幕小于768px时，图表仍然每行占满 */
  }
}
</style>
