// src/utils/summernote.js

// 默认配置
const defaultOptions = {
    height: 300,
    placeholder: '请输入内容...',
    lang: 'zh-CN', // 设置为中文（需确保 Summernote Lite 支持）
    toolbar: [
        ['style', ['bold', 'italic', 'underline', 'clear']],
        ['font', ['strikethrough', 'superscript', 'subscript']],
        ['fontsize', ['fontsize']],
        ['color', ['color']],
        ['para', ['ul', 'ol', 'paragraph']],
        ['height', ['height']],
        ['insert', ['link', 'picture']],
        ['view', ['fullscreen', 'codeview']],
    ],
    callbacks: {
        onChange: null, // 默认回调为空，允许组件自定义
    },
};

// 初始化 Summernote 的全局方法
export const initSummernote = (element, options = {}) => {
    const $editor = $(element);
    // 防止重复初始化
    if ($editor.hasClass('summernote')) {
        $editor.summernote('destroy');
    }
    // 合并默认配置和自定义配置
    const mergedOptions = {
        ...defaultOptions,
        ...options,
        callbacks: {
            ...defaultOptions.callbacks,
            ...options.callbacks,
        },
    };
    $editor.summernote(mergedOptions);
    return $editor; // 返回 jQuery 对象以便后续操作
};

// 销毁 Summernote 的全局方法
export const destroySummernote = (element) => {
    const $editor = $(element);
    if ($editor.hasClass('summernote')) {
        $editor.summernote('destroy');
    }
};

// 设置内容的全局方法
export const setSummernoteContent = (element, content) => {
    const $editor = $(element);
    $editor.summernote('code', content);
};

// 获取内容的全局方法
export const getSummernoteContent = (element) => {
    const $editor = $(element);
    return $editor.summernote('code');
};

// 只读模式的全局方法
export const initReadOnlySummernote = (element, content) => {
    const $editor = $(element);
    if ($editor.hasClass('summernote')) {
        $editor.summernote('destroy');
    }
    $editor.summernote({
        height: 300,
        toolbar: [], // 无工具栏
        disableResizeEditor: true,
    });
    $editor.summernote('code', content);
    $editor.summernote('disable'); // 设置为只读
};