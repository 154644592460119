<template>
  <el-container>
    <!--头部-->
    <Header></Header>
    <el-main>

      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/' }">
          <el-tag>
            首页
          </el-tag>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <el-tag>{{ furniture.category.cname }}</el-tag>
        </el-breadcrumb-item>
        <el-breadcrumb-item>
          <el-tag>{{ furniture.fname }}</el-tag>
        </el-breadcrumb-item>
      </el-breadcrumb>

      <div class="tb-item-info tb-clear">
        <el-image :src="furniture.image.cover" class="tb-item-info-l">
        </el-image>

        <div class="tb-item-info-r">
          <div class="tb-property tb-property-x">
            <div class="tb-wrap tb-wrap-newshop">
              <div id="J_Title" class="tb-title">
                <h3 class="tb-main-title">{{ furniture.fname }}</h3>
              </div>

              <ul class="tb-meta tb-promo-meta">
                <li v-if="furniture.type==1" id="J_PromoPrice" class="tb-detail-price tb-promo-price tb-clear">
                  <span class="tb-property-type">价格</span>
                  <div class="tb-property-cont">
                    <div id="J_Promo" class="tb-promo-mod">
                      <div class="tb-promo-item-bd"><strong class="tb-promo-price"><em class="tb-rmb">¥</em><em
                          id="J_PromoPriceNum" class="tb-rmb-num">{{ furniture.price }}</em></strong>
                      </div>
                    </div>
                  </div>
                </li>

                <li id="J_OtherDiscount" class="tb-clear">
                  <span class="tb-property-type">发布人</span>
                  <div class="tb-other-discount">
                    <div class="tb-other-discount-content">
                      {{ furniture.userName }}
                    </div>
                  </div>
                </li>

                <li id="J_OtherDiscount" class="tb-clear">
                  <span class="tb-property-type">发布时间</span>
                  <div class="tb-other-discount">
                    <div class="tb-other-discount-content">
                      {{ new Date(furniture.date).toLocaleString() }}
                    </div>
                  </div>
                </li>

                <li id="J_OtherDiscount" class="tb-clear">
                  <span class="tb-property-type">分类名称</span>
                  <div class="tb-other-discount">
                    <div class="tb-other-discount-content">
                      {{ furniture.category.cname }}
                    </div>
                  </div>
                </li>

                <li id="J_OtherDiscount" class="tb-clear">
                  <span class="tb-property-type">浏览数量</span>
                  <div class="tb-other-discount">
                    <div class="tb-other-discount-content">
                      {{ furniture.views }}
                    </div>
                  </div>
                </li>

                <li id="J_OtherDiscount" class="tb-clear">
                  <span class="tb-property-type">作者地址</span>
                  <div class="tb-other-discount">
                    <div class="tb-other-discount-content">
                      {{ furniture.address }}
                    </div>
                  </div>
                </li>

                <li v-if="user.role!=0 && user.uid!=furniture.shop.uid && furniture.type==1 && !isUserBuy" class="tb-clear">
                  <span class="tb-property-type">数量</span>
                  <div class="tb-other-discount">
                    <div class="tb-other-discount-content">
                      <el-input-number v-model="shopcar.count" :min="1" :max="10" label="描述文字"></el-input-number>
                      <el-button type="primary" @click="addShopCar">添加购物车</el-button>
                    </div>
                  </div>
                </li>

                <li v-if="isUserBuy" class="tb-clear">
                  <span class="tb-property-type">购买状态</span>
                  <div class="tb-other-discount">
                    <div class="tb-other-discount-content">您已购买过该商品</div>
                  </div>
                </li>

                <li v-if="user.role==0 && furniture.type==1" class="tb-clear">
                  <span class="tb-property-type">管理员不能购买小说商品</span>
                </li>

                <li v-if="user.uid==furniture.shop.uid && furniture.type==1" class="tb-clear">
                  <span class="tb-property-type">您不能购买自己的小说商品</span>
                </li>

                <li v-else class="tb-clear">
                  <span class="tb-property-type">收藏</span>
                  <div class="tb-other-discount">
                    <div class="tb-other-discount-content">
                      <el-button type="primary" @click="addAttention">收藏</el-button>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </el-main>

    <el-footer>
      <el-tabs style="width: 750px; margin: 0 auto;">
        <el-tab-pane label="小说介绍">
          <div v-for="url in furniture.imageLists" :key="url.id" style="margin-bottom: 20px;">
            <!-- 判断是否为图片 -->
            <el-image
                v-if="isImage(url.url)"
                align="absmiddle"
                style="max-width: 750px;"
                :src="url.url"
                fit="contain"
            ></el-image>
            <!-- 判断是否为视频 -->
            <video
                v-else-if="isVideo(url.url)"
                controls
                style="max-width: 750px;"
                :src="url.url"
            ></video>
            <!-- 可选：处理未知类型 -->
            <el-tag v-else>不支持的文件类型: {{ url.url }}</el-tag>
          </div>
        </el-tab-pane>

        <el-tab-pane label="评论">
          <el-form :model="commentForm" label-width="0px">
            <el-form-item label="">
              <el-input v-model="commentForm.content"></el-input>
            </el-form-item>
            <el-form-item>
              <el-button class="detail-btn" type="primary" @click="onSubmit">评论</el-button>
            </el-form-item>

            <el-row v-for="item in comment" :key="item.cid">
              <el-row class="tb-tbcr-content"> 用户{{ item.user.uname }}</el-row>
              <el-row class="tb-tbcr-content"> {{ item.content }}</el-row>
              <el-row class="tb-r-date">{{ item.ctime }}</el-row>
              <el-divider></el-divider>
            </el-row>
          </el-form>
        </el-tab-pane>

        <el-tab-pane label="章节">
          <el-row v-for="item in tableData" :key="item.id">
            <el-row>
              <el-button type="success" @click="showContent(item.content)"
                         v-if="item.type==0 || user.role==0 || user.uid==furniture.uid || isUserBuy">
                查看内容
              </el-button>
              <el-tag v-else-if="item.type==1 && user.role!=0 && user.uid!=furniture.uid">
                付费内容购买后可阅读
              </el-tag>
              章节名称: {{ item.name }}
            </el-row>
            <el-divider></el-divider>
          </el-row>
        </el-tab-pane>
      </el-tabs>

      <el-dialog title="查看内容" :visible.sync="dialogVisible2" width="50%" @close="destroyContentViewer">
        <div ref="contentViewer" class="summernote"></div>
        <span slot="footer" class="dialog-footer">
    <el-button type="primary" @click="dialogVisible2 = false">确 定</el-button>
  </span>
      </el-dialog>
    </el-footer>
  </el-container>
</template>

<style>
.text {
  font-size: 14px;
}

.item {
  padding: 18px 0;
}

.box-card {
  width: 480px;
}
</style>
<script>
import publicJS from "../assets/js/public";
import Header from "./Header";
import Footer from "./Footer";
import $ from 'jquery'; // 导入 jQuery
import 'summernote/dist/summernote-bs4.min.css'; // 导入 Summernote CSS
import 'summernote/dist/summernote-bs4.min.js'; // 导入 Summernote JS

export default {
  name: "Detail",
  components: {Footer, Header},
  data() {
    return {
      user: {},
      shopcar: {
        count: 0
      }, form: {
        name: '',
        desption: '',

      },
      imgSrc: '', content: '',
      dialogVisible: false,
      furniture: {},
      urlArray: [],
      comment: [],//评论对象
      commentForm: {
        content: '',
        TiLength: 0,//当前字数
        MaxLength: 100,//最大字数
        replyCid: 0,//当前要回复的评论id
      }, tableData: [], dialogVisible2: false, isBuy: false, isUserBuy: false,
    }
  },
  methods: {
    // 判断是否为图片
    isImage(url) {
      const imageExtensions = ['.jpg', '.jpeg', '.png', '.gif', '.bmp', '.webp'];
      const ext = url.toLowerCase().slice(url.lastIndexOf('.'));
      return imageExtensions.includes(ext);
    },

    // 判断是否为视频
    isVideo(url) {
      const videoExtensions = ['.mp4', '.webm', '.ogg', '.mov', '.avi'];
      const ext = url.toLowerCase().slice(url.lastIndexOf('.'));
      return videoExtensions.includes(ext);
    },
    contentAuthor() {

    },
    //获取商品信息
    getfurniture(fid, fname) {
      this.axios.get(publicJS.methods.getBackPath() + "f/findByFid/" + fid).then(res => {
        this.furniture = res.data;
        // 如果 imageLists 是 JSON 字符串，则解析
        if (typeof this.furniture.imageLists === 'string') {
          this.furniture.imageLists = JSON.parse(this.furniture.imageLists);
        }
      });
    },
    getUserInfo() {
      this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo").then(res => {
        this.user = res.data;
      })
    },//添加收藏
    async addAttention() {
      const res = await this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo");
      const user = res.data;
      if (!user) {
        this.$message.error("请您登录后收藏");
        return;
      }
      const formData = new FormData();

      formData.append("shoppingId", this.$route.query.fid);
      formData.append("userId", user.uid);
      formData.append("englishName", this.furniture.category.englishName);
      formData.append("shopId", this.furniture.shop.id);

      formData.append("name", this.furniture.fname);
      formData.append("salemanId", this.furniture.uid);

      const flag = await this.axios.post(publicJS.methods.getBackPath() + "attontion/addAttontionRecord", formData);
      if (flag) {
        this.$message.success("添加成功");
        this.shopcar.count = 0;
      } else {
        this.$message.error("添加失败");
      }
    },
//添加购物车
    async addShopCar() {
      const res = await this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo");
      const user = res.data;
      if (!user) {
        this.$message.error("请您登录后加入购物车");
        return;
      }
      const formData = new FormData();
      formData.append("count", this.shopcar.count);
      formData.append("fid", this.$route.query.fid);
      formData.append("uid", user.uid);
      formData.append("shopId", this.furniture.shop.id);
      formData.append("salemanId", this.furniture.uid);

      const flag = await this.axios.post(publicJS.methods.getBackPath() + "shopcar/addShopCar", formData);
      if (flag) {
        this.$message.success("添加成功");
        this.shopcar.count = 0;
      } else {
        this.$message.error("添加失败");
      }
    }
    ,
    getComment(fid) {
      this.axios.get(publicJS.methods.getBackPath() + "comment/findByFid/" + fid).then(res => {
        this.comment = res.data;
      })
    }
    ,
// 内容改变事件
    onEditorChange(e) {
      console.log(this.commentForm.content);
      //控制输入字数
      if (e.quill.getLength() - 1 > this.commentForm.MaxLength) {
        this.$message.error("最多输入" + this.commentForm.MaxLength + "字");
      }
      e.quill.deleteText(this.commentForm.MaxLength, 4);
      if (this.commentForm.content == '') {
        this.commentForm.TiLength = 0
      } else {
        this.commentForm.TiLength = e.quill.getLength() - 1
      }
    }
    ,
//回复按钮
    replay(comment) {
      this.commentForm.content = "";
      console.log(comment);
      this.commentForm.replyCid = comment.cid;
      const editor = document.getElementsByClassName("ql-editor");
      editor[0].dataset.placeholder = "@" + comment.user.uname + ":";
    }
    ,
//取消回复按钮
    cancel() {
      this.commentForm.content = "";
      this.commentForm.replyCid = 0;
      const editor = document.getElementsByClassName("ql-editor");
      editor[0].dataset.placeholder = this.editorOption.placeholder;
    }
    ,
    delComment(cid) {
      this.$confirm('此操作将永久删除评论, 是否继续?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        this.axios.delete(publicJS.methods.getBackPath() + "comment/del/" + cid).then(res => {
          if (res.data) {
            this.$message.success("删除成功");
            location.reload();
          } else {
            this.$message.success("操作失败,请联系管理员");
          }
        })
      }).catch(() => {
      });
    }
    ,
    onSubmit2() {
      this.axios.post(publicJS.methods.getBackPath() + "complaint/add", {
        "name": this.form.name,
        "desption": this.form.desption,
        "userId": this.user.uid,
        "type": 0,
        "userName": this.user.uname,
        "shopId": this.furniture.shop.id,
        "shopName": this.furniture.shop.name,
        "complaintType": 1,
        "shoppingName": this.furniture.fname,
        "shoppingId": this.furniture.fid,
      }).then(res => {
        this.$message.success("投诉成功");
        this.dialogVisible = false;
      })

    }
    , showContent(content) {
      this.content = content;
      this.dialogVisible2 = true;
      this.$nextTick(() => {
        this.initContentViewer(content);
      });
    },// 初始化 Summernote 查看器（只读模式）
    initContentViewer(content) {
      const $viewer = $(this.$refs.contentViewer);
      if ($viewer.hasClass('summernote')) {
        $viewer.summernote('destroy'); // 防止重复初始化
      }
      $viewer.summernote({
        height: 300,
        toolbar: [], // 移除工具栏实现只读效果
        disableResizeEditor: true,
      });
      $viewer.summernote('code', content); // 设置内容
      $viewer.summernote('disable'); // 设置为只读
    }, // 销毁 Summernote 查看器
    destroyContentViewer() {
      const $viewer = $(this.$refs.contentViewer);
      if ($viewer.hasClass('summernote')) {
        $viewer.summernote('destroy');
      }
    },getNovels(fid) {
      this.axios.get(publicJS.methods.getBackPath() + "content/queryByTypeIdSort/" + fid,).then(res => {
        this.tableData = res.data;
        this.tableData.forEach(function (e) {
          if (e.type == 1) {
            this.isBuy = true;
          }
        })

      })
    },
//提交评论
    async onSubmit() {
      if (this.commentForm.content === "") {
        this.$message.error("评论内容不能为空");
        return;
      }
      var res = await this.axios.get(publicJS.methods.getBackPath() + "user/getUserInfo");
      var user = res.data;
      if (!user) {
        this.$message.error("请您登录后再评论");
      }
      const formData = new FormData();
      formData.append("content", this.commentForm.content);
      formData.append("fid", this.$route.query.fid);
      formData.append("uid", user.uid);
      formData.append("ctime", publicJS.methods.getTime());
      formData.append("replayCid", this.commentForm.replyCid);


      this.axios.post(publicJS.methods.getBackPath() + "comment/insert", formData).then(res => {
        if (res.data) {
          this.$message.success("评论成功");
          location.reload();
        } else {
          this.$message.error("评论失败,请联系站长");
        }
      })
    },
    getIsBuy(fid) {

      this.axios.get(publicJS.methods.getBackPath() + "f/isBuy/" + fid).then(res => {
        this.isUserBuy = res.data;
      })
    }
  },
  mounted() {
    var fid = this.$route.query.fid;
    var fname = this.$route.query.name;
    this.getfurniture(fid, fname);
    this.getComment(fid);
    this.getNovels(fid);
    this.getUserInfo();
    this.getIsBuy(fid);
  },

}
</script>

<style scoped>
/* 基本字体设置 */
.text {
  font-size: 14px;
  color: #333;
}

/* 卡片样式 */
.item {
  padding: 18px 0;
  border-bottom: 1px solid #E8E8E8;
}

/* 商品详情的整体样式 */
/* 商品详情的整体样式 */
.tb-item-info {
  position: relative;
  margin: 20px auto;
  width: 1140px;
  background-color: #FFF;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 16px;
  overflow: hidden;
  padding: 20px;
  display: flex; /* 使用flex布局 */
  align-items: flex-start; /* 垂直方向对齐 */
}

/* 商品图片区域 */
.tb-item-info .tb-item-info-l {
  padding: 20px;
  width: 400px;
  height: 400px;
  object-fit: cover;
  border-radius: 12px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.tb-item-info .tb-item-info-l:hover {
  transform: scale(1.05);
}

/* 商品信息区域 */
.tb-item-info .tb-item-info-r {
  padding: 20px;
  width: calc(100% - 440px); /* 剩余空间 */
  background-color: #F9F9F9;
  border-radius: 12px;
  display: flex;
  flex-direction: column; /* 确保内容垂直排列 */
  gap: 12px; /* 增加内容间的间距 */
}

/* 修改商品信息列表 */
.tb-meta {
  list-style-type: none;
  padding: 0;
  margin: 0;
}

/* 每个列表项 */
.tb-meta li {
  margin-bottom: 12px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* 商品信息属性标题 */
.tb-property-type {
  font-size: 16px;
  color: #6c6c6c;
}

/* 商品信息属性内容 */
.tb-property-cont {
  font-size: 18px;
  color: #FF5000;
}

.tb-detail-price {
  font-size: 24px;
  font-weight: bold;
  color: #FF5000;
}

.tb-meta {
  margin-top: 20px;
}

.tb-property-x .tb-meta li {
  margin-bottom: 12px;
  font-size: 16px;
  line-height: 1.5;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.tb-other-discount-content {
  color: #333;
  font-size: 16px;
  padding: 8px 0;
  white-space: normal; /* 允许文本换行 */
  word-wrap: break-word; /* 强制长单词换行 */
  word-break: break-all; /* 强制长词分割换行 */
}


/* 输入框和按钮 */
.el-input-number {
  width: 120px;
}

.el-button {
  margin-left: 10px;
  background-color: #FF5000;
  border-color: #FF5000;
  color: white;
}

.el-button:hover {
  background-color: #FF6F00;
  border-color: #FF6F00;
}

/* 收藏按钮 */
.tb-other-discount .tb-other-discount-content {
  margin-top: 10px;
}

.detail-btn {
  padding: 10px 15px;
  background-color: #FF5000;
  color: white;
  border-radius: 18px;
  font-size: 14px;
  text-align: center;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.detail-btn:hover {
  background-color: #FF6F00;
}

/* Tab 和章节内容 */
.el-tabs {
  margin-top: 30px;
}

.el-tabs__header {
  border-bottom: 2px solid #E8E8E8;
}

.el-tab-pane {
  padding: 20px;
}

.el-row {
  font-size: 16px;
  line-height: 1.5;
}

.el-row .tb-tbcr-content {
  margin-top: 8px;
  color: #333;
}

.el-row .tb-r-date {
  font-size: 14px;
  color: #B0B0B0;
}

/* 章节按钮 */
.el-button.success {
  background-color: #4CAF50;
  color: white;
  border-radius: 4px;
  padding: 8px 12px;
}

.el-button.success:hover {
  background-color: #45a049;
}

.el-tag {
  font-size: 14px;
  color: #FF5000;
  border-color: #FF5000;
}

.el-tag:hover {
  background-color: #FF5000;
  color: white;
}

/* 对话框 */
.el-dialog {
  border-radius: 16px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.el-dialog__header {
  background-color: #FF5000;
  color: white;
  border-radius: 16px 16px 0 0;
  padding: 15px;
  font-size: 18px;
}

.el-dialog__body {
  font-size: 16px;
  color: #333;
}

/* 响应式设计 */
@media (max-width: 1200px) {
  .tb-item-info {
    width: 95%;
  }
  .tb-item-info .tb-item-info-l, .tb-item-info .tb-item-info-r {
    width: 100%;
    float: none;
    padding: 20px;
  }
}

@media (max-width: 768px) {
  .tb-item-info {
    width: 100%;
    padding: 15px;
  }
  .tb-item-info .tb-item-info-l {
    width: 100%;
    height: auto;
  }
  .tb-item-info .tb-item-info-r {
    width: 100%;
    margin-top: 20px;
  }
}
.summernote {
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  background-color: #fff;
  padding: 10px;
}

/* 章节内容查看器样式 */
.el-dialog .summernote {
  min-height: 200px;
}
</style>
