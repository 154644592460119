<template>
  <div class="refresh-page">
    <!-- Loading Indicator (可选) -->
    <div class="loading-container" v-if="isLoading">
      <el-spinner size="50" />
      <p>正在刷新...</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Refresh",
  data() {
    return {
      isLoading: true, // 控制页面加载状态
    };
  },
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$router.replace(from.path); // 路由返回原路径
      vm.isLoading = false; // 加载完成后隐藏加载动画
    });
  },
  mounted() {
    // 模拟加载动画消失
    setTimeout(() => {
      this.isLoading = false;
    }, 500); // 500毫秒后隐藏加载动画
  },
};
</script>

<style scoped>
.refresh-page {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
  position: relative;
  font-family: 'Arial', sans-serif;
}

/* Loading 样式 */
.loading-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 10px;
  padding: 30px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  transition: opacity 0.3s ease;
}

/* 可选：自定义加载文字 */
.loading-container p {
  margin-top: 15px;
  font-size: 18px;
  color: #409eff;
  font-weight: bold;
}

/* 响应式设计：确保在小屏幕设备上的兼容 */
@media (max-width: 768px) {
  .loading-container {
    padding: 20px;
    width: 80%;
  }
}

@media (max-width: 480px) {
  .loading-container {
    padding: 15px;
    width: 90%;
  }
}
</style>
