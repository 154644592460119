<template>
  <div class="register-container">
    <div class="form-wrapper">
      <el-form :model="userForm" status-icon :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
        <!-- 用户名 -->
        <el-form-item label="用户名" prop="uname">
          <el-input type="text" v-model="userForm.uname" autocomplete="off" placeholder="请输入用户名"></el-input>
        </el-form-item>

        <!-- 密码 -->
        <el-form-item label="密码" prop="passwd">
          <el-input type="password" v-model="userForm.passwd" autocomplete="off" placeholder="请输入密码"></el-input>
        </el-form-item>

        <!-- 重复密码 -->
        <el-form-item label="重复密码" prop="passwd2">
          <el-input type="password" v-model="userForm.passwd2" autocomplete="off" placeholder="再次输入密码"></el-input>
        </el-form-item>

        <!-- 性别 -->
        <el-form-item label="性别">
          <el-radio v-model="userForm.gender" label="1">男</el-radio>
          <el-radio v-model="userForm.gender" label="0">女</el-radio>
        </el-form-item>

        <!-- 出生日期 -->
        <el-form-item label="出生日期" prop="birthday">
          <el-col :span="11">
            <el-date-picker type="date" value-format="yyyy-MM-dd" placeholder="选择出生日期" v-model="userForm.birthday" style="width: 100%;"></el-date-picker>
          </el-col>
        </el-form-item>

        <!-- 手机号码 -->
        <el-form-item label="手机号码" prop="phoneNum">
          <el-input type="text" v-model="userForm.phoneNum" autocomplete="off" placeholder="请输入手机号码"></el-input>
        </el-form-item>

        <!-- 居住地 -->
        <el-form-item label="居住地">
          <el-select v-model="userForm.city" placeholder="请选择居住地">
            <el-option v-for="item in cities" :key="item.value" :label="item.label" :value="item.value">
              <span>{{ item.label }}</span>
              <span style="float: right; color: #8492a6; font-size: 13px">{{ item.value }}</span>
            </el-option>
          </el-select>
        </el-form-item>

        <!-- 联系地址 -->
        <el-form-item label="联系地址" prop="address">
          <el-input type="textarea" v-model="userForm.address" placeholder="请输入联系地址"></el-input>
        </el-form-item>

        <!-- 验证码 -->
        <el-form-item label="验证码" prop="code">
          <el-image style="float: left;width: 30%" :src="codeSrc" @click="changeCode"></el-image>
          <el-input style="float: right;width: 65%" type="text" v-model="userForm.code" autocomplete="off" placeholder="请输入验证码"></el-input>
        </el-form-item>

        <!-- 提交按钮 -->
        <el-form-item>
          <el-button type="primary" @click="onSubmit('ruleForm')" style="width: 100%;">注册</el-button>
        </el-form-item>
      </el-form>
    </div>
  </div>
</template>

<script>
import publicJS from "../assets/js/public";

export default {
  name: "Register",
  data() {
    var checkUserName = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('用户名不能为空'));
      } else {
        this.axios.get(publicJS.methods.getBackPath() + "user/checkUname", {
          params: {
            uname: value
          }
        }).then(Response => {
          if (Response.data) {
            callback();
          } else {
            callback(new Error('用户名已存在！'));
          }
        });
      }
    };

    var checkPasswd2 = (rule, value, callback) => {
      if (!value) {
        return callback(new Error("请再次输入密码"));
      } else if (this.userForm.passwd !== value) {
        callback(new Error("两次密码不一致"));
      } else {
        callback();
      }
    };

    return {
      codeSrc: '',
      cities: [
        { value: 'Beijing', label: '北京市' },
        { value: 'Changchun', label: '长春市' },
        { value: 'Chengdu', label: '成都市' },
        { value: 'Changsha', label: '长沙市' },
        { value: 'Chongqing', label: '重庆市' },
        { value: 'Fujian', label: '福建市' },
        { value: 'Guiyang', label: '贵阳市' },
        { value: 'Guangzhou', label: '广州市' },
        { value: 'Harbin', label: '哈尔滨市' },
        { value: 'HeFei', label: '合肥市' },
        { value: 'Huhhot', label: '呼和浩特市' },
        { value: 'HaiKou', label: '海口市' },
        { value: 'Hangzhou', label: '杭州市' },
        { value: 'Jinan', label: '济南市' },
        { value: 'Kunming', label: '昆明市' },
        { value: 'Lanzhou', label: '兰州市' },
        { value: 'Nanchang', label: '南昌市' },
        { value: 'Nanjing', label: '南京市' },
        { value: 'Shanghai', label: '上海市' },
        { value: 'Shijiazhuang', label: '石家庄市' },
        { value: 'Shenyang', label: '沈阳市' },
        { value: 'Tianjin', label: '天津市' },
        { value: 'Taiyuan', label: '太原市' },
        { value: 'Wuhan', label: '武汉市' },
        { value: 'XiAn', label: '西安市' },
        { value: 'Xining', label: '西宁市' },
        { value: 'Zhengzhou', label: '郑州市' },
        { value: 'Urumqi', label: '乌鲁木齐市' },
        { value: 'LaSa', label: '拉萨市' },
        { value: 'Nanning', label: '南宁市' },
        { value: 'HangKong', label: '香港' },
        { value: 'Macao', label: '澳门' },
        { value: 'Taipei', label: '台北市' },
        { value: 'Zhuhai', label: '珠海市' },
        { value: 'Hainan', label: '海南省' },
        { value: 'Guizhou', label: '贵州省' },
        { value: 'Shandong', label: '山东省' },
        { value: 'Henan', label: '河南省' },
        { value: 'Sichuan', label: '四川省' },
        { value: 'Liaoning', label: '辽宁省' },
        { value: 'Anhui', label: '安徽省' },
        { value: 'Jiangsu', label: '江苏省' },
        { value: 'Zhejiang', label: '浙江省' },
        { value: 'Guangxi', label: '广西省' },
        { value: 'Xinjiang', label: '新疆省' },
        { value: 'Gansu', label: '甘肃省' },
        { value: 'Qinghai', label: '青海省' },
        { value: 'Tibet', label: '西藏' },
        { value: 'Ningxia', label: '宁夏省' },
        { value: 'Jilin', label: '吉林省' },
        { value: 'Hunan', label: '湖南省' },
        { value: 'Shanxi', label: '山西省' },
        { value: 'InnerMongolia', label: '内蒙古自治区' },
        { value: 'Macau', label: '澳门特别行政区' },
        { value: 'HongKong', label: '香港特别行政区' }
      ],
      userForm: {
        uname: '',
        passwd: '',
        passwd2: '',
        gender: '1',
        birthday: '',
        phoneNum: '',
        city: '北京市',
        address: '',
        code: ''
      },
      rules: {
        uname: [
          { required: true, message: '用户名不能为空', trigger: 'blur' },
          { pattern: /^[0-9a-zA-Z_]{1,}$/, message: '必须是数字字母组合', trigger: 'blur' },
          { min: 4, max: 10, message: '长度在4-10位', trigger: 'blur' },
          { validator: checkUserName, trigger: 'blur' }
        ],
        passwd: [
          { required: true, message: '请输入密码', trigger: 'blur' },
          { pattern: /^[0-9a-zA-Z_]{1,}$/, message: '必须是数字字母组合', trigger: 'blur' },
          { min: 4, max: 16, message: '长度在4-16位', trigger: 'blur' }
        ],
        passwd2: [{ required: true, validator: checkPasswd2, trigger: 'blur' }],
        birthday: [{ required: true, message: '请选择您的生日', trigger: 'blur' }],
        phoneNum: [{ required: true, pattern: /^1[3-9]\d{9}$/, message: '请输入正确的手机号码', trigger: 'blur' }],
        address: [{ required: true, message: '请填入您的联系地址', trigger: 'blur' }],
        code: [{ required: true, message: '请输入验证码', trigger: 'blur' }]
      }
    };
  },
  methods: {
    onSubmit(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let formData = new FormData();
          formData.append("uname", this.userForm.uname);
          formData.append("passwd", this.userForm.passwd);
          formData.append("gender", this.userForm.gender);
          formData.append("birthday", this.userForm.birthday);
          formData.append("phoneNum", this.userForm.phoneNum);
          formData.append("city", this.userForm.city);
          formData.append("address", this.userForm.address);
          formData.append("code", this.userForm.code);
          this.axios.post(publicJS.methods.getBackPath() + "user/register", formData)
              .then(res => {
                if (res.data.status) {
                  this.$message.success("注册成功");
                  this.$router.replace({ path: '/' });
                } else {
                  this.$message.error(res.data.msg);
                  this.changeCode();
                }
              });
        }
      });
    },
    changeCode() {
      this.codeSrc = publicJS.methods.getBackPath() + "checkCode?timestamp=" + new Date();
    }
  },
  mounted() {
    this.changeCode();
  }
}
</script>

<style scoped>
/* 注册页面背景 */
.register-container {
  padding: 40px 10px;
  background: #f4f7fc;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.form-wrapper {
  width: 100%;
  max-width: 600px;
  background-color: #fff;
  padding: 40px;
  border-radius: 12px;
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
}

.el-form-item {
  margin-bottom: 20px;
}

.el-input, .el-select, .el-date-picker {
  width: 100%;
}

.el-button {
  width: 100%;
  padding: 12px;
  background-color: #409EFF;
  color: #fff;
  border-radius: 6px;
}

.el-button:hover {
  background-color: #66b1ff;
}

.el-form-item label {
  font-size: 16px;
  color: #333;
}

.el-radio, .el-select, .el-input {
  font-size: 14px;
}

/* 响应式设计 */
@media (max-width: 768px) {
  .form-wrapper {
    padding: 20px;
  }

  .el-form-item label {
    font-size: 14px;
  }
}
</style>
